import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KPI } from 'src/app/config/constants';
import { Equipment, Point } from 'src/app/models/dashboard';
import { FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';
import { BackendService } from 'src/app/services/backend/backend.service';
import { PocServiceService } from '../poc-service.service';
import { timer, Subscription } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification/notification.service';


@Component({
	selector: 'cbms-equipment-widget',
	templateUrl: './equipment-widget.component.html',
	styleUrls: ['./equipment-widget.component.scss']
})
export class EquipmentWidgetComponent implements OnInit {
	public uri: string;
	equipments: Equipment[] = [];
	equipmentTree: Equipment;
	polledPointsValues: Subscription;

	private filterParams: FilterParams = {
		includesPresentValue: true,
		dataPointIdFilter: null
	};

	constructor(private pocService: PocServiceService, 
		private route: ActivatedRoute, 
		private backendService: BackendService,
		private notificationService: NotificationService) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(parameter => {
			if (parameter['uri'] != null) {
				this.uri = parameter['uri'];
				this.pocService.getEquipment(parameter['uri']).subscribe(equipmentData => {
					equipmentData.bindings.map(item => {
						let index = this.equipments.findIndex(equipment => item.equipment.value === equipment.uri);
						if (index !== -1) {
							if (item.point_label) {
								if (!this.equipments[index].points.find(point => point.uuid === item.point_uuid.value)) {
									this.equipments[index].points.push(
										{
											label: item.point_label.value,
											uuid: item.point_uuid.value,
											class: item.point_class.value.split('#')[1],
											presentValue: null
										}
									);
								}
							}
						} else {
							this.equipments.push({
								class: item.equipment_class.value.split("#")[1],
								uri: item.equipment.value,
								icon: this.generateIconClass(item.equipment_class.value),
								label: item.equipment_label.value,
								points: item.point_label ? [
									{
										label: item.point_label.value,
										uuid: item.point_uuid.value,
										class: item.point_class.value.split('#')[1],
										presentValue: null
									}
								] : [],
								parent: item.parent_equipment ? {
									uri: item.parent_equipment.value,
									label: item.parent_equipment_label.value
								} : null,
								kpis: [],
								otherPoints: []
							});
						}
					});
				
					this.filterParams.dataPointIdFilter = this.equipments.map(equipment => equipment.points).reduce((previousArray, currentArray) => previousArray.concat(currentArray)).map(point => { return { "id": point.uuid } });
					const equipmentPoints$ = this.backendService.getDataPointsDetails(this.filterParams);

					this.polledPointsValues = timer(0, 10000).pipe(
						concatMap(_ => equipmentPoints$),
						map((points) => {
							this.mapEquipmentPoints(points.content);
							this.equipmentTree = this.unflattenData(this.equipments)[0];
							this.groupPoints(this.equipmentTree);
						})
					).subscribe();
				})
			}
		});
	}

	unflattenData(items) {
		let tree = [];
		let mappedArr = {};
		let mappedElem = null;

		// Build a hash table and map items to objects
		items.forEach(function (item) {
			var uri = item.uri;
			if (!mappedArr.hasOwnProperty(uri)) { // in case of duplicates
				mappedArr[uri] = item; // the extracted id as key, and the item as value
				mappedArr[uri].children = [];  // under each item, add a key "children" with an empty array as value
			}
		})

		// Loop over hash table
		for (var uri in mappedArr) {
			if (mappedArr.hasOwnProperty(uri)) {
				mappedElem = mappedArr[uri];

				// If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
				if (mappedElem.parent) {
					var parentUri = mappedElem.parent.uri;
					mappedArr[parentUri].children.push(mappedElem);
				}

				// If the element is at the root level, directly push to the tree
				else {
					tree.push(mappedElem);
				}
			}
		}

		return tree;
	}

	generateIconClass(equipmentClass: string) {
		if (equipmentClass.toLowerCase().includes('air_handling_unit') || equipmentClass.toLowerCase().includes('exhaust_fan')) return 'air';
		if (equipmentClass.toLowerCase().includes('luminaire') || equipmentClass.toLowerCase().includes('lighting')) return 'lighting';
		if (equipmentClass.toLowerCase().includes('chiller')) return 'chiller';
		if (equipmentClass.toLowerCase().includes('boiler')) return 'hot_water';
		if (equipmentClass.toLowerCase().includes('fan')) return 'fan';
	}

	groupPoints(equipment: Equipment) {
		equipment.kpis = [];
		equipment.otherPoints = [];

		equipment.points.forEach(point => {
			if (KPI[equipment.class].indexOf(point.class) === -1) {
				equipment.otherPoints.push(point);
			} else {
				equipment.kpis.push(point);
			}
		});
	}

	// mapEquipmentPoints(points: any[]) {
	// 	const mappedPointsPerId = {};

	// 	this.equipmentTree.points.forEach(point => {
	// 		mappedPointsPerId[point.uuid] = point;
	// 	})

	// 	points.forEach(point => {
	// 		const existingPointObjectReference = mappedPointsPerId[point.id];
	// 		if (existingPointObjectReference) {
	// 			existingPointObjectReference.presentValue = point.presentValue;
	// 			existingPointObjectReference.signalType = point.signalType;
	// 			existingPointObjectReference.type = point.type;
	// 			existingPointObjectReference.units = point.units;
	// 			existingPointObjectReference.isWritable = this.isPointWritable(existingPointObjectReference);
	// 		}
	// 	})
	// }

	mapEquipmentPoints(points: any[]) {
		const mappedPointsPerId = {};
		let equipmentsPoints = this.equipments.map(equipment => equipment.points).reduce((previousArray, currentArray) => previousArray.concat(currentArray));

		equipmentsPoints.forEach(point => {
			mappedPointsPerId[point.uuid] = point;
		})

		points.forEach(point => {
			const existingPointObjectReference = mappedPointsPerId[point.id];
			if (existingPointObjectReference) {
				existingPointObjectReference.presentValue = point.presentValue;
				existingPointObjectReference.signalType = point.signalType;
				existingPointObjectReference.type = point.type;
				existingPointObjectReference.units = point.units;
				existingPointObjectReference.isWritable = this.isPointWritable(existingPointObjectReference);
			}
		})
	}

	isPointWritable(point: Point) {
		const pointMainClass = point.class.split('_').pop();
		if (['Command', 'Parameter', 'Setpoint'].includes(pointMainClass)) return true;
		if (['Alarm', 'Sensor', 'Status'].includes(pointMainClass)) return false;
	}

	handleChange(e, point: Point) {
		this.writeCommandPoint(point);
	}

	writeCommandPoint(point: Point) {
		const priority = 'AUTO';

		let dataToSend = {
			dataPointId: point.uuid,
			value: point.presentValue ? 1 : 0,
			rollbackTimer: null,
			priority: ''
		}

		this.backendService.writeCommand(dataToSend, priority).subscribe((response) => {
			this.notificationService.addSuccessMessage('Success', 'Point successfully written');
		})
	}

	public trackByFn(index: number, point: Point) {
		return point.uuid;
	}
}
