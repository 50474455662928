<div class="filter-component">
    <p-overlayPanel #filterMenuOverlay appendTo="body" styleClass="filter-overlay">
        <h3>{{filterFormTitle}}</h3>
        <form [formGroup]="addFilterForm" (ngSubmit)="saveFilter(filterMenuOverlay)">
            <div class="grid">
                <div class="col-6">
                    <p>Field name</p>
                    <p-dropdown [options]="columnList" formControlName="columnName"></p-dropdown>
                </div>
                <div class="col-6">
                    <p>Operator</p>
                    <p-dropdown [options]="operatorsList" formControlName="selectedOperator"></p-dropdown>
                </div>
                <div class="col-6">
                    <p>Value</p>
                    <input class="full-width" pInputText placeholder="Value" formControlName="columnValue">
                    <br><br>
                    <p>All fields are required</p>
                </div>
                <div class="col-6">
                </div>
            </div>

            <hr>
            <div class="filter-action-buttons">
                <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" type="submit"
                    [disabled]="!addFilterForm.valid">Save</button>
                <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left" type="reset" (click)="filterMenuOverlay.hide()">Cancel</button>
            </div>
        </form>
    </p-overlayPanel>
    <div class="col-12 filter-fields-wrapper">
        <div class="form_field" pTooltip="Edit Filter" tooltipPosition="bottom"
            *ngFor="let item of selectedColumns | keyvalue">
            <span class="field__name-and-value"
                (click)="editColumnToFilter($event, item, filterMenuOverlay)">{{item.value.column}}:
                {{item.value.operator}} "{{item.value.value}}"</span>
            <span class="field__remove-btn pi pi-times" (click)="removeColumnFromFilter(item)"></span>
        </div>
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__grey-bg" 
            (click)="showFilterMenu($event, filterMenuOverlay)">
            <mat-icon svgIcon="filter"></mat-icon>
            <span class="table-caption-text">Add Filter</span>
        </button>

        <ng-container *ngIf="hasFilters">
            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg filter-item"  data-cy="apply-filter" (click)="onFilter()">Apply Filter</button>
            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__red-bg filter-item" data-cy="clear-filter" (click)="clearFilters()">
                <span class="table-caption-text">Clear</span>
            </button>
        </ng-container>
    </div>
</div>
