<h2>Dashboard</h2>
<h3 class="cbms-text-size18">Template</h3>
<div class="grid">
    <div class="col-3">
        <p class="cbms-vertical-align">
            <p-fileUpload #dashboardTemplateUpload class="upload-button white cbms-margin-right" mode="basic"
                name="test" chooseIcon="pi pi-upload" 
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                chooseLabel="" maxFileSize="20000000" customUpload="true" [auto]="true" [disabled]="uploadInProgress"
                (uploadHandler)="uploadTemplateCSV($event, dashboardTemplateUpload)"></p-fileUpload>
            <span>Import Template</span>                
        </p>

        <p class="cbms-vertical-align">
            <button pButton class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-right" (click)="exportDashboardSettingsTemplate()">
                <i class="pi pi-download"></i>
            </button>
            <span>Export Template</span>
        </p>

        <p class="cbms-vertical-align">
            <button pButton class="p-button-raised p-button-text cbms-row-button cbms-margin-right" (click)="deleteMenu.show($event)">
                <mat-icon svgIcon="delete"></mat-icon>
            </button>
            <span>Delete Template</span>
        </p>

        <p-overlayPanel #deleteMenu appendTo="body">
            <p>
                Please confirm you want to continue with the removal<br>of the template:
                <strong>{{templateName}}</strong>.
            </p>
            <hr>
            <div class="delete-action-buttons">
                <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" 
                    (click)="deleteTemplate(); deleteMenu.hide();">Confirm
                </button>
                <button pButton class="p-button-raised p-button-text cbms-button cbms-small-margin-left" (click)="deleteMenu.hide()">Cancel</button>
            </div>
        </p-overlayPanel>
    </div>
    <div class="col-6">
        <p class="cbms-vertical-align cbms-min_height_36"><span class="cbms-text-size18"><b>Template name:&nbsp;&nbsp;</b></span>{{templateName || 'No template found'}}</p>
    </div>
    <div class="col-3">
        <p class="cbms-vertical-align cbms-min_height_36"><span class="cbms-text-size18"><b>Upload date:&nbsp;&nbsp;</b></span>{{templateDateOfImport | date:'dd.MM.yyy, HH:mm:ss'}}</p>
    </div>
</div>