<ng-container *ngIf="isGlobalAction">
    <div pTooltip="No data points selected." showDelay="1000"
         hideDelay="500" tooltipPosition="bottom" [tooltipDisabled]="selectedDataPoints.length">
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="showSetpointDialog(selectedDataPoints)"
                [disabled]="!selectedDataPoints.length"
                pTooltip="Manage value of the selected datapoints" showDelay="1000" hideDelay="500">
            <mat-icon svgIcon="control"></mat-icon>
            <span class="table-caption-text">Manage Value</span>
        </button>
    </div>
</ng-container>


<ng-container *ngIf="!isGlobalAction">
    <button pButton class="p-button-raised p-button-text cbms-row-button" (click)="showSetpointDialog(selectedDataPoints)"
            pTooltip="Manage value" showDelay="1000" hideDelay="500">
        <mat-icon svgIcon="control"></mat-icon>
    </button>
</ng-container>
