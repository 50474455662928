<div class="cbms-content-wrapper">
    <div class="grid">
        <div class="col">
            <h1>Dashboard</h1>
        </div>
    </div>

    <div class="grid" style="margin-top: 20px;">
        <div class="col">
            <h1>Maintenance Responsible View – All Equipment Dashboard</h1>
        </div>
    </div>

    <div class="grid">
        <div class="col-12 p-sm-12 p-md-6 p-lg-4">
            <div class="card min-height200">
                <div class="card__header">
                    <mat-icon svgIcon="hot_water"></mat-icon>
                    <h2>Hot Water Boiler</h2>
                </div>
                <div class="card__content">
                    <div class="grid content-row vertical-space"></div>
                    <div class="grid content-row">
                        <div class="col-10">
                            <p>Water flow temperature</p>
                        </div>
                        <div class="col-2">
                            <p>45&deg;C</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-sm-12 p-md-6 p-lg-4">
            <div class="card min-height200">
                <div class="card__header">
                    <mat-icon svgIcon="domestic_water"></mat-icon>
                    <h2>Domesti Hot Water</h2>
                </div>
                <div class="card__content">
                    <div class="grid content-row vertical-space"></div>
                    <div class="grid content-row">
                        <div class="col-10">
                            <p>Water flow temperature</p>
                        </div>
                        <div class="col-2">
                            <p>40&deg;C</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="grid">
        <div class="col-12 p-sm-12 p-md-6 p-lg-4">
            <div class="card min-height200">
                <div class="card__header">
                    <mat-icon svgIcon="cooling"></mat-icon>
                    <h2>Toilet Exhaust Fan</h2>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-2">
                            <mat-icon svgIcon="door" class="mat_icon__big"></mat-icon>
                        </div>
                        <div class="col-4">
                            <button pButton type="button" class="p-button-raised p-button-rounded"
                                label="Staff toilet"></button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-10">
                            <p>Fan command</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="fanCommand"></p-inputSwitch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-sm-12 p-md-6 p-lg-4">
            <div class="card min-height200">
                <div class="card__header">
                    <mat-icon svgIcon="cooling"></mat-icon>
                    <h2>Air Handling Unit-1</h2>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-2">
                            <mat-icon svgIcon="door" class="mat_icon__big"></mat-icon>
                        </div>
                        <div class="col-4">
                            <button pButton type="button" class="p-button-raised p-button-rounded"
                                label="Sales area"></button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-10">
                            <p>Supply Air Temperature</p>
                        </div>
                        <div class="col-2">
                            <p>17&deg;C</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="grid" style="margin-top: 80px;">
        <div class="col-12 p-sm-12 p-md-6 p-lg-4">
            <div class="card">
                <div class="card__header">
                    <mat-icon svgIcon="hot_water"></mat-icon>
                    <h2>Hot Water Boiler</h2>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Water flow temperature</p>
                        </div>
                        <div class="col-2">
                            <p>45&deg;C</p>
                        </div>
                        <div class="col-2">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Boiler timer</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerTimer"></p-inputSwitch>
                        </div>
                        <div class="col-2">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Boiler enable</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerEnable"></p-inputSwitch>
                        </div>
                        <div class="col-2">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Boiler fault</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerFault" styleClass="cbms-alert-switch"></p-inputSwitch>
                        </div>
                        <div class="col-2">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-sm-12 p-md-6 p-lg-4">
            <div class="card">
                <div class="card__header">
                    <mat-icon svgIcon="hot_water"></mat-icon>
                    <h2>Hot Water Boiler</h2>
                    <button mat-raised-button class="cbms-margin-left-auto">
                        <mat-icon svgIcon="timeline"></mat-icon>
                        <span class="table-caption-text">Analysis</span>
                    </button>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Water flow temperature</p>
                        </div>
                        <div class="col-2">
                            <p>45&deg;C</p>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="waterFlowCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Boiler timer</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerTimer"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="boilerTimerCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Boiler enable</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerEnable"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="boilerEnableCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Boiler fault</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerFault"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="boilerFaultCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-sm-12 p-md-6 p-lg-4">
            <div class="card">
                <div class="card__header">
                    <mat-icon svgIcon="developer_board"></mat-icon>
                    <h2>Air handling unit-1 </h2>
                    <button mat-raised-button class="cbms-margin-left-auto">
                        <mat-icon svgIcon="timeline"></mat-icon>
                        <span class="table-caption-text">Analysis</span>
                    </button>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Supply Air Temperature sensor</p>
                        </div>
                        <div class="col-2">
                            <p>30&deg;C</p>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="airTemperatureCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Return air temperature</p>
                        </div>
                        <div class="col-2">
                            <p>25&deg;C</p>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="returnAirTemperatureCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Schedule</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="schedule"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="scheduleCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-12">
                            <p-accordion>
                                <p-accordionTab header="Supply fan">
                                    <div class="grid content-row">
                                        <div class="col-7">
                                            <p>Supply fan command</p>
                                        </div>
                                        <div class="col-2">
                                            <p-inputSwitch [(ngModel)]="supplyFan"></p-inputSwitch>
                                        </div>
                                        <div class="col-2 cbms-text-align-center">
                                            <button mat-raised-button class="cbms-row-button">
                                                <mat-icon svgIcon="timeline"></mat-icon>
                                            </button>
                                        </div>
                                        <div class="col-1">
                                            <p-checkbox [(ngModel)]="supplyFanCheckbox" binary="true"></p-checkbox>
                                        </div>
                                    </div>
                                    <div class="grid content-row">
                                        <div class="col-7">
                                            <p>Supply fan trip</p>
                                        </div>
                                        <div class="col-2">
                                            <p-inputSwitch [(ngModel)]="supplyFanTrip" styleClass="cbms-alert-switch"></p-inputSwitch>
                                        </div>
                                        <div class="col-2 cbms-text-align-center">
                                            <button mat-raised-button class="cbms-row-button">
                                                <mat-icon svgIcon="timeline"></mat-icon>
                                            </button>
                                        </div>
                                        <div class="col-1">
                                            <p-checkbox [(ngModel)]="supplyFanTripCheckbox" binary="true"></p-checkbox>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <p-accordionTab header="Exhaust fan">
                                    <div class="grid content-row">
                                        Content 2
                                    </div>
                                </p-accordionTab>
                                <p-accordionTab header="Heating Coil">
                                    <div class="grid content-row">
                                        Content 3
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="grid" style="margin-top: 50px;">
        <div class="col">
            <h1>Maintenance Responsible View – Cause and Effect Chain</h1>
        </div>
    </div>

    <div class="grid">
        <div class="col-12 p-sm-12 p-md-6 p-lg-4">
            <div class="card">
                <div class="card__header">
                    <mat-icon svgIcon="hot_water"></mat-icon>
                    <h2>Heating Circuit 1B</h2>
                    <button mat-raised-button class="cbms-margin-left-auto">
                        <mat-icon svgIcon="timeline"></mat-icon>
                        <span class="table-caption-text">Analysis</span>
                    </button>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Water Flow Temperature</p>
                        </div>
                        <div class="col-2">
                            <p>30&deg;C</p>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="waterFlowCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Water Return Temperature</p>
                        </div>
                        <div class="col-2">
                            <p>25&deg;C</p>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="returnAirTemperatureCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Circuit timer</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="circuitTimer"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="circuitTimerCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-7">
                            <p>Pump</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="pump"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="scheduleCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row cbms-margin-bottom20">
                        <div class="col-7">
                            <p>Heating valve opening</p>
                        </div>
                        <div class="col-2">
                            <input pInputText class="cbms-input__small-size" type="text" [(ngModel)]="heatingValve">
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                        <div class="col-1">
                            <p-checkbox [(ngModel)]="valveCheckbox" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-4">
                            <mat-icon svgIcon="door" class="mat_icon__big"></mat-icon>
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="edit"></mat-icon>
                            </button>
                        </div>
                        <div class="col-4 cbms-text-align-center">
                            <mat-icon svgIcon="settings" class="mat_icon__big"></mat-icon>
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="edit"></mat-icon>
                            </button>
                        </div>
                        <div class="col-4 cbms-text-align-right">
                            <mat-icon svgIcon="settings" class="mat_icon__big"></mat-icon>
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="edit"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="grid" style="margin-top: 50px;">
        <div class="col">
            <h1>Maintenance Responsible View – Play History Of equipment chain</h1>
        </div>
    </div>

    <div class="grid">
        <div class="col-12 p-sm-12 p-md-6 p-lg-3">
            <div class="card">
                <div class="card__header">
                    <mat-icon svgIcon="hot_water"></mat-icon>
                    <h2>Hot Water Boiler</h2>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Water flow temperature</p>
                        </div>
                        <div class="col-2">
                            <p>45&deg;C</p>
                        </div>
                        <div class="col-2">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Boiler timer</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerTimer"></p-inputSwitch>
                        </div>
                        <div class="col-2">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Boiler enable</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerEnable"></p-inputSwitch>
                        </div>
                        <div class="col-2">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Boiler fault</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerFault"></p-inputSwitch>
                        </div>
                        <div class="col-2">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col p-lg-1">
            <p>Feeds</p>
            <mat-icon svgIcon="double-arrow" class="mat_icon__big"></mat-icon>
        </div>
        <div class="col-12 p-sm-12 p-md-6 p-lg-3">
            <div class="card">
                <div class="card__header">
                    <mat-icon svgIcon="hot_water"></mat-icon>
                    <h2>Heating Circuit 1B</h2>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Water Flow Temperature</p>
                        </div>
                        <div class="col-2">
                            <p>30&deg;C</p>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Water Return Temperature</p>
                        </div>
                        <div class="col-2">
                            <p>25&deg;C</p>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Circuit timer</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="circuitTimer"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Pump</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="pump"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Heating valve opening</p>
                        </div>
                        <div class="col-2">
                            <input pInputText class="cbms-input__small-size" type="text" [(ngModel)]="heatingValve">
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col p-lg-1">
            <p>Feeds</p>
            <mat-icon svgIcon="double-arrow" class="mat_icon__big"></mat-icon>
        </div>
        <div class="col-12 p-sm-12 p-md-6 p-lg-3">
            <div class="card">
                <div class="card__header">
                    <mat-icon svgIcon="cooling"></mat-icon>
                    <h2>AHU 1</h2>
                </div>
                <div class="card__content">
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Supply Air Temperature</p>
                        </div>
                        <div class="col-2">
                            <p>16&deg;C</p>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Boiler timer</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="boilerTimer"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Supply fan enable</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="supplyFanEnable"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid content-row">
                        <div class="col-8">
                            <p>Ehaust Fan Enable</p>
                        </div>
                        <div class="col-2">
                            <p-inputSwitch [(ngModel)]="ehaustFan"></p-inputSwitch>
                        </div>
                        <div class="col-2 cbms-text-align-center">
                            <button mat-raised-button class="cbms-row-button">
                                <mat-icon svgIcon="timeline"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>