<div class="content">

    <div class="grid fixed-height-500">
        <div class="col-9">
            <h3>Exception Schedule: {{selectedDataPoint.objectName}}</h3>
            <cbms-exception-schedule-table
                    [exceptionScheduleInput]="exceptionScheduleObject"></cbms-exception-schedule-table>
        </div>
        <div class="col-3">
            <h3>Save as Template</h3>
            <form [formGroup]="saveTemplateForm" (ngSubmit)="saveTemplate()">
                <input pInputText type="text" placeholder="Template Name" required minlength="3"
                    formControlName="templateName">
                <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" type="submit"
                    [disabled]="!saveTemplateForm.valid || isLoading">Save</button>
            </form>
            <div class="cbms-spinner-wrapper" *ngIf="isLoading">
                <mat-spinner [diameter]="20" class="cbms-spinner"></mat-spinner>
            </div>
        </div>
    </div>
</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>
