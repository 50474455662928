import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Site } from '../../models/site';
import _ from 'lodash';
import { BackendService } from 'src/app/services/backend/backend.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'cbms-dashboards-management',
  templateUrl: './dashboards-management.component.html',
  styleUrls: ['./dashboards-management.component.scss']
})
export class DashboardsManagementComponent implements OnInit {

  public sites: Site[] = [];
  public selectedSites: Site[] = [];

  constructor(public ref: DynamicDialogRef,
		public config: DynamicDialogConfig, private backendService: BackendService, private notificationService: NotificationService ) { }

  ngOnInit(): void {
    this.sites = _.cloneDeep(this.config.data);
  }

  close() {
	this.ref.close();
  }

  enable() {
	this.backendService.manageDashboardsVisibility(this.selectedSites.map(site => ({siteId: site.id, dashboardEnabled: true}))).subscribe(response => {
		this.notificationService.addSuccessMessage('Success', 'Dashboards have been enabled.');
		this.ref.close(true);
	});
  }

  disable() {
	this.backendService.manageDashboardsVisibility(this.selectedSites.map(site => ({siteId: site.id, dashboardEnabled: false}))).subscribe(response => {
		this.notificationService.addSuccessMessage('Success', 'Dashboards have been disabled.');
		this.ref.close(true);
	});
  }
}
