import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MappedDataPoint, DataPoint } from 'src/app/models/data-point';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-compare-schedule-to-twin-action',
    templateUrl: './compare-schedule-to-twin-action.component.html',
    styleUrls: ['./compare-schedule-to-twin-action.component.scss']
})
export class CompareScheduleToTwinActionComponent implements OnInit {
    @Input()
    public selectedDataPoints: MappedDataPoint[];

    constructor(private dialogConfigService: DialogConfigService) {
    }

    ngOnInit() {
    }

    public showCompareScheduleToTwinDialog() {
        this.dialogConfigService.showCompareScheduleToTwinDialog(this.selectedDataPoints);
    }
}
