import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HighchartsDataService {
    private _zoomLevelList: Highcharts.ExtremesObject[] = [];

    private zoomInSubject = new Subject<{start: number, end: number}>();
    zoomIn$ = this.zoomInSubject.asObservable();

    constructor() {}

    addZoomLevel(zoomValues: Highcharts.ExtremesObject) {
        this._zoomLevelList.push(zoomValues);
    }

    removeLastZoomLevel() {
        this._zoomLevelList.pop();
    }

    getPreviousZoomLevel() {
        return this._zoomLevelList[this._zoomLevelList.length-2];
    }

    getZoomLevelList() {
        return this._zoomLevelList;
    }

    clearZoomLevelList() {
        this._zoomLevelList = [];
    }

    updateTimeFilter(start: number, end: number) {
        this.zoomInSubject.next({start, end});
    }
}
