import { Component } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'cbms-no-access',
    standalone: true,
    imports: [],
    templateUrl: './no-access.component.html',
    styleUrl: './no-access.component.scss',
})
export class NoAccessComponent {
    constructor(
        private dataService: DataService,
        private authService: OAuthService
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('error');
    }

    logout() {
        sessionStorage.removeItem('userRolePermissionList');
        this.authService.logOut();
    }
}
