<div class="content-wrapper">
    <div class="cbms-spinner-wrapper" *ngIf="isLoading || !visible">
        <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
    </div>

    <p-table *ngIf="visible" class="cbms-generic-table-style" #dataPointsTable
        [columns]="deviceSource==='CC'? cols:wiseMeterCols" [value]="dataPoints" [resizableColumns]="true"
        [scrollable]="true" sortField="objectName" sortOrder="1" [paginator]="true" [rows]="100"
        columnResizeMode="expand" (onPage)="onPageChange()" stateStorage="session" stateKey="{{tableName}}-session">
        <ng-template pTemplate="caption" *ngIf="showCaption">
            <div class="caption-wrapper">
                <button pButton class="p-button-raised p-button-text cbms-button auto-margin" (click)="unassign.show($event)" [disabled]="!selectedDataPoints.length"
                    label="Unassign Site"></button>
                <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left" (click)="assignSiteAction($event)" [disabled]="!selectedDataPoints.length"
                    label="Assign Site"></button>
                <div class="cbms-margin-left">
                    <cbms-delete-datapoints-action [selectedDataPoints]="selectedDataPoints"
                        (updatedDataPointsEvent)="updateDataPointsList($event)"></cbms-delete-datapoints-action>
                </div>
                <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left" (click)="scan()">
                    <mat-icon svgIcon="scan"></mat-icon>
                    <span class="table-caption-text">Scan for Data Points</span>
                </button>
                <div class="cbms-margin-left">
                    <cbms-edit-tags-action [selectedDataPoints]="selectedDataPoints"
                        (updatedDataPointsEvent)="updateDataPointsTags($event)"></cbms-edit-tags-action>
                </div>
                <div class="cbms-margin-left">
                    <cbms-watch-action [selectedDataPoints]="selectedDataPoints"></cbms-watch-action>
                </div>
                <button *ngIf="!editMode && dataPoints != null && dataPoints.length > 0" pButton class="p-button-raised p-button-text cbms-button cbms-margin-left"
                    (click)="toggleEditMode()">
                    <mat-icon svgIcon="edit"></mat-icon>
                    <span class="table-caption-text">Edit Polling</span>
                </button>

                <button *ngIf="editMode" pButton class="p-button-raised p-button-text cbms-button cbms-margin-left"
                    (click)="showPollingGroupSelectionSidebar()">
                    <span>Group Selection</span>
                </button>
                <button *ngIf="editMode" pButton class="p-button-raised p-button-text cbms-button cbms-margin-left" (click)="save()">
                    <span>Save</span>
                </button>
                <button *ngIf="editMode" pButton class="p-button-raised p-button-text cbms-button cbms-margin-left" (click)="cancel()">
                    <span>Cancel</span>
                </button>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="flex: 0 0 50px !important; min-width: 50px;">
                    <p-checkbox [(ngModel)]="allDPSelected" binary="true"
                        (click)="toggleSelectedDP($event)"></p-checkbox>
                </th>
                <th style="flex: 0 0 170px !important; min-width: 170px">Actions</th>
                <th class="cbms-text-align-left" *ngFor="let col of columns" [pSortableColumn]="col.field"
                    pResizableColumn [ngClass]="{ 'polled-column': col.field === 'polled' }" style="flex-basis: {{col.width}}; min-width: {{col.width}}">
                    <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'polled'">
                            <p-checkbox *ngIf="editMode" binary="true" class="right-margin"
                                (onChange)="onPollingHeaderChange($event)"></p-checkbox>
                            <span>Polled</span>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{col.header}}
                        </ng-container>
                    </ng-container>
                    <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th pResizableColumn style="flex-basis: 140px; min-width: 140px"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataPoint let-columns="columns">
            <tr>
                <td style="flex: 0 0 50px !important; min-width: 50px">
                    <div class="cbms-column-center">
                        <p-checkbox [(ngModel)]="dataPoint.selected" binary="true"
                            (onChange)="updateSelectedDPList(dataPoint)">
                        </p-checkbox>
                    </div>
                </td>
                <td style="flex: 0 0 170px !important; min-width: 170px">
                    <div class="cbms-button-wrapper">
                        <button pButton class="p-button-raised p-button-text cbms-row-button" (click)="showTelemetryDialog([dataPoint])"
                            pTooltip="Show graph" showDelay="1000" hideDelay="500">
                            <mat-icon svgIcon="bar_chart"></mat-icon>
                        </button>
                        <button pButton class="p-button-raised p-button-text cbms-row-button" (click)="showSetpointDialog([dataPoint])"
                            pTooltip="Manage value" showDelay="1000" hideDelay="500">
                            <mat-icon svgIcon="control"></mat-icon>
                        </button>
                        <button pButton class="p-button-raised p-button-text cbms-row-button"
                            (click)="showDataPointPropertiesDialog(dataPoint)" pTooltip="Read properties"
                            showDelay="1000" hideDelay="500">
                            <mat-icon svgIcon="description"></mat-icon>
                        </button>
                    </div>
                </td>
                <td class="ui-resizable-column" *ngFor="let col of columns" style="flex-basis: {{col.width}}; min-width: {{col.width}}">
                    <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'tags'">
                            <div class="cbms-white-space-normal">
                                <ng-container *ngFor="let tag of dataPoint.tags">
                                    <span class="cbms-chips">{{tag.name}}</span>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'polled'">
                            <div class="cbms-column-center">
                                <p-checkbox [(ngModel)]="dataPoint.polled" binary="true" [disabled]="!editMode">
                                </p-checkbox>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'site.name'">
                            {{dataPoint?.site?.name}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'site.customer.name'">
                            {{dataPoint?.site?.customer.name}}
                        </ng-container>
                        <ng-container *ngSwitchDefault>{{dataPoint[col.field]}}</ng-container>
                    </ng-container>
                </td>
                <td style="text-align: center; flex-basis: 140px; min-width: 140px">
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg auto-margin" (click)="showTagsSidebar(dataPoint)">Edit
                        Tags</button>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-sidebar *ngIf="selectedDataPoint" [(visible)]="showSidebar" position="right" styleClass="p-sidebar-md"
        (onHide)="cancelTagsEditing()">
        <div class="cbms-sidebar-header">
            <h2>Edit Tags</h2>
            <h4>Data Point - {{selectedDataPoint.objectName}}</h4>
        </div>

        <div class="sidebar-body">
            <p>Only alphanumeric chars and "_" are allowed, no white-space nor special characters!</p>

            <div class="tags-component">
                <p-autoComplete field="name" [(ngModel)]="selectedDataPoint.tags" [minLength]="3" [maxlength]="32"
                    [suggestions]="results" [multiple]="true" emptyMessage="No results"
                    (completeMethod)="search($event)" (onKeyUp)="onKeyUp($event)">
                </p-autoComplete>
            </div>
            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" [disabled]="tagsNotChanged(selectedDataPoint)"
                (click)="saveTags(selectedDataPoint)">Save</button>
            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
                (click)="cancelTagsEditing()">Cancel</button>
        </div>
    </p-sidebar>

    <p-sidebar [(visible)]="isPollingGroupSelectionSidebarVisible" position="right" styleClass="p-sidebar-md"
        (onHide)="cancelPollingGroupSelection()">
        <div class="cbms-sidebar-header">
            <h2>Polling Group Selection</h2>
        </div>

        <div class="grid">
            <div class="col-6">
                <h3>Global Presets</h3>
            </div>
            <div class="col-6">
                <p-radioButton name="tagLogicalOperator" value="global" [(ngModel)]="pollingType"></p-radioButton>
            </div>
        </div>
        <div class="grid" *ngFor="let item of globalPresetsUIList">
            <div class="col-6">
                <p>All {{item.label}} datapoints</p>
            </div>
            <div class="col-6">
                <p-checkbox class="right-margin" binary="true" [disabled]="pollingType === 'protocolSpecific'"
                    [(ngModel)]="item.isChecked" (onChange)="globalPresetCheckboxUpdated(item)">
                </p-checkbox>
            </div>
        </div>

        <div class="cbms-sidebar-header"></div>

        <div class="grid">
            <div class="col-6">
                <h3>Protocol Specific</h3>
            </div>
            <div class="col-6">
                <p-radioButton name="tagLogicalOperator" value="protocolSpecific" [(ngModel)]="pollingType">
                </p-radioButton>
            </div>
        </div>
        <div class="grid" *ngFor="let item of protocolSpecificUIList">
            <div class="col-6">
                <p>{{item.label}}</p>
            </div>
            <div class="col-6">
                <p-checkbox class="right-margin" binary="true" [disabled]="pollingType === 'global'"
                    [(ngModel)]="item.isChecked" (onChange)="protocolSpecificCheckboxUpdated()"></p-checkbox>
            </div>
        </div>
        <div class="grid cbms-margin-bottom20">
        </div>

        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" (click)="confirmGroupPolling()">Apply</button>
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" 
            (click)="cancelPollingGroupSelection()">Close</button>
    </p-sidebar>

    <p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" appendTo="body" width="500"
        styleClass="style-confirmation-message"></p-confirmDialog>

    <div class="cbms-spinner-wrapper" *ngIf="dataPoints == null && !isLoading">
        <mat-progress-spinner [diameter]="60" color="warn" class="cbms-spinner" mode="determinate"
            [value]="spinnerValue"></mat-progress-spinner>
    </div>

    <div class="cbms-spinner-wrapper" *ngIf="isScanning">
        <span class="no-datapoints-text">{{loadingText}}</span>
    </div>

    <div class="cbms-spinner-wrapper" *ngIf="!isScanning && dataPoints != null && dataPoints.length == 0">
        <span class="no-datapoints-text">No Data Points found</span>
    </div>
</div>

<p-overlayPanel #unassign appendTo="body" styleClass="cbms-mid-size">
    <p>
        You are about to remove the site assignment to these data points. <br />
        Do you wish to proceed?
    </p>
    <hr>
    <div class="cbms-overlay-confirmation-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="unassignSite(); unassign.hide();">Confirm
        </button>
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="unassign.hide()">Cancel</button>
    </div>
</p-overlayPanel>

<p-overlayPanel #assign appendTo="body" styleClass="cbms-mid-size">
    <p>
        Data points already have a site assignment. <br />
        Do you wish to proceed?
    </p>
    <hr>
    <div class="cbms-overlay-confirmation-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="showAssignDialog(); assign.hide();">Confirm
        </button>
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="assign.hide()">Cancel</button>
    </div>
</p-overlayPanel>
