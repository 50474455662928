import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AlarmFilter, SmartAlert } from 'src/app/models/alert-rule.model';
import { BackendService } from 'src/app/services/backend/backend.service';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-smart-alert-details',
    templateUrl: './smart-alert-details.component.html',
    styleUrls: ['./smart-alert-details.component.scss'],
})
export class SmartAlertDetailsComponent implements OnInit {
	alertData: SmartAlert;
	isLoading = true;
	rules: AlarmFilter;
	dataPointIdList: {id: string}[] = [];

	constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
		private backendService: BackendService,
		private dialogConfigService: DialogConfigService) {

	}

	ngOnInit() {
		this.alertData = this.config.data;

		this.backendService.getAlertDetails(this.alertData.id).subscribe((response: any) => {
			this.alertData.alarmConfig = response.alarmConfig;
			this.rules = response.alarmFilterMatcherResult;
			this.iterateRulesToGetDataPointIdList(response.alarmFilterMatcherResult.rules);
			this.isLoading = false;
		})
	}

	close() {
        this.ref.close();
    }

	showTelemetryDialog() {
		this.dialogConfigService.showTelemetryDialog(this.dataPointIdList);
	}

	private iterateRulesToGetDataPointIdList(rules: AlarmFilter[]) {
        if (!rules || !Array.isArray(rules)) {
            return;
        }

        for (const rule of rules) {
            // Process the current rule here
			if (rule.entityId) {
				this.dataPointIdList.push({id: rule.entityId});
			}
			
            // Recursively process nested rules
            if (this.ruleHasChildren(rule)) {
                this.iterateRulesToGetDataPointIdList(rule.rules);
            }
        }
    }

    private ruleHasChildren(rule) {
        return rule.rules && Array.isArray(rule.rules);
    }
}
