<div class="error-wrapper">
    <div class="animatedbox animatedbox-one"></div>
    <div class="animatedbox animatedbox-two"></div>
    <div class="animatedbox animatedbox-three"></div>
    <div class="blurr"></div>
    <div class="content">
        <p class="eon_optimum_logo">
            <a href="https://www.eon.com/"><span></span></a>
        </p>
        <div class="apperror-main">
            <h1>Sorry,we could not find that user account.</h1>

            <div class="support"></div>
        </div>
        <button class="eon-button bg-eon-white logout" (click)="logout()">
            Return to Login
        </button>
    </div>
    <div class="apperror-footer">
        <p>© 2023 E.ON UK</p>
    </div>
</div>
