import { Component, OnInit, ViewEncapsulation, Input, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import moment from 'moment';
import { COLORS } from '../../config/constants';
import { FullCalendarElement } from '@fullcalendar/web-component';

import { WeeklyScheduleObject } from '../../models/data-point';

@Component({
	selector: 'cbms-schedule-calendar',
	templateUrl: './schedule-calendar.component.html',
	styleUrls: ['./schedule-calendar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarComponent implements OnInit {

	@Input() calendarHeight: number;

	@Input() scheduleData: any;

	@Input() isEditable: boolean;

	@Input() shouldReset: boolean;

	@Output() emitPeriod = new EventEmitter();

	@ViewChild('fc') fc: FullCalendarElement;

	@Input() weeklyScheduleObject: WeeklyScheduleObject;
	
	events: any[];
	options: any;

	constructor() { }

	ngOnInit() {

		this.events = this.generateEvents();
		const that = this;

		this.options = {
			plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin],
			slotLabelFormat: ['H:mm'],
			height: this.calendarHeight || 'auto',
			initialView: 'timeGridWeek',
			firstDay: 1,
			allDaySlot: false,
			header: false,
			headerToolbar: false,
			views: {
				timeGridWeek: {
					dayHeaderFormat: {
						weekday: 'long'
					  }
				}
			  },
			eventOverlap: false,
			locale: 'en-GB',
			eventTimeFormat: {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false
			},
			dateClick: function() {
				that.emitPeriod.emit(null);
			},
			eventClick: function (info) {
				if (!that.isEditable) {
					return;
				}
				if (info.event.title.indexOf('default') !== -1) {
					return;
				}

				let htmlEvents = document.getElementsByClassName("fc-event");
				Array.from(htmlEvents).forEach((event: HTMLElement) => {
					event.style.opacity = '1';
				})
				//highlight selected period
				info.el.style.opacity = '0.6';

				that.emitPeriod.emit(info.event);
			}
		};
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.scheduleData && !changes.scheduleData.firstChange) {
			this.scheduleData = changes.scheduleData.currentValue;
			this.events = [...this.generateEvents()];
			//this.fc.getCalendar().rerenderEvents();
		}
		if (changes.shouldReset && changes.shouldReset.currentValue) {
			//this.fc.getCalendar().rerenderEvents();
		}
	}

	public generateEvents() {
		const SUNDAY = 0;
		const currentDay = moment().day() === SUNDAY ? 6 : moment().day() - 1;
		const events = this.scheduleData.map((scheduleObj: any, index: number): any[] => {
			return scheduleObj.map( (scheduleItem: any) => {
				if (scheduleItem.default) {
					return;
				}
				let color = COLORS.LIME_YELLOW;
				let textColor = COLORS.BLACK;

				//don't use strict comparison, value can be number or string or anything :)
				if (scheduleItem.value == '0') {
					color = COLORS.RED;
					textColor = COLORS.WHITE;
				}
				if (scheduleItem.value == '1') {
					color = COLORS.TURQOISE;
					textColor = COLORS.WHITE;
				}

				if (scheduleItem.value === null) {
					color = "transparent";
				}

				return {
					title: `Value: ${scheduleItem.value}`,
					value: `${scheduleItem.value}`,
					start: moment(scheduleItem.start, 'HH:mm:ss').add(index - currentDay, 'days').format('YYYY-MM-DDTHH:mm:ss'),
					end: moment(scheduleItem.end, 'HH:mm:ss').add(index - currentDay, 'days').format('YYYY-MM-DDTHH:mm:ss'),
					backgroundColor: color,
					borderColor: color,
					textColor: textColor,
					id: scheduleItem.id
				}
			}).filter(event => event !== undefined);
		}).flat();
		return events;
	}

}
