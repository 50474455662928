import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';
import { ColumnsToFilter } from 'src/app/models/columns-filter.interface';
import {Gateway} from "../../models/gateway";

interface TagsStatus {
    sitesTagsUpdated?: boolean,
    dataPointsTagsUpdated?: boolean
}

@Injectable()
export class DataService {//maybe rename this into DataNotificationService

    private newTagsCreated = new BehaviorSubject<TagsStatus>(null);
    newTagsCreatedStatus = this.newTagsCreated.asObservable();

    private filters = new Subject();
    filtersChanged$ = this.filters.asObservable();

    private columnsFilter = new BehaviorSubject(null);
    columnsFilterChanges$ = this.columnsFilter.asObservable();

    private timeframeFilter = new BehaviorSubject(null);
    timeframeFilterChanges$ = this.timeframeFilter.asObservable();

    private currentTab = new BehaviorSubject(null);
    currentTabChanges$ = this.currentTab.asObservable();

    private filterToDisable = new BehaviorSubject(null);
    shouldDisableFilter$ = this.filterToDisable.asObservable();

    private gatewaysFilter = new Subject();
    gatewaysFilterChanged$ = this.gatewaysFilter.asObservable();

    constructor() { }

    changeNewTagsStatus(tagsStatus: TagsStatus) {
        this.newTagsCreated.next(tagsStatus);
    }

    updateFiltersStatus(filtersStatus: {updateDevices: boolean, updateDataPoints: boolean}) {
        this.filters.next(filtersStatus);
    }

    applyFilter(filters: FilterParams) {
        this.filters.next(filters);
    }

    clearFilter(filters: FilterParams) {
        this.filters.next(filters);
    }

    applyColumnsfilter(columnsToFilter: ColumnsToFilter) {
        this.columnsFilter.next(columnsToFilter);
    }

    clearColumnsFilter() {
        this.columnsFilter.next(null);
    }

    applyTimeframeFilter(timeframe: Date[]) {
        this.timeframeFilter.next(timeframe);
    }

    clearTimeframeFilter() {
        this.timeframeFilter.next(null);
    }

    currentTabHasChanged(tabName: string) {
        this.currentTab.next(tabName);
    }

    shouldDisableFilter(filterName: string) {
        this.filterToDisable.next(filterName);
    }

    changeGatewaysFilter(selectedGatewayList: Gateway[]){
        this.gatewaysFilter.next(selectedGatewayList)
    }
}
