import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'trimText'
})
export class TrimTextPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        if (value == null || args == null || args.length < 1) {
            return value;
        }
        return value.substring(0, args[0]) + '...';
    }

}
