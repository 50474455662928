import { Component, Input, OnInit } from '@angular/core';
import { MappedDataPoint } from '../../../models/data-point';
import { DialogConfigService } from '../../../services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-view-data-point-props-action',
    templateUrl: './view-data-point-props-action.component.html',
    styleUrls: ['./view-data-point-props-action.component.scss']
})
export class ViewDataPointPropsActionComponent implements OnInit {

    @Input()
    public selectedDataPoint: MappedDataPoint;


    constructor(private dialogConfigService: DialogConfigService) {

    }

    public showDataPointPropertiesDialog() {
        this.dialogConfigService.showDataPointPropertiesDialog(this.selectedDataPoint);
    }

    ngOnInit() {
    }

}
