<div class="content">
    <div class="grid">
        <div [ngClass]="{'col-12':READ_ONLY_MODE===viewMode, 'col-8':EDIT_MODE===viewMode}">
            <div class="grid cbms-align-center">
                <div class="col-6 template-heading">
                    <h3><span>Template Name:</span>
                    <input pInputText minlength="2" [(ngModel)]="template.name" #templateNameRef="ngModel"></h3>
                </div>
                <div class="col-6">
                    <p-message severity="error" text="Template name is required!"
                        *ngIf="!templateNameRef.value || templateNameRef.invalid && (templateNameRef.dirty || templateNameRef.touched)">
                    </p-message>
                </div>
            </div>

            <cbms-schedule-calendar [scheduleData]="template.weeklyScheduleCalendarData" [isEditable]="true"
                [shouldReset]="shouldReset" (emitPeriod)="onPeriodSelected($event)"></cbms-schedule-calendar>
                <p>Note: If a local time schedule has a default value defined, it will apply that value where no periods are defined in this template</p>
        </div>
        <div class="col-4" *ngIf="EDIT_MODE===viewMode">
            <form class="form-wrapper" [formGroup]="savePeriodForm" (ngSubmit)="savePeriod()">
                <h3>Schedule Options</h3>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Signal type</span>
                    </div>
                    <div class="col-5">
                        <p-dropdown data-cy="signal-type-dd" class="full-width" [options]="signalTypeList" formControlName="selectedSignalType">
                        </p-dropdown>
                    </div>
                </div>

                <h3>Add/edit period</h3>
                <p>Click on a period to edit it.<br />Overlapping periods not allowed.</p>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Start Time *</span>
                    </div>
                    <div class="col-5">
                        <p-calendar class="full-width"
                            data-cy="startTime"
                            [ngClass]="{'cbms-form-input-error': savePeriodForm.errors && savePeriodForm.errors.startTime}"
                            [timeOnly]="true" hourFormat="24" formControlName="startTime">
                        </p-calendar>
                    </div>
                    <div class="col-4" [ngClass]="{ 'cbms-opacity-06': isPeriodEditing }">
                        <span>All day</span>
                        <p-checkbox class="cbms-small-margin-left" [binary]="true" [formControl]="allDayPeriod">
                        </p-checkbox>
                    </div>

                </div>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Stop Time *</span>
                    </div>
                    <div class="col-5">
                        <p-calendar class="full-width"
                            data-cy="stopTime"
                            [ngClass]="{'cbms-form-input-error': savePeriodForm.errors && savePeriodForm.errors.stopTime}"
                            [timeOnly]="true" hourFormat="24" formControlName="stopTime">
                        </p-calendar>
                    </div>
                </div>
                <div class="grid cbms-align-center">
                    <div class="col-3">
                        <span>Value *</span>
                    </div>
                    <div class="col-5" [ngClass]="{'cbms-form-input-error': savePeriodForm.errors && savePeriodForm.errors.periodValue}">
                        <input data-cy="period-input" *ngIf="selectedSignalType.value !== 'Binary'"
                        class="full-width period-value"
                        pInputText type="text" formControlName="periodValue">
                        <p-dropdown data-cy="period-dd" *ngIf="selectedSignalType.value === 'Binary'" class="full-width period-value" [options]="binaryTypeList" formControlName="periodValue"></p-dropdown>
                    </div>
                </div>

                <div class="grid cbms-align-center" *ngIf="savePeriodForm.errors && savePeriodForm.errors.periodValue">
                    <div class="col-12 cbms-form-input-error">
                        <p>{{savePeriodForm.errors.periodValue}}</p>
                    </div>
                </div>

                <div class="grid">
                    <div class="col-12">
                        <span><strong>For new periods only</strong></span>
                    </div>
                </div>

                <div class="grid margin-bottom" [ngClass]="{ 'cbms-opacity-06': isPeriodEditing }">
                    <div class="col-3">
                        <span>Apply to *</span>
                    </div>
                    <div class="col-6">
                        <div class="grid"
                            *ngFor="let day of savePeriodForm.controls['applyTo']['controls']; let i=index;">
                            <div class="col-2">
                                <p-checkbox [formControl]="day" [binary]="true"></p-checkbox>
                            </div>
                            <div class="col-9">{{weekDays[i].name}}</div>
                        </div>
                    </div>
                </div>

                <div class="grid cbms-align-center">
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" type="submit"
                        [disabled]="!savePeriodForm.valid || isLoading" data-cy="apply-schedule">Apply</button>
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" type="button"
                        (click)="resetSavePeriodForm(); resetPeriodSelection()" data-cy="reset-schedule">Reset</button>
                    <button *ngIf="isPeriodEditing" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" type="button"
                            (click)="deletePeriod()" data-cy="remove-period">Remove Period</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="footer">
    <button *ngIf="EDIT_MODE===viewMode" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="saveTemplateChanges()"
            [disabled]="!templateNameRef.value"
            data-cy="save-button">Save
    </button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()" data-cy="close-button">Close</button>
</div>
