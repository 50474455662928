<div class="filter-item-global" *ngIf="!hideSiteFilter">
    <p-overlayPanel #op [style]="{height: '630px'}" (onShow)="onFilterShow()">
        <ng-template pTemplate>
            <p-radioButton name="siteSearchLogicalOperator" value="AND" label="AND"
                [(ngModel)]="siteSearchLogicalOperator" (onClick)="tagLogicalOperatorChanged()"></p-radioButton>
            <p-radioButton name="siteSearchLogicalOperator" class="cbms-margin-left" value="OR" label="OR"
                [(ngModel)]="siteSearchLogicalOperator" (onClick)="tagLogicalOperatorChanged()"></p-radioButton>
            <p-radioButton name="siteSearchLogicalOperator" class="cbms-margin-left" value="NOT" label="NOT"
                [(ngModel)]="siteSearchLogicalOperator" (onClick)="tagLogicalOperatorChanged()"></p-radioButton>
            <hr>
            <p-listbox [options]="siteTagList" styleClass="cbms-checkbox-list" [(ngModel)]="selectedSiteTagList"
                [multiple]="true" optionLabel="name" filterPlaceHolder="Tags" checkbox="true" filter="true"
                (onChange)="siteTagListChanged($event)"></p-listbox>

            <p-listbox #sitesListbox [options]="siteList" styleClass="cbms-checkbox-list" [(ngModel)]="selectedSiteList"
                [multiple]="true" optionLabel="name" filterPlaceHolder="Sites" checkbox="true" filter="true"
                (onChange)="siteListChanged($event)">
                <ng-template let-site pTemplate="item">
                    <div>
                        <div>{{site.name}} -
                            <ng-container *ngFor="let tag of site.tags">
                                <span class="cbms-chips">{{tag.name}}</span>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </p-listbox>

            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-right" label="Apply" (click)="onSiteChosen()" data-cy="apply-site-filter"></button>
            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__red-bg" label="Clear" (click)="clearSelection()" data-cy="apply-site-filter"></button>

        </ng-template>
    </p-overlayPanel>

    <button pButton [disabled]="shouldDisableFilter" type="text" icon="pi pi-chevron-down" iconPos="right" label="{{siteFilterLabel}}" class="cbms-site-filter-button" (click)="op.toggle($event)"></button>
</div>