<div class="filter-item filter-item__tags">
    <mat-icon svgIcon="tags"></mat-icon>
    <p-multiSelect #tagFilter class="filter-item small-margin min-width500" [options]="tagList"
        [(ngModel)]="selectedTagList" [filter]="true" filterBy="name" filterPlaceHolder="Tags"
        selectedItemsLabel="{0} Tags selected" maxSelectedLabels="1" placeholder="Tags" optionLabel="name" [showToggleAll]="false" 
        [virtualScroll]="true" virtualScrollItemSize="30" (onChange)="emitTagsData()" dataKey="id">
        <p-header class="filter-header">
            <h4>Search Type:</h4>
            <p-radioButton name="tagLogicalOperator" class="cbms-margin-left" value="OR" label="OR"
                [(ngModel)]="tagLogicalOperator" (onClick)="emitTagsData()"></p-radioButton>
            <p-radioButton name="tagLogicalOperator" value="AND" label="AND" [(ngModel)]="tagLogicalOperator"
                (onClick)="emitTagsData()"></p-radioButton>
            <hr>
        </p-header>

        <p-footer>
            <hr>
            <div class="filter-footer" *ngIf="tagList && tagList.length">
                <span *ngIf="tagFilter && !tagFilter._filteredOptions">showing
                    {{tagList.length}} of {{tagList.length}} Tags</span>
                <span *ngIf="tagFilter && tagFilter._filteredOptions">showing
                    {{tagFilter._filteredOptions.length}} of {{tagList.length}} Tags</span>
            </div>
        </p-footer>
    </p-multiSelect>
</div>