import { Component, Input, OnInit } from '@angular/core';
import { DialogConfigService } from '../../../services/dialog-config/dialog-config.service';
import { MappedDataPoint } from '../../../models/data-point';

@Component({
    selector: 'cbms-watch-action',
    templateUrl: './watch-action.component.html',
    styleUrls: ['./watch-action.component.scss']
})
export class WatchActionComponent implements OnInit {
    @Input()
    public selectedDataPoints: MappedDataPoint[];

    constructor(private dialogConfigService: DialogConfigService) {

    }

    ngOnInit() {
    }

    public showWatchDataDialog() {
        this.dialogConfigService.showWatchDataDialog(this.selectedDataPoints);
    }

}
