import { Injectable } from '@angular/core';
import { PAGINATION, ANY, MANUAL_DATAPOINTS_TABLE_SIZE } from 'src/app/config/constants';
import { DayOfWeek, WEEK_OF_MONTH_TEXT, Months } from '../../config/constants';
import { uuid } from 'uuidv4';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    private periodTypeName = {
        date: 'Single Date',
        dateRange: 'Date Range',
        weekNDay: 'Week and Day',
        calendarReference: 'Calendar Reference'
    };

    /**
     * We order a list of object items, T, by a given property belongging to the T object
     * @param list List of items we want sorted, ordered
     * @param fieldName The property by which we will apply the sort
     */
    orderListByFieldName<T, K extends keyof T>(list: T[], fieldName: K, subfieldName?: string): T[] {
        return list.sort((a, b) => {
            // we will make sure the property by which we're trying to sort is a string and normalize it in the process
            let normalizedA = '';
            let normalizedB = '';

            if (subfieldName) {
                normalizedA = a[fieldName][subfieldName].toString().toUpperCase();
                normalizedB = b[fieldName][subfieldName].toString().toUpperCase();
            } else {
                normalizedA = a[fieldName].toString().toUpperCase();
                normalizedB = b[fieldName].toString().toUpperCase();
            }

            return normalizedA.localeCompare(normalizedB, undefined, { numeric: true, sensitivity: 'base' });
        });
    }

    getDataPointsPageSize() {
        const localStoragePageSize = parseInt(window.localStorage.getItem(MANUAL_DATAPOINTS_TABLE_SIZE));
        if (localStoragePageSize > 0 && localStoragePageSize <= 1000) {
            return localStoragePageSize;
        }
        return PAGINATION.SIZE;
    }

    mapExceptionSchedule(exceptionScheduleObject: any) {
        return exceptionScheduleObject.map(exception => {
            exception.timeValues.forEach(element => {
                element.id = uuid();
            });
            let periodType = Object.keys(exception.period)[0];
            return Object.assign({}, exception, { summary: this.generateExceptionScheduleEventSummary(exception, periodType) }, { name: exception.name || 'No name', type: this.periodTypeName[periodType], id: uuid() });
        });
    }

    generateExceptionScheduleEventSummary(exception, periodType) {
        let summary = '';
        if (periodType === 'date') {
            summary = this.generateDate(exception.period[periodType]);
        }
        if (periodType === 'calendarReference') {
            summary = `Calendar ID: ${exception.period[periodType].instance}`;
        }
        if (periodType === 'dateRange') {
            summary = `${this.generateDate(exception.period[periodType].startDate)} - ${this.generateDate(exception.period[periodType].endDate)}`
        }
        if (periodType === 'weekNDay') {
            summary = `${this.replaceAnyWithStar(DayOfWeek[this.getOptionNumber(exception.period[periodType].dayOfWeek)])}, 
            ${this.replaceAnyWithStar(WEEK_OF_MONTH_TEXT[this.getOptionNumber(exception.period[periodType].weekOfMonth)])}, 
            ${this.replaceAnyWithStar(Months[this.getOptionNumber(exception.period[periodType].month)])}`;
        }
        return summary;
    }

    generateDate(dateObj: any) {
        return `${dateObj.day}.${dateObj.month}.${dateObj.year}`.replace(/null/g, '*');
    }

    replaceAnyWithStar(item: any) {
        return `${item}`.replace(ANY, '*');
    }

    getOptionNumber(optionNr: number) {
        if (optionNr === null) {
            return 0;
        }
        return optionNr;
    }
}
