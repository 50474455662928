import { Injectable } from '@angular/core';
import { DataPoint, MappedDataPoint, WeeklyScheduleObject } from '../../models/data-point';

import { DialogService } from 'primeng/dynamicdialog';
import { SetpointDialogComponent } from '../../dialogs/setpoint/setpoint.dialog';
import { TelemetryDialogComponent } from '../../dialogs/telemetry/telemetry.dialog';
import { TagManagementComponent } from '../../dialogs/tag-management/tag-management.component';
import { DataPointPropertiesComponent } from '../../dialogs/data-point-properties/data-point-properties.component';
import { WatchDataPointsComponent } from '../../dialogs/watch-data-points/watch-data-points.component';
import { GatewayMaintenanceComponent } from '../../dialogs/gateway-maintenance/gateway-maintenance.component';
import _ from 'lodash';
import { Gateway } from 'src/app/models/gateway';
import { CopyScheduleComponent } from 'src/app/dialogs/copy-schedule/copy-schedule.component';
import { ScheduleCalendarDialogComponent } from 'src/app/dialogs/schedule-calendar/schedule-calendar.component';
import { TemplateCalendarDialogComponent } from '../../dialogs/template-calendar/template-calendar.component';
import { ExceptionScheduleDialogComponent } from 'src/app/dialogs/exception-schedule/exception-schedule-dialog.component';
import { ExceptionScheduleTemplateComponent } from 'src/app/dialogs/exception-schedule-template/exception-schedule-template.component';
import { CreateTwinFromScheduleDialogComponent } from 'src/app/dialogs/create-twin-from-schedule-dialog/create-twin-from-schedule-dialog.component';
import { CompareScheduleToTwinDialogComponent } from 'src/app/dialogs/compare-schedule-to-twin-dialog/compare-schedule-to-twin-dialog.component';
import { WriteTwinToScheduleDialogComponent } from 'src/app/dialogs/write-twin-to-schedule-dialog/write-twin-to-schedule-dialog.component';
import { SitesTagManagementComponent } from 'src/app/dialogs/sites-tag-management/sites-tag-management.component';
import { DATAPOINTS_OP } from 'src/app/config/constants';
import { AddOcTenantDialogComponent } from '../../dialogs/add-oc-tenant-dialog/add-oc-tenant-dialog.component';
import {
    CreateTwinFromDatapointDialogComponent
} from '../../dialogs/create-twin-from-datapoint-dialog/create-twin-from-datapoint-dialog.component';
import { AssignSiteComponent } from 'src/app/dialogs/assign-site/assign-site.component';

import { DashboardsManagementComponent } from 'src/app/dialogs/dashboards-management/dashboards-management.component';
import { SmartAlertDetailsComponent } from 'src/app/dialogs/smart-alert-details/smart-alert-details.component';

@Injectable()
export class DialogConfigService {
    constructor(private dialogService: DialogService) {
    }

    public showTelemetryDialog(dataPoints: any[], showPreview: boolean = false) {
        const header = showPreview ? `Data Point - ${dataPoints[0].objectName} (last 72 hours)` : 'Data Point Analysis';

        this.dialogService.open(TelemetryDialogComponent, {
            header,
            contentStyle: { 'padding': '0' },
            width: '96%',
            closable: false,
            data: {
                dataPoints,
                showPreview
            }
        });
    }

    public showSetpointDialog({dataPoints, operation}) {
        const header = operation === DATAPOINTS_OP.MANAGE_VALUES ? 'Manage Value' : 'Write Twin to Datapoint'
        this.dialogService.open(SetpointDialogComponent, {
            header: header,
            contentStyle: { 'padding': '0' },
            width: '96%',
            closable: false,
            data: {dataPoints, operation}
        });
    }

    public showTagManagementDialog(dataPoints) {
        return this.dialogService.open(TagManagementComponent, {
            header: 'Edit Tags',
            contentStyle: { 'padding': '0' },
            width: '96%',
            closable: false,
            data: dataPoints
        });
    }

    public showSitesTagManagementDialog(sites) {
        return this.dialogService.open(SitesTagManagementComponent, {
            header: 'Edit Tags',
            contentStyle: { 'padding': '0' },
            width: '96%',
            closable: false,
            data: sites
        });
    }

    public showWatchDataDialog(dataPoints) {
        return this.dialogService.open(WatchDataPointsComponent, {
            header: 'Watch Values',
            contentStyle: { 'padding': '0' },
            width: '96%',
            closable: false,
            data: dataPoints
        });
    }

    public showDataPointPropertiesDialog(dataPoint) {
        this.dialogService.open(DataPointPropertiesComponent, {
            header: 'Data Point Properties',
            contentStyle: { 'padding': '0' },
            width: '96%',
            closable: false,
            data: dataPoint
        });
    }

    public showGatewayMaintenanceDialog(gateway: Gateway) {
        this.dialogService.open(GatewayMaintenanceComponent, {
            header: `Gateway Maintenance: ${gateway.name}`,
            contentStyle: { 'padding': '0' },
            width: '50%',
            closable: false,
            data: gateway
        });
    }

    public showCopyDialog(dataPoints: MappedDataPoint[], copySource: MappedDataPoint) {
        this.dialogService.open(CopyScheduleComponent, {
            header: 'Schedule Change',
            contentStyle: { 'padding': '0' },
            width: '96%',
            height: '100%',
            style: {
                maxHeight: '100%',
            },
            closable: false,
            data: { dataPoints, copySource }
        });
    }

    public showScheduleDialog(scheduleData: {dataPoint: MappedDataPoint, isTwin: boolean}) {
        this.dialogService.open(ScheduleCalendarDialogComponent, {
            header: 'Weekly Schedule',
            contentStyle: { 'padding': '0' },
            width: '96%',
            style: {
                maxHeight: '95%',
            },
            closable: false,
            data:  scheduleData
        });
    }

    public showTemplateDialog(scheduleData: { isReadOnly: boolean; name: any; id: string; weeklyScheduleObject: WeeklyScheduleObject }) {
        return this.dialogService.open(TemplateCalendarDialogComponent, {
            header: 'Weekly Schedule Template',
            contentStyle: { 'padding': '0' },
            width: '96%',
            style: {
                maxHeight: '95%',
            },
            closable: false,
            data:  scheduleData
        });
    }

    public showExceptionScheduleDialog(dataPoint: MappedDataPoint) {
        this.dialogService.open(ExceptionScheduleDialogComponent, {
            header: 'Exception Schedule',
            contentStyle: { 'padding': '0' },
            width: '96%',
            style: {
                maxHeight: '95%',
            },
            closable: false,
            data:  dataPoint
        });
    }

    public showExceptionScheduleTemplateDialog(exceptioScheduleData) {
        return this.dialogService.open(ExceptionScheduleTemplateComponent, {
            header: 'Exception Schedule Template',
            contentStyle: { 'padding': '0' },
            width: '96%',
            style: {
                maxHeight: '95%',
            },
            closable: false,
            data:  exceptioScheduleData
        });
    }

    public showCreateTwinFromScheduleDialog(dataPoints: MappedDataPoint[]) {
        return this.dialogService.open(CreateTwinFromScheduleDialogComponent, {
            header: 'Create Twin from Schedule',
            contentStyle: { 'padding': '0' },
            width: '96%',
            closable: false,
            data: dataPoints
        });
    }

    public showCompareScheduleToTwinDialog(dataPoints: MappedDataPoint[]) {
        this.dialogService.open(CompareScheduleToTwinDialogComponent, {
            header: 'Compare Schedule To Twin',
            contentStyle: { 'padding': '0' },
            width: '96%',
            style: {
                maxHeight: '95%',
            },
            closable: false,
            data: dataPoints
        });
    }

    public showWriteTwinToScheduleDialog(dataPoints: MappedDataPoint[]) {
        this.dialogService.open(WriteTwinToScheduleDialogComponent, {
            header: 'Write Twin To Schedule',
            contentStyle: { 'padding': '0' },
            width: '96%',
            style: {
                maxHeight: '95%',
            },
            closable: false,
            data: dataPoints
        });
    }

    public showCreateTwinFromDatapointDialog(dataPoints: MappedDataPoint[]) {
        return this.dialogService.open(CreateTwinFromDatapointDialogComponent, {
            header: 'Create Twin from Datapoint Value',
            contentStyle: { 'padding': '0' },
            width: '96%',
            closable: false,
            data: dataPoints
        });
    }

    public showAddOcTenantDatapointDialog(dataPoints: DataPoint[]) {
        return this.dialogService.open(AddOcTenantDialogComponent, {
            header: 'Optimum Commercial Assignment',
            contentStyle: { 'padding': '0' },
            width: '56%',
            closable: false,
            data: dataPoints
        });
    }

    public showAssignDialog(idList: string[], assignBy: string) {
        return this.dialogService.open(AssignSiteComponent, {
            header: 'Assign Site',
            contentStyle: { 'padding': '0' },
            width: '40%',
            style: {
                maxHeight: '95%',
            },
            closable: false,
            data: {idList, assignBy}
        });
    }

    public showDashboardsManagementDialog(sites) {
        return this.dialogService.open(DashboardsManagementComponent, {
            header: 'Manage Dashboards',
            contentStyle: { 'padding': '0' },
            width: '40%',
            closable: false,
            data: sites
        });
    }

    public showSmartAlertDetailsDialog(alarm: any) {
        return this.dialogService.open(SmartAlertDetailsComponent, {
            header: 'Smart Alert Details',
            contentStyle: { 'padding': '0' },
            width: '70%',
            closable: false,
            data: alarm
        });
    }
}
