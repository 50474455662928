export enum Status {
    SUCCESS = 1,
    WARNING = 0,
    ERROR = -1,
    DISABLED = -2

}


export const isSucces = (status: Status) => status === Status.SUCCESS;

export const isWarning = (status: Status) => status === Status.WARNING;

export const isError = (status: Status) => status === Status.ERROR;

export const isDisabled = (status: Status) => status === Status.DISABLED;

