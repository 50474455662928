import { Injectable, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, Directive } from '@angular/core';
import * as Highcharts from 'highcharts';
import _ from 'lodash';
import moment from 'moment-timezone';
import { ChartConfig } from './timeseries-chart/interfaces/timeseries-chart-config.interface';

@Directive()
@Injectable()
export abstract class HighchartsBaseComponent implements OnChanges {
    @Input()
    public chartConfig: ChartConfig = null;

    @Input()
    public timezone: string;

    @ViewChild('chartWrapper', { static: true })
    public chartElement: ElementRef;

    protected chart: Highcharts.Chart = null;

    constructor() {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['chartConfig'] != null) {
            if (this.chartConfig == null && this.chart != null) {
                // cleanup
                this.chart.destroy();
                this.chart = null;
            }

            if (this.chartConfig != null) {
                this.createChart();
            }
        }
    }

    protected abstract getChartOptions(config: ChartConfig): Highcharts.Options;

    protected createChart() {
        const mergedOptions = _.merge(this.getRootConfig(this.chartConfig), this.getChartOptions(this.chartConfig));
        this.chart = Highcharts.chart(this.chartElement.nativeElement,
            mergedOptions, this.chartCallback);
    }

    protected getRootConfig(config: ChartConfig): Highcharts.Options {
        return {
            credits: {
                enabled: false
            },
            chart: {
                width: config.width,
                height: config.height,
            },
            title: {
                text: config.title
            },
            subtitle: {
                text: config.subTitle
            },
            legend: {
                enabled: true
            },
            time: {
                timezone: this.timezone || moment.tz.guess()
            }
        };
    }

    //use any because of missing showResetZoom declaration on Chart class in highcharts.d.ts
    private chartCallback = (chart: any) => {
        if (this.chartConfig && this.chartConfig.zoomValues) {
            chart.xAxis[0].setExtremes(this.chartConfig.zoomValues.min, this.chartConfig.zoomValues.max);
            chart.showResetZoom();
        }
    }
}
