import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MappedDataPoint, DataPoint } from 'src/app/models/data-point';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-create-twin-from-schedule-action',
    templateUrl: './create-twin-from-schedule-action.component.html',
    styleUrls: ['./create-twin-from-schedule-action.component.scss']
})
export class CreateTwinFromScheduleActionComponent implements OnInit {

    @Input()
    public selectedDataPoints: MappedDataPoint[];

    @Output()
    public updateTwinEvent = new EventEmitter<DataPoint[]>();

    constructor(private dialogConfigService: DialogConfigService) {
    }

    ngOnInit() {
    }

    public showCreateTwinDialog() {
        const ref = this.dialogConfigService.showCreateTwinFromScheduleDialog(this.selectedDataPoints);
        ref.onClose.subscribe((dataPoints: DataPoint[]) => {
            if (dataPoints) {
                this.updateTwinEvent.emit(dataPoints);
            }
        });
        
    }
}
