<button pButton class="p-button-raised p-button-text cbms-row-button" (click)="deleteMenu.show($event)"
        pTooltip="delete template" showDelay="1000" hideDelay="500">
    <mat-icon svgIcon="delete"></mat-icon>
</button>

<p-overlayPanel #deleteMenu appendTo="body">
    <p>
        Please confirm you want to continue with the removal<br>of the schedule template
        <strong>{{selectedName}}</strong>.
    </p>
    <hr>
    <div class="delete-action-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                (click)="deleteEntity(); deleteMenu.hide();">Confirm
        </button>
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="deleteMenu.hide()">Cancel</button>
    </div>
</p-overlayPanel>
