<div class="cbms-content-wrapper" style="max-width: 1024px; margin: 25px auto;">

    <p-tabView (onChange)="handleTabChange($event)">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <mat-icon svgIcon="simple-door"></mat-icon>
            </ng-template>

            <div class="grid">
                <div class="col">
                    <div class="filter-buttons-wrapper">
                        <button pButton type="button" class="p-button-raised p-button-rounded"
                            [ngClass]="selectedFilter === 'all' ? 'selected' : ''"
                            (click)="filterLocationCards('all')">All</button>
                        <button pButton type="button" class="p-button-raised p-button-rounded"
                            [ngClass]="selectedFilter === item.key ? 'selected' : ''"
                            *ngFor="let item of locationsFilters | keyvalue"
                            (click)="filterLocationCards(item.key)">{{item.key}}</button>
                    </div>
                </div>
            </div>

            <div class="grid">
                <div class="col-12 p-sm-12 p-md-6 p-lg-6" *ngFor="let locationCard of locationCards">
                    <div class="card min-height200">
                        <div class="card__header">
                            <mat-icon svgIcon="simple-door"></mat-icon>
                            <h2>{{locationCard.label}}</h2>
                        </div>
                        <div class="card__content">
                            <div class="content-row">
                                <div class="centered-content">
                                    <p class="big" *ngIf="locationCard.point.presentValue">
                                        {{locationCard.point.presentValue}}&deg;C</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>

        <p-tabPanel>
            <ng-template pTemplate="header">
                <mat-icon svgIcon="settings"></mat-icon>
            </ng-template>
            <div class="grid">
                <div class="col">
                    <div class="filter-buttons-wrapper">
                        <button pButton type="button" class="p-button-raised p-button-rounded"
                            [ngClass]="selectedFilter === 'all' ? 'selected' : ''"
                            (click)="filterEquipmentCards('all')">All</button>

                        <button pButton type="button" class="p-button-raised p-button-rounded"
                            [ngClass]="selectedFilter === item.key ? 'selected' : ''"
                            *ngFor="let item of equipmentsFilters | keyvalue" (click)="filterEquipmentCards(item.key)">
                            <mat-icon svgIcon="{{item.value[0].icon}}"></mat-icon>
                            {{item.key}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="grid">
                <div class="col-12 p-sm-12 p-md-6 p-lg-6" *ngFor="let equipmentCard of equipmentCards">
                    <div class="card min-height200">
                        <div class="card__header" (click)="goToEquipment(equipmentCard)">
                            <mat-icon svgIcon="{{equipmentCard.icon}}"></mat-icon>
                            <h2>{{equipmentCard.label}}</h2>
                        </div>
                        <div class="card__content">
                            <div class="grid content-row vertical-space"></div>

                            <div class="grid content-row" *ngIf="equipmentCard.kpi.signalType">
                                <ng-container [ngSwitch]="equipmentCard.kpi.signalType">
                                    <ng-container *ngSwitchCase="'Binary'">
                                        <div class="col-10">
                                            <p class="font_size20">{{equipmentCard.kpi.label}}</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="font_size20">
                                                <p-inputSwitch [disabled]="!equipmentCard.kpi.isWritable"
                                                    [(ngModel)]="equipmentCard.kpi.presentValue"
                                                    (onChange)="handleChange($event, equipmentCard)"></p-inputSwitch>
                                            </p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <div class="col-8">
                                            <p class="font_size20">{{equipmentCard.kpi.label}}</p>
                                        </div>
                                        <div class="col-4">
                                            <p class="font_size20">{{equipmentCard.kpi.presentValue}} <span style="font-size:12px">({{equipmentCard.kpi.units}})</span></p>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
