import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/util.service';

@Component({
    selector: 'cbms-data-point-properties',
    templateUrl: './data-point-properties.component.html',
    styleUrls: ['./data-point-properties.component.scss']
})
export class DataPointPropertiesComponent implements OnInit {
    public isLoadingProperties: boolean = false;
    public properties: TreeNode[];

    statusFlags = [
        'In Alarm',
        'Fault',
        'Overridden',
        'Out of Service'
    ];

    priorityArray = [
        '1 (Manual Life Safety)',
        '2 (Automatic Life Safety)',
        '3 (Not Named)',
        '4( Not Named)',
        '5 (Critical Equipment Control)',
        '6 (Minimum On/Off)',
        '7 (Not Named)',
        '8 (Manual Operator)',
        '9 (Not Named)',
        '10 (Not Named)',
        '11 (Not Named)',
        '12 (Not Named)',
        '13 (Not Named)',
        '14 (Not Named)',
        '15 (Not Named)',
        '16 (Not Named)'
    ];

    propertiesOrder = {
        'object_name': 1,
        'description': 2,
        'present_value': 3,
        'inactive_text': 4,
        'active_text': 5,
        'units': 6,
        'state_text': 7,
        'priority_array': 8,
        'relinquish_default': 9,
        'weekly_schedule': 10,
        'exception_schedule': 11,
        'status_flags': 12,
        'out_of_service': 13,
        'reliability': 14,
        'other': 15
    };

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private backendService: BackendService,
        private utilsService: UtilsService) { }

    ngOnInit() {
        this.isLoadingProperties = true;
        this.backendService.getDataPointProperties(this.config.data.id).subscribe(dataPointProperties => {
            if (!dataPointProperties) {
                this.properties = null;
            } else {
                this.properties = this.utilsService.orderListByFieldName(this.transformProperties(dataPointProperties), 'position');
            }
            this.isLoadingProperties = false;
        })
    }

    transformProperties(dataPointProperties) {
        return Object.keys(dataPointProperties).map(key => {
            let item: any = {
                position: this.propertiesOrder[key] ? this.propertiesOrder[key] : this.propertiesOrder.other,
            };

            if (key === 'status_flags') {
                item.data = {
                    name: key,
                    value: dataPointProperties[key]
                };
                item.children = [
                    ...dataPointProperties[key].map((value, index: number) => {
                        return {
                            data: {
                                name: this.statusFlags[index],
                                value: value
                            }
                        }
                    })
                ];

                return item;
            };

            if (key === 'priority_array') {
                item.data = {
                    name: key,
                    value: dataPointProperties[key].map(value => this.parsePriorityArrayValue(value))
                };
                item.children = [
                    ...dataPointProperties[key].map((value, index: number) => {
                        return {
                            data: {
                                name: this.priorityArray[index],
                                value: this.parsePriorityArrayValue(value)
                            }
                        }
                    })
                ];

                return item;
            }

            item.data = {
                name: key,
                value: JSON.stringify(dataPointProperties[key])
            }

            return item;
        });
    }

    parsePriorityArrayValue(value) {
        if (value === 0) { return 0; }
        return value ? value : 'Null';
    }

    close() {
        this.ref.close();
    }

}
