<p-toast [style]="{ marginTop: '80px' }"></p-toast>

<div class="app-body" *ngIf="user">
    <div class="app-header" *ngIf="!shouldHideHeader">
        <nav class="nav-bar flex">
            <p-tabMenu [style]="{'min-width': '940px'}" [model]="menuItems">
                <ng-template pTemplate="item" let-item let-i="index">
                    <a [routerLink]="item.routerLink" class="p-menuitem-link">
                        <mat-icon class="tab-icon" [svgIcon]="item.icon"></mat-icon>
                        {{ item.label }}
                    </a>
                </ng-template>
            </p-tabMenu>

            <div class="header-toolbar cbms-margin-left-auto">
                <div class="header-toolbar-container">
                    <cbms-customer-filter
                        *ngIf="userIsPresent"
                    ></cbms-customer-filter>
                    <cbms-site-filter *ngIf="userIsPresent"></cbms-site-filter>

                    <mat-icon
                        class="menu-icon"
                        svgIcon="user"
                        mat-button
                        [matMenuTriggerFor]="userMenu"
                    ></mat-icon>
                    <mat-menu #userMenu="matMenu">
                        <div class="user-info-wrapper">
                            <p>
                                <mat-icon
                                    class="menu-icon"
                                    svgIcon="user"
                                ></mat-icon>
                                <span class="user-info-title"
                                    >Logged in as</span
                                >
                            </p>
                            <p>
                                {{ user }}
                            </p>
                        </div>
                        <div class="user-info-button-wrapper">
                            <button
                                pButton
                                class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                                (click)="logout()"
                            >
                                Logout
                            </button>
                        </div>
                    </mat-menu>

                    <mat-icon class="menu-icon-logo" svgIcon="eon"></mat-icon>
                </div>
            </div>
        </nav>
    </div>

    <div class="app-content">
        <router-outlet></router-outlet>
    </div>
</div>
