import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UtilsService } from 'src/app/services/utils/util.service';
import { Observable, Subscription } from 'rxjs';
import { Site } from 'src/app/models/site';
import { AlertData } from 'src/app/models/alert-rule.model';
import { FilterService } from 'src/app/components/filter/filter.service';
import { DataService } from 'src/app/services/data/data.service';

@Component({
    selector: 'cbms-smart-alert',
    templateUrl: './smart-alert.component.html',
    styleUrls: ['./smart-alert.component.scss'],
})
export class SmartAlertComponent implements OnInit {
    public cols: any[] = [
        { field: 'name', header: 'Rule Name', position: 2, width: '100px' },
        { field: 'type', header: 'Rule Type', position: 3, width: '100px' },
        {
            field: 'isEnabled',
            header: 'Activation Status',
            position: 4,
            width: '150px',
        },
    ];

    public rulesList: any[];
    public selectedRuleData: any;

    public siteList: Site[];
    public selectedSite: Site;
    public customerId: string;
    public selectedSiteIdList: string[] = [];
    public shouldDisableResources: boolean = false;
    public alertRules$: Observable<AlertData[]>;
    public viewRules = true;
    public addEditRule = false;
    private sitesSubscription: Subscription;

    constructor(
        private backendService: BackendService,
        private utilsService: UtilsService,
        private dataService: DataService,
        private filterService: FilterService,
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('smartAlert');
        if (
            this.customerId ||
            JSON.parse(sessionStorage.getItem('selectedCustomer'))
        ) {
            this.getSiteList();
            this.getAlertRules();
        }

        this.filterService.customerFilterChanges$.subscribe((data) => {
            this.customerId = data.selectedCustomer.id;
            this.getSiteList();
            this.getAlertRules();
        });
    }

    getSiteList() {
        if (this.sitesSubscription) {
            this.sitesSubscription.unsubscribe();
        }
        this.sitesSubscription = this.backendService
            .getSitesByCustomer()
            .subscribe((response) => {
                this.siteList = this.utilsService.orderListByFieldName(
                    response.content,
                    'name'
                );
            });
    }

    onSiteChosen() {
        this.getAlertRules();
    }

    getAlertRules() {
        this.alertRules$ = this.backendService.getAlertRules(
            this.selectedSite?.id,
            this.customerId
        );
    }

    addNewRule() {
        this.viewRules = false;
        this.addEditRule = true;
    }

    changeAlertStatus(event: any, rule: AlertData) {
        this.backendService
            .changeAlertStatus(rule.isEnabled, rule.id)
            .subscribe(
                (res) => {
                    this.notificationService.addSuccessMessage(
                        'Smart Alert Rule',
                        'Rule successfully updated!'
                    );
                },
                (err) => {
                    rule.isEnabled = !rule.isEnabled;
                }
            );
    }

    onClose(shouldHideAddComponent: boolean) {
        if (shouldHideAddComponent) {
            this.viewRules = true;
            this.addEditRule = false;
            this.selectedRuleData = null;
        }
    }

    editRule(rule: any) {
        this.selectedRuleData = rule;
        this.viewRules = false;
        this.addEditRule = true;
    }

    deleteSmartAlertRule(ruleId: string) {
        this.backendService.deleteAlert([ruleId]).subscribe(
            () => {
                this.notificationService.addSuccessMessage(
                    'Delete SMART Alert',
                    'SMART alert rule deleted successfully'
                );
				this.getAlertRules();
            },
            (err) => {
                this.notificationService.addErrorMessage(
                    'Delete SMART Alert',
                    err
                );
            }
        );
    }
}
