import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Site } from 'src/app/models/site';
import { Customer } from 'src/app/models/customer';

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    private siteFilterSource = new Subject<Site[]>();
    siteFilterChanges$ = this.siteFilterSource.asObservable();

    private customerFilterSource = new Subject<{'selectedCustomer': Customer, 'isInitLoad': boolean}>();
    customerFilterChanges$ = this.customerFilterSource.asObservable();

    constructor() {
    }

    siteChanged(selectedSiteList: Site[]) {
        this.siteFilterSource.next(selectedSiteList);
    }

    customerChanged(selectedCustomer: Customer, isInitLoad: boolean = false) {
        this.customerFilterSource.next({selectedCustomer, isInitLoad });
    }

    getSelectedFilter(filterName: string, page?: string) {
        return JSON.parse(sessionStorage.getItem(`${page}-${filterName}`));
    }

    applyFilter(page: string, filterDataOjb: any) {
        Object.keys(filterDataOjb).map(filterName => {
            sessionStorage.setItem(`${page}-${filterName}`, JSON.stringify(filterDataOjb[filterName]));
        });
    }

    clearFilter(page: string, filterName: string) {
        sessionStorage.removeItem(`${page}-${filterName}`);
    }

    clearFilters(page: string, filterDataOjb: any) {
        Object.keys(filterDataOjb).map(filterName => {
            sessionStorage.removeItem(`${page}-${filterName}`);
        });
    }
}
