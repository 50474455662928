<ng-container>
    <div [pTooltip]="'No datapoints selected.'"
         showDelay="1000"
         hideDelay="500"
         [tooltipDisabled]="selectedDataPoints.length">
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="showWriteTwinToDatapointDialog()"
                [disabled]="!selectedDataPoints.length">
            <mat-icon svgIcon="arrow_down"></mat-icon>
            Write Twin To Datapoint
        </button>
    </div>
</ng-container>
