<div class="content">
    <div class="cbms-spinner-wrapper" *ngIf="isLoadingProperties">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <p-treeTable class="cbms-generic-tree-table-style" *ngIf="properties" [value]="properties" [scrollable]="true" [scrollHeight]="'500px'">
        <ng-template pTemplate="header">
            <tr>
                <th class="cbms-text-align-left" style="width: 30%; padding-left: 40px;">Name</th>
                <th class="cbms-text-align-left">Value</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr>
                <td style="width: 30%">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{rowData.name}}
                </td>
                <td>{{rowData.value}}</td>
            </tr>
        </ng-template>
    </p-treeTable>

    <div class="cbms-spinner-wrapper" *ngIf="!properties && !isLoadingProperties">
        <span class="no-datapoints-text">No properties found</span>
    </div>
</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>