import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataPoint, MappedDataPoint } from '../../../models/data-point';
import { DialogConfigService } from '../../../services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-assign-oc-tenant-action',
    templateUrl: './assign-oc-tenant-action.component.html',
    styleUrls: ['./assign-oc-tenant-action.component.scss']
})
export class AssignOcTenantActionComponent implements OnInit {

    @Input()
    public selectedDataPoints: DataPoint[];

    @Output()
    public updateOcSiteEvent = new EventEmitter<DataPoint[]>();

    shouldDisableButton = false;

    constructor(private dialogConfigService: DialogConfigService) {
    }

    ngOnInit() {
        this.shouldDisableButton = !sessionStorage.getItem('userRolePermissionList').includes("SYNC_WITH_OPTIMUM_COMMERCIAL_BUTTON.CORE_APP");
    }

    public showCreateTwinDialog() {
        const ref = this.dialogConfigService.showAddOcTenantDatapointDialog(this.selectedDataPoints);
        ref.onClose.subscribe((dataPoints: DataPoint[]) => {
            if (dataPoints) {
                this.updateOcSiteEvent.emit(dataPoints);
            }
        });

    }
}
