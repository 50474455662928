import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MappedDataPoint } from 'src/app/models/data-point';

@Component({
  selector: 'cbms-select-row',
  templateUrl: './select-row.component.html',
  styleUrls: ['./select-row.component.scss']
})
export class SelectRowComponent implements OnInit {

  @Input()
  public dataPoint: MappedDataPoint[];
  
  @Output() valueChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public selectRow() {
    this.valueChange.emit(this.dataPoint);
  }
}
