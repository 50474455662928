<div class="filter-component">
    <div class="col-12 filter-wrapper">
        <span class="filter-text">Filter</span>
        <cbms-gateway-filter *ngIf="config.gatewayFilter" (filterChanged)="onGatewayChange($event)"
                             [selectedGatewayList]="filters.gatewayFilter"
        > </cbms-gateway-filter>

        <cbms-device-filter *ngIf="config.deviceFilter" [selectedDeviceList]="filters.deviceFilter"
            (valueChange)="onDeviceChange($event)"
        [gatewaysFilterList]="filters.gatewayFilter"
        ></cbms-device-filter>
        <cbms-tag-filter *ngIf="config.tagFilter" [selectedTagList]="filters.tagFilter"
            [tagLogicalOperator]="filters.tagLogicalOperator" (valueChange)="onTagChange($event)"></cbms-tag-filter>
        <cbms-quick-filter *ngIf="config.quickFilterText" [styleClass]="'filter-item'" [quickFilterText]="filters.quickFilterText"
            (valueChange)="onQuickFilterChange($event)"></cbms-quick-filter>

        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto filter-item" label="Apply Filter" (click)="onFilter()" data-cy="apply-filter"></button>
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__red-bg filter-item" label="Clear Filter" (click)="clearFilters()" data-cy="clear-filter"></button>
    </div>

    <cbms-field-filter *ngIf="config.fieldFilter" [selectedFieldFilter]="filters.fieldFilter"
        [fieldFilterOptions]="fieldFilterOptions" (valueChange)="onFieldFilterChange($event)"></cbms-field-filter>
</div>
