import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cbms-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  boilerTimer: boolean = false;
  boilerEnable: boolean = true;
  boilerFault: boolean = true;
  schedule: boolean = false;
  supplyFan: boolean = false;
  supplyFanTrip: boolean = false;
  supplyFanEnable: boolean = true;
  fanCommand: boolean = false;
  circuitTimer: boolean = true;
  valveCheckbox: boolean = true;
  pump: boolean = true;
  ehaustFan: boolean = true;

  boilerTimerCheckbox: boolean = false;
  boilerEnableCheckbox: boolean = false;
  boilerFaultCheckbox: boolean = true;
  waterFlowCheckbox: boolean = false;
  airTemperatureCheckbox: boolean = false;
  returnAirTemperatureCheckbox: boolean = false;
  scheduleCheckbox: boolean = false;
  supplyFanCheckbox: boolean = false;
  supplyFanTripCheckbox: boolean = false;
  circuitTimerCheckbox: boolean = true;

  heatingValve: string = '100%';

  constructor() { }

  ngOnInit(): void {
  }

}
