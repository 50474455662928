import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataPoint } from 'src/app/models/data-point';
import { BackendService } from 'src/app/services/backend/backend.service';
import _ from 'lodash';
import { NotificationService } from '../../services/notification/notification.service';
import { Subscription } from 'rxjs';

interface ExtendedDataPoint extends DataPoint {
	result: {
		code: string;
		message: string;
	}
	success: boolean;
}

@Component({
	selector: 'cbms-create-twin-from-datapoint-dialog',
	templateUrl: './create-twin-from-datapoint-dialog.component.html',
	styleUrls: ['./create-twin-from-datapoint-dialog.component.scss']
})
export class CreateTwinFromDatapointDialogComponent implements OnInit {
	public dataPoints: ExtendedDataPoint[] = [];
	public createTwinFromScheduleSubscription: Subscription;
	public isLoading = false;
	public isLoadingDataPoints = false;
	public requestSent = false;

	public cols: any[] = [
		{ field: 'siteName', header: 'Site', sortable: true, width: '' },
		{ field: 'deviceName', header: 'Device', sortable: true, width: '' },
		{ field: 'objectName', header: 'Name', sortable: true, width: '' },
		{ field: 'description', header: 'Source Description', sortable: true, width: '' },
		{ field: 'customName', header: 'Custom Name', sortable: true, width: '' },
		{ field: 'result', header: 'Result', sortable: true, width: '' }
	];

	constructor(public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private backendService: BackendService,
		private confirmationService: ConfirmationService,
		private notificationService: NotificationService) {
	}

	ngOnInit(): void {
		this.isLoadingDataPoints = true;
		const filterParams = {
			dataPointIdFilter: Object.keys(this.config.data).map((key) => this.config.data[key])
		}
		const pageSize = filterParams.dataPointIdFilter.length;

		this.backendService.getDataPointsDetails(filterParams, 0, pageSize).subscribe(response => {
			this.dataPoints = response.content.map((dataPoint: DataPoint) => {
				return Object.assign({}, dataPoint, { siteName: dataPoint.site.name, deviceName: dataPoint.deviceName, result: {} });
			});
			this.isLoadingDataPoints = false;
		});
	}

	public createTwin() {
		this.isLoading = true;
		this.requestSent = true;
		this.createTwinFromScheduleSubscription = this.backendService.createTwinFromDatapoint(this.dataPoints.map(dataPoint => dataPoint.id)).subscribe(response => {
			response.forEach(item => {
				this.dataPoints.forEach(dataPoint => {
					if (dataPoint.id === item.dataPointId) {
						dataPoint.result = item.result;
						dataPoint.success = item.success;
						if (item.success) {
                            dataPoint.twinDatapointValue = item.twinDatapointValue;
                            dataPoint.twinDatapointCreationDate = item.twinDatapointCreationDate;
                        }
					}
				});
			});

			this.isLoading = false;
		}, (err) => {
			this.isLoading = false;
			this.notificationService.addErrorMessage('Create Twin', err);
		});
	}

	public close() {
		if (this.createTwinFromScheduleSubscription) {
			this.createTwinFromScheduleSubscription.unsubscribe();
		}
		if (this.requestSent) {
			this.ref.close(this.dataPoints);
		} else {
			this.ref.close();
		}
	}

	public confirm() {
		if (this.dataPoints.filter(dataPoint => dataPoint.twinDatapointCreationDate).length) {
			this.confirmationService.confirm({
				message: `One or more of selected datapoints already have a twin,<br /> please confirm if you want to overwrite it.`,
				accept: () => {
					this.createTwin();
				}
			});
		} else {
			this.createTwin();
		}
	}
}
