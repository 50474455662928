import { Component, OnInit, ViewEncapsulation, Output, Input, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Customer } from 'src/app/models/customer';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FilterService } from '../filter.service';
import _ from 'lodash';
import { UtilsService } from 'src/app/services/utils/util.service';
import { DataService } from 'src/app/services/data/data.service';
import { NO_GLOBAL_FILTERS_TABS } from 'src/app/config/constants';

@Component({
	selector: 'cbms-customer-filter',
	templateUrl: './customer-filter.component.html',
	styleUrls: ['../filter.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CustomerFilterComponent implements OnInit {

	customerList: Customer[];
	selectedCustomer: Customer;
	hideCustomerFilter = false;

	constructor(private backendService: BackendService, private filterService: FilterService, private utilsService: UtilsService, 
		private dataService: DataService, private cd: ChangeDetectorRef) {
	}

	public ngOnInit(): void {
		this.dataService.currentTabChanges$.
            subscribe((tabName) => {
                if (!tabName) { return; }
                this.hideCustomerFilter = NO_GLOBAL_FILTERS_TABS.indexOf(tabName) !== -1;
				this.cd.detectChanges();
            });

		this.backendService.getCustomers().subscribe(customerList => {
			this.customerList = this.utilsService.orderListByFieldName(customerList.map(customer => { return { id: customer.id, name: customer.name }; }), 'name');

			this.backendService.getLastCustomer().subscribe(response => {
				if(_.isEmpty(response)) {
					this.selectedCustomer = this.customerList[0];
				} else {
					this.selectedCustomer = response;
				}

				if (!_.isEqual(this.selectedCustomer, JSON.parse(sessionStorage.getItem('selectedCustomer')))) {
					sessionStorage.setItem('selectedCustomer', JSON.stringify(this.selectedCustomer));
				}

				this.filterService.customerChanged(this.selectedCustomer, true);
			})
		});
	}

	public onCustomerChosen() {
		sessionStorage.setItem('selectedCustomer', JSON.stringify(this.selectedCustomer));
		this.backendService.updateLastCustomer(this.selectedCustomer.id).subscribe();
		this.filterService.customerChanged(this.selectedCustomer);
	}
}
