import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
	BackendService,
	BE_EXCEPTION_SCHEDULE,
	BE_WEEKLY_SCHEDULE,
	GATEWAY_RESPONSE_EXCEPTION_SCHEDULE_TYPE,
	GATEWAY_RESPONSE_WEEKLY_SCHEDULE_TYPE,
	UpdateType
} from 'src/app/services/backend/backend.service';
import {
	DataPoint,
	ExceptionScheduleTemplate,
	ScheduleRequest,
	ScheduleRequestByTemplateId,
	WeeklyScheduleObject
} from 'src/app/models/data-point';
import { Subscription } from 'rxjs';
import { SetpointResult } from '../setpoint/interfaces/setpointResult.interface';
import { OPERATION_TYPE_CREATE, OPERATION_TYPE_UPDATE, UNDERSCORE_SEPARATOR } from '../../config/constants';
import { StoreService } from '../../services/data/store.service';
import { Template } from '../../models/core/template';

const weeklyScheduleTemplateIdKey = 'weeklyScheduleTemplateId';

const exceptionScheduleTemplateIdKey = 'exceptionScheduleTemplateId';
const REPLACE_EVENTS = 'replace';
const APPEND_EVENTS = 'append';

@Component({
	selector: 'cbms-copy-schedule',
	templateUrl: './copy-schedule.component.html',
	styleUrls: ['./copy-schedule.component.scss']
})
export class CopyScheduleComponent implements OnInit {

	public exceptionSchedule = '';
	public weeklyScheduleObject: WeeklyScheduleObject;
	public dataPoints: DataPoint[] = [];
	public copySource: any;
	public secondCopySource: any;
	public gettingSchedule: boolean = true;
	public weeklyScheduleCheckBox: boolean = true;
	public exceptionScheduleCheckBox: boolean = true;
	public writingOptions: string = REPLACE_EVENTS;
	public isLoading: boolean = false;
	public resultMap: Map<string, SetpointResult>;
	public requestDone: boolean = false;
	private dataPointsSubscription: Subscription;
	public operationType: OperationType;
	public readonly COPY_WEEKLY_SCHEDULE_TEMPLATE = OperationType.COPY_WEEKLY_SCHEDULE_TEMPLATE;
	public readonly COPY_EXCEPTION_SCHEDULE_TEMPLATE = OperationType.COPY_EXCEPTION_SCHEDULE_TEMPLATE;
	public readonly COPY_SCHEDULE = OperationType.COPY_SCHEDULE;
	public readonly COPY_FROM_TWO_TEMPLATES= OperationType.COPY_FROM_TWO_TEMPLATES;

	public weeklyscheduletype = GATEWAY_RESPONSE_WEEKLY_SCHEDULE_TYPE;
	public exceptionscheduletype = GATEWAY_RESPONSE_EXCEPTION_SCHEDULE_TYPE;

	public cols: any[] = [
        { field: 'siteName', header: 'Site', sortable: true, width: '' },
        { field: 'deviceName', header: 'Device', sortable: true, width: '' },
		{ field: 'objectName', header: 'Name', sortable: true, width: '' },
        { field: 'description', header: 'Source Description', sortable: true, width: '' },
		{ field: 'customName', header: 'Custom Name', sortable: true, width: '' },
		{ field: 'result', header: 'Result', sortable: true, width: '' }
	];

	constructor(public ref: DynamicDialogRef,
				public config: DynamicDialogConfig,
				private backendService: BackendService,
				private storeService: StoreService) {
	}

	ngOnInit() {
		this.operationType = this.computeOperationType();

		this.isLoading = false;
		const filterParams = {
            dataPointIdFilter: [...this.config.data.dataPoints]
        }
        const pageSize = filterParams.dataPointIdFilter.length;

        this.backendService.getDataPointsDetails(filterParams, 0, pageSize).subscribe(response => {
            this.dataPoints = response.content.map(dataPoint => {
				return Object.assign({}, dataPoint, {siteName: dataPoint.site.name, deviceName: dataPoint.deviceName});
			});
            this.isLoading = false;
        });

		if (this.isCopyFromTwoTemplatesFlow() || this.isCopyFromWeeklyScheduleTemplateFlow()) {
			this.exceptionScheduleCheckBox = this.isCopyFromTwoTemplatesFlow();
			this.setDataFromTemplate(this.storeService.selectedWeeklyScheduleTemplate, BE_WEEKLY_SCHEDULE);

		}
		if (this.isCopyFromTwoTemplatesFlow() || this.isCopyFromExceptionScheduleTemplateFlow()) {
			this.weeklyScheduleCheckBox = this.isCopyFromTwoTemplatesFlow();
			this.setDataFromTemplate(this.storeService.selectedExceptionScheduleTemplate, BE_EXCEPTION_SCHEDULE);
		}
		if (this.isCopyFromScheduleFlow()) {
			this.copySource = this.config.data.copySource;
			this.dataPointsSubscription = this.backendService.getDataPointSchedule(this.config.data.copySource.id).subscribe(response => {
				this.exceptionSchedule = (response.exceptionSchedule);
				this.weeklyScheduleObject = response.weeklyScheduleObject;
				this.gettingSchedule = false;

			});
		}
	}

	private computeOperationType() {
		if (this.storeService.selectedWeeklyScheduleTemplate && this.storeService.selectedExceptionScheduleTemplate) {
			return OperationType.COPY_FROM_TWO_TEMPLATES;
		}
		if (this.storeService.selectedWeeklyScheduleTemplate) {
			return OperationType.COPY_WEEKLY_SCHEDULE_TEMPLATE;
		}
		if (this.storeService.selectedExceptionScheduleTemplate) {
			return OperationType.COPY_EXCEPTION_SCHEDULE_TEMPLATE;
		} else {
			return OperationType.COPY_SCHEDULE;
		}
	}

	private setDataFromTemplate(template: Template, templateType: string) {
		this.backendService.getScheduleTemplateByIdAndType(templateType, template.id).subscribe((response) => {
			if (BE_WEEKLY_SCHEDULE === templateType) {
				this.copySource = {objectName: template.name};
				this.weeklyScheduleObject = response.content[0].weeklyScheduleObject;
			} else {
				this.secondCopySource = {objectName: template.name};
				this.exceptionSchedule = (<ExceptionScheduleTemplate>response.content[0]).exceptionScheduleObject;
			}
		}, () => null, () => {
			//fix calendar rendering issue
			setTimeout(() => this.gettingSchedule = false, 500);
		});
	}

	public close() {
		if (this.dataPointsSubscription) {
			this.dataPointsSubscription.unsubscribe();
		}
		this.ref.close();
	}

	public computeAndSend() {
		this.isLoading = true;

		//copy from Template flow
		if (this.isCopyFromTwoTemplatesFlow()) {

			if (this.weeklyScheduleCheckBox && this.exceptionScheduleCheckBox) {
				this.send(Object.assign(this.getWeeklyScheduleRequestFromTemplateRequest(), this.getExceptionScheduleFromTemplateRequest()));
				return;
			}
			if (this.weeklyScheduleCheckBox) {
				this.send(this.getWeeklyScheduleRequestFromTemplateRequest());
				return;
			}
			if (this.exceptionScheduleCheckBox) {
				this.send(this.getExceptionScheduleFromTemplateRequest());
				return;
			}
		}

		if (this.isCopyFromWeeklyScheduleTemplateFlow()) {
			this.send(this.getWeeklyScheduleRequestFromTemplateRequest());
			return;
		}

		if (this.isCopyFromExceptionScheduleTemplateFlow()) {
			let request = this.getExceptionScheduleFromTemplateRequest();

			this.send(request);
			return;
		}

		//copy from schedule flow
		this.send(this.getRequestBySchedule());
	}

	private getWeeklyScheduleRequestFromTemplateRequest() {
		return Object.assign(this.getBasicRequest(UpdateType.COPY_SCHEDULE_TEMPLATE),
			this.getRequestByTemplateId(weeklyScheduleTemplateIdKey, this.storeService.selectedWeeklyScheduleTemplate.id));
	}

	private getExceptionScheduleFromTemplateRequest() {
		return Object.assign(this.getBasicRequest(UpdateType.COPY_SCHEDULE_TEMPLATE),
			this.getRequestByTemplateId(exceptionScheduleTemplateIdKey, this.storeService.selectedExceptionScheduleTemplate.id),
			this.getOperationTypeObject());
	}

	private getOperationTypeObject() {
		return {operationType: this.writingOptions === APPEND_EVENTS ? OPERATION_TYPE_UPDATE : OPERATION_TYPE_CREATE};
	}

	private getRequestBySchedule(): ScheduleRequest {
		let scheduleRequest: ScheduleRequest;
		scheduleRequest = {
			updateType: UpdateType.COPY_SCHEDULE,
			destinationDataPointIdList: this.dataPoints.map(dataPoint => dataPoint.id),
		};

		if (this.exceptionScheduleCheckBox) {
			scheduleRequest.exceptionSchedule = this.exceptionSchedule;
		}

		if (this.weeklyScheduleCheckBox) {
			scheduleRequest.weeklySchedule = this.weeklyScheduleObject.weeklyScheduleWithInterval;
		}
		return scheduleRequest;
	}

	private getRequestByTemplateId(templateIdKey: string, id: string): any {
		let scheduleRequest = {};
		scheduleRequest[templateIdKey] = id;
		return scheduleRequest;
	}

	private getBasicRequest(updateType: UpdateType) {
		let scheduleRequest: ScheduleRequestByTemplateId = {
			updateType: updateType,
			destinationDataPointIdList: this.dataPoints.map(dataPoint => dataPoint.id)
		};
		return scheduleRequest;
	}

	private send(scheduleRequest: ScheduleRequest | ScheduleRequestByTemplateId) {
		this.backendService.updateDataPointsSchedule(scheduleRequest).subscribe((response) => this.processResponse(response),
			(e) => this.isLoading = false);
	}

	private isCopyFromWeeklyScheduleTemplateFlow() {
		return OperationType.COPY_WEEKLY_SCHEDULE_TEMPLATE === this.operationType;
	}
	private isCopyFromExceptionScheduleTemplateFlow() {
		return OperationType.COPY_EXCEPTION_SCHEDULE_TEMPLATE=== this.operationType;
	}

	private isCopyFromTwoTemplatesFlow(){
		return OperationType.COPY_FROM_TWO_TEMPLATES=== this.operationType;
	}

	private isCopyFromScheduleFlow(){
		return OperationType.COPY_SCHEDULE===this.operationType
	}

	private processResponse(response: SetpointResult[]) {
		this.resultMap = new Map<string, SetpointResult>();
		response.forEach(response => {
			if (response.result.context) {
				this.resultMap.set(this.getComposedKey(response.dataPointId, response.result.context.property), this.getFormatedResponse(response));
			} else {
				this.resultMap.set(response.dataPointId, response);
			}
		});
		this.requestDone = true;
		this.isLoading = false;
	}

	private getFormatedResponse(response: SetpointResult): SetpointResult {
		let formatedResponse: SetpointResult = Object.assign({}, response);
		formatedResponse.result.message = `Failed writing ${this.weeklyscheduletype} property with reason: ${formatedResponse.result.code}`;
		return formatedResponse;
	}

	public getComposedKey(id: string, type: string) {
		return id + UNDERSCORE_SEPARATOR + type;
	}

	getResultByIdAndType(id: string, type: string): SetpointResult {
		return this.resultMap.get(this.getComposedKey(id, type)) || this.resultMap.get(id) ||  this.detDefaultResponse(id);
	}

	private detDefaultResponse(id: string) {
		return {
			dataPointId: id,
			success: false,
			result: {code: 'RESPONSE_NOT_FOUND', message: 'Response not found, please press Send again'}
		};
	}


}

enum OperationType {
	COPY_SCHEDULE = 'COPY_SCHEDULE',
	COPY_WEEKLY_SCHEDULE_TEMPLATE = 'COPY_WEEKLY_SCHEDULE_TEMPLATE',
	COPY_EXCEPTION_SCHEDULE_TEMPLATE = 'COPY_EXCEPTION_SCHEDULE_TEMPLATE',
	COPY_FROM_TWO_TEMPLATES="COPY_FROM_TWO_TEMPLATES",
	NONE = 'NONE'
}
