<div class="content">
    <mat-progress-bar class="cbms-progressbar" mode="indeterminate"
        [ngClass]="{ 'cbms-progressbar-visible': gettingPresentValue }">
    </mat-progress-bar>

    <div class="cbms-spinner-wrapper" *ngIf="isLoadingData">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <p-table class="cbms-generic-table-style" [value]="dataPoints" [columns]="cols" [resizableColumns]="true" [scrollable]="true"
        [scrollHeight]="'500px'" columnResizeMode="expand" stateStorage="session" stateKey="{{tableName}}-session" *ngIf="!isLoadingData">
        <ng-template pTemplate="caption">
            <div class="watch-caption-wrapper">
                <span class="caption-label auto-margin">Automatic updates</span>
                <p-dropdown class="cbms-small-margin-left" [options]="pollingTimerOptions" [(ngModel)]="pollingTimer"
                    (onChange)="changePollingTimer($event)" [disabled]="gettingPresentValue">
                </p-dropdown>
                <button *ngIf="shouldShowUpdateBtn()" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
                    [disabled]="gettingPresentValue"
                    (click)="updatePresentValue()">Update value</button>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="cbms-text-align-left watch" *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable"
                    pResizableColumn>
                    {{col.header}}
                    <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataPoint>
            <tr>
                <td class="ui-resizable-column">{{dataPoint.siteName}}</td>
                <td class="ui-resizable-column">{{dataPoint.deviceName}} ({{dataPoint.deviceId}})</td>
                <td class="ui-resizable-column">{{dataPoint.name}}</td>
                <td class="ui-resizable-column">{{dataPoint.description}}</td>
                <td class="ui-resizable-column">{{dataPoint.units}}</td>
                <td class="ui-resizable-column">{{dataPoint.value}}</td>
                <td class="ui-resizable-column" [ngClass]="{'blink': !gettingPresentValue && dataPoint.value !== null, 'highlight': dataPoint.highlight}">
                    <span>{{dataPoint.updatedValue}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>