import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { BackendService } from '../../../services/backend/backend.service';
import { PAGE_SIZE_1000, SUCCESS } from '../../../config/constants';
import { Site } from '../../../models/site';
import { EventNotificationService, EventType, PayloadEvent } from '../../../services/notification/event-notification.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { Subscription } from 'rxjs';
import { FilterService } from '../../filter/filter.service';
import { saveAs } from 'file-saver';
import { ROLE_PERMISSIONS } from '../../../models/user';

@Component({
    selector: 'cbms-sites-table',
    templateUrl: './sites-table.component.html',
    styleUrls: ['./sites-table.component.scss'],
})


export class SitesTableComponent implements OnInit, OnDestroy, OnChanges {
    public pageSize: number = PAGE_SIZE_1000;
    @Input()
    public siteList: Site[] = [];
    @Input()
    public totalRecords: any;
    @Output()
    public getSiteEventEmitter = new EventEmitter();
    @Output()
    public selectedSitesEmitter = new EventEmitter<Site[]>();
    public cols: any[];
    public allSitesSelected: boolean = false;
    public importInProgress: boolean = false;
    private subscription: Subscription;
    private customerSubscription: Subscription;
    public userRolePermissionList: string;
    protected readonly ROLE_PERMISSIONS = ROLE_PERMISSIONS;

    constructor(public backendService: BackendService,
        public eventNotificationService: EventNotificationService,
        private notificationService: NotificationService,
        private filterService: FilterService) {
        this.cols = [
            { field: 'customerName', header: 'Customer Name', uiField: 'customerName' },
            { field: 'name', header: 'Site', uiField: 'name' },
            { field: 'dashboardEnabled', header: 'Dashboard Enabled', uiField: 'dashboardEnabled' },
            { field: 'readOnly', header: 'Read-only', uiField: 'readOnly' },
            { field: 'timezone', header: 'Time Zone', uiField: 'timezone' },
            { field: 'tags.name', header: 'Tags', uiField: 'tags' }
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.totalRecords) {
            this.totalRecords = changes.totalRecords.currentValue;
        }
        if (changes.siteList) {
            this.siteList = changes.siteList.currentValue;
        }
        this.allSitesSelected = false;
    }

    ngOnInit(): void {
        this.subscription = this.eventNotificationService.getSiteSaved$().subscribe(_ => this.getSiteEventEmitter.emit(null));
        this.customerSubscription = this.filterService.customerFilterChanges$.subscribe(() => this.getSiteEventEmitter.emit(null));
        this.userRolePermissionList = sessionStorage.getItem(
            'userRolePermissionList'
        );
    }

    hasPermission(permission: string) {
        return this.userRolePermissionList.includes(permission)
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }
    }

    public loadSitesLazy(event: LazyLoadEvent) {
        this.getSiteEventEmitter.emit(event);
    }

    onEditSite(siteData: Site) {
        // send selected data as JSON string payload and bring the sidebar on
        let editSiteEvent: PayloadEvent = {
            type: EventType.SITE_EDITED,
            payload: {
                id: siteData.id,
                jsonData: JSON.stringify(siteData)
            }
        };
        this.eventNotificationService.emittEvent(editSiteEvent);
    }

    public deleteSite(siteId: string) {
        this.backendService.deleteSiteById(siteId).subscribe(() => {
            this.notificationService.addSuccessMessage('Delete Site', 'Site deleted successfully');
            this.getSiteEventEmitter.emit(null);
        },
            (err) => {
                this.notificationService.addErrorMessage('Delete Site', err);
            });
    }

    public toggleSelectAllSites($event: MouseEvent) {
        $event.stopPropagation();
        this.siteList = this.siteList.map(site => {
            site.selected = this.allSitesSelected;
            return site;
        });
        this.updateSelectedSiteList();
    }

    public updateSelectedSite() {
        this.allSitesSelected = false;
        this.updateSelectedSiteList();
    }

    public updateSelectedSiteList() {
        this.selectedSitesEmitter.emit(this.siteList.filter(value => value.selected));
    }

    public uploadDataPointsCSV(event: any, datapointsFileUpload) {
        this.importInProgress = true;
        this.backendService.uploadDatapoints(event.files[0]).subscribe((response) => {
            datapointsFileUpload.clear();
            this.importInProgress = false;
            this.notificationService.addSuccessMessage(SUCCESS, `File uploaded. For file processing status, please see the Action Log tab.`, true);
        });
    }

    public exportSiteDatapoints(site: Site) {
        let date = new Date();
        let time = `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
        this.backendService.exportSiteDatapoints(site.id).subscribe(response => {
            saveAs(response, `OptimumCC_${site.customerName}_${site.name}_Model_${time}.xlsx`);
        }, (err) => { console.log(err) });
    }

}
