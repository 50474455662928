<div class="filter-item">
    <mat-icon svgIcon="gateways"></mat-icon>
    <p-multiSelect
        data-cy="gateway-filter"
        #gatewayFilter
        class="filter-item min-width500"
        [options]="gatewayList"
        [(ngModel)]="selectedGatewayList"
        [filter]="true"
        filterBy="name,hardwareId"
        filterPlaceHolder="Gateways"
        selectedItemsLabel="{0} Gateways selected"
        maxSelectedLabels="1"
        placeholder="Gateways"
        optionLabel="nameAndGwHardwareId"
        dataKey="id"
        (onChange)="onGatewaySelected()"
        [showToggleAll]="false"
        [virtualScroll]="true"
        virtualScrollItemSize="30"
        dataKey="id"
    >
        <p-footer>
            <hr />
            <div
                class="filter-footer"
                *ngIf="gatewayList && gatewayList.length"
            >
                <span *ngIf="gatewayFilter && !gatewayFilter._filteredOptions"
                    >showing {{ gatewayList.length }} of
                    {{ gatewayList.length }} Gateways</span
                >
                <span *ngIf="gatewayFilter && gatewayFilter._filteredOptions"
                    >showing {{ gatewayFilter._filteredOptions.length }} of
                    {{ gatewayList.length }} Gateways</span
                >
            </div>
        </p-footer>
    </p-multiSelect>
</div>
