import { Injectable } from '@angular/core';
import { Template } from '../../models/core/template';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    private _selectedWeeklyScheduleTemplate: Template;
    private _selectedExceptionScheduleTemplate: Template;

    constructor() {
    }

    get selectedExceptionScheduleTemplate(): Template {
        return this._selectedExceptionScheduleTemplate;
    }

    set selectedExceptionScheduleTemplate(value: Template) {
        this._selectedExceptionScheduleTemplate = value;
    }

    get selectedWeeklyScheduleTemplate(): Template {
        return this._selectedWeeklyScheduleTemplate;
    }

    set selectedWeeklyScheduleTemplate(value: Template) {
        this._selectedWeeklyScheduleTemplate = value;
    }
}
