import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Device } from 'src/app/models/device';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/components/filter/filter.service';
import { BackendService } from '../../../services/backend/backend.service';

@Component({
    selector: 'cbms-device-card',
    templateUrl: './device-card.component.html',
    styleUrls: ['./device-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceCardComponent implements OnInit {
    @Input()
    public device: Device;
    public visible: boolean;

    constructor(private router: Router, private filterService: FilterService, private backendService: BackendService) {

    }

    public onDeviceClick() {
        if (!this.visible) {
            return;
        }
        
        this.filterService.clearFilter('devices', 'siteFilter');
        sessionStorage.setItem('devices-deviceFilter', JSON.stringify([this.device]));

        this.router.navigate(['devices'],
            {
                queryParams: {
                    selected: this.device.id,
                    gatewayIds: this.device.gatewayId
                }
            });
    }

    toggleVisibility() {
        this.visible = !this.visible;
        this.backendService.setDeviceHiddenFlag(this.device.id, !this.visible).subscribe(() => null);
    }

    ngOnInit(): void {
        this.visible = !this.device.hidden;
    }
}
