import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { Observable } from 'rxjs';
import { AlertData, SmartAlert } from 'src/app/models/alert-rule.model';
import { DataService } from 'src/app/services/data/data.service';
import { Table } from 'primeng/table';
import { FilterService } from 'src/app/components/filter/filter.service';
import { map } from 'rxjs/operators';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import moment from 'moment-timezone';
import { SortEvent } from 'primeng/api';

@Component({
    selector: 'cbms-smart-alert-console',
    templateUrl: './smart-alert-console.component.html',
    styleUrls: ['./smart-alert-console.component.scss'],
})
export class SmartAlertConsoleComponent implements OnInit {
    @ViewChild('dt') table: Table;

    public cols: any[] = [
        { field: 'status.value', header: 'Status', width: '100px' },
        { field: 'startDate', header: 'Start', width: '100px' },
        { field: 'endDate', header: 'End', width: '100px' },
        { field: 'durationInHours', header: 'Duration[Hours]', width: '100px' },
        { field: 'alarmConfig.name', header: 'Alarm Name', width: '100px' },
        { field: 'alarmConfig.type', header: 'Alarm Type', width: '100px' },
        { field: 'priority.value', header: 'Alarm Priority', width: '100px' },
        { field: 'alarmConfig.site.name', header: 'Site', width: '150px' },
        { field: 'comment', header: 'Comment', width: '150px' },
    ];

    public customerId: string;
    public triggeredAlerts$: Observable<SmartAlert[]>;
    public statusOptions = [
        { value: 'OPEN' },
        { value: 'CLOSED' },
        { value: 'IN_PROGRESS' },
        { value: 'EXPIRED' },
    ];

    public changeStatusOptionsList = [
        { label: 'OPEN', value: 'OPEN' },
        { label: 'CLOSED', value: 'CLOSED' },
        { label: 'IN PROGRESS', value: 'IN_PROGRESS' },
    ];

    public priorityOptions = [
        { value: 'High' },
        { value: 'Medium' },
        { value: 'Low' },
    ];

    public isLoading = true;

    public backupData: { [s: string]: string } = {};
    public editing: { [key: string]: boolean } = {};

    constructor(
        private backendService: BackendService,
        private filterService: FilterService,
        private dataService: DataService,
        private dialogService: DialogConfigService,
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('alertConsole');
        this.getTriggeredAlerts();

        this.filterService.customerFilterChanges$.subscribe((data) => {
            this.customerId = data.selectedCustomer.id;
            this.getTriggeredAlerts();
        });
    }

    getTriggeredAlerts() {
        this.triggeredAlerts$ = this.backendService
            .getTriggeredAlerts(this.customerId)
            .pipe(
                map((res) => {
                    res.forEach((alert) => {
                        const timezone =
                            alert.alarmConfig.site?.timezone ||
                            moment.tz.guess();
                        alert.durationInHours = this.toHoursAndMinutes(
                            alert.durationInMinutes
                        );
                        alert.durationInMinutes / 60;
                        alert.startDate = moment
                            .tz(alert.startDate, timezone)
                            .format('DD.MM.YYYY, HH:mm:ss');
                        alert.endDate = alert.endDate
                            ? moment
                                  .tz(alert.endDate, timezone)
                                  .format('DD.MM.YYYY, HH:mm:ss')
                            : null;
                        alert.status = { value: alert.status };
                        alert.priority = { value: alert.alarmConfig.priority };
                    });
                    return res;
                })
            );
    }

    clear(table: Table) {
        table.clear();
        sessionStorage.removeItem('alert-console-session');
    }

    viewAlertDetails(alert: AlertData) {
        this.dialogService.showSmartAlertDetailsDialog(alert);
    }

    onEditInit(rule: any) {
        this.editing[rule.id] = true;
        this.backupData[rule.id] = rule.comment;
    }

    saveCommentsChanges(rule: any) {
        this.editing[rule.id] = false;
        if (rule.comment === this.backupData[rule.id]) {
            return;
        }

        this.backendService
            .updateSmartAlertComment(rule.id, rule.comment)
            .subscribe((res) => {
                this.notificationService.addSuccessMessage(
                    'Edit',
                    'Comment successfully updated.',
                    false
                );
            });
    }

    onEditCancel(rule: any) {
        this.editing[rule.id] = false;
        rule.comment = this.backupData[rule.id];
        delete this.backupData[rule.id];
    }

    onStatusChange(rule: any) {
        this.backendService
            .changeAlertConsoleStatus(rule.id, rule.status.value)
            .subscribe((res) => {
                this.notificationService.addSuccessMessage(
                    'Edit',
                    'Alert status updated.',
                    false
                );
            });
    }

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) result = -1;
            else if (value1 != null && value2 == null) result = 1;
            else if (value1 == null && value2 == null) result = 0;
            else if (
                typeof value1 === 'string' &&
                typeof value2 === 'string' &&
                moment(value1, 'DD/MM/YYYY').isValid() &&
                moment(value2, 'DD/MM/YYYY').isValid()
            ) {
                result =
                    moment(value1, 'DD/MM/YYYY HH:mm:ss') <
                    moment(value2, 'DD/MM/YYYY HH:mm:ss')
                        ? -1
                        : moment(value1, 'DD/MM/YYYY HH:mm:ss') >
                            moment(value2, 'DD/MM/YYYY HH:mm:ss')
                          ? 1
                          : 0;
            }
            else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
            return event.order * result;
        });
    }

    private toHoursAndMinutes(totalMinutes: number) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return `${hours}:${minutes}`;
    }
}
