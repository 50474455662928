<div class="content">
    <div class="content-body">
        <div class="grid cbms-align-center">
            <div class="col-4">
                <span>Customer</span>
            </div>
            <div class="col-6">
                <p-dropdown class="min-width250" name="selectedCustomer" [required]="true" [options]="customerList"
                    [(ngModel)]="selectedCustomer" placeholder="Select Customer" optionLabel="name"
                    (onChange)="onCustomerChosen()">
                </p-dropdown>
            </div>
        </div>

        <div class="grid cbms-align-center">
            <div class="col-4">
                <span>Site</span>
            </div>
            <div class="col-6">
                <p-dropdown class="min-width250" name="selectedCustomer" [required]="true" [options]="siteList"
                    [(ngModel)]="selectedSite" placeholder="Select Site" optionLabel="name">
                </p-dropdown>
            </div>
        </div>
    </div>
</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" [disabled]="" (click)="send()">Send</button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>