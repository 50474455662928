import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService, BE_EXCEPTION_SCHEDULE } from 'src/app/services/backend/backend.service';
import { PAGINATION, SORT_ORDER_DESC } from 'src/app/config/constants';
import { LazyLoadEvent } from 'primeng/api';
import { ExceptionScheduleTemplate } from 'src/app/models/data-point';
import { Subscription } from 'rxjs';
import { TemplateType } from '../../models/core/template';

@Component({
    selector: 'cbms-exception-schedule-templates',
    templateUrl: './exception-schedule-templates.component.html',
    styleUrls: ['./exception-schedule-templates.component.scss']
})
export class ExceptionScheduleTemplatesComponent implements OnInit {

    public pageSize = PAGINATION.SIZE;
    public totalRecords: number = null;
    public sortField: string = 'modifiedDate';
    public firstPageIndex: number = 0;
    public sortOrder: number = SORT_ORDER_DESC;
    public isLoading: boolean = false;
    public updatedTemplateId: string = '';
    public tableName = 'exceptionScheduleTemplates';

    public templateCols: any[] = [
        { field: 'customerName', header: 'Customer Name', sortable: true, position: 2, width: '170px' },
        { field: 'name', header: 'Name', sortable: true, position: 3, width: '' },
        { field: 'createdBy', header: 'Created By', sortable: true, position: 4, width: '' },
        { field: 'createdDate', header: 'Created Date', sortable: true, position: 5, width: '' },
        { field: 'modifiedBy', header: 'Modified By', sortable: true, position: 6, width: '' },
        { field: 'modifiedDate', header: 'Modified Date', sortable: true, position: 7, width: '' }
    ];

    public templates: ExceptionScheduleTemplate[];
    public getTemplatesSubscription: Subscription;
    public EXCEPTION_SCHEDULE=TemplateType.EXCEPTION_SCHEDULE;

    constructor(private backendService: BackendService) {
    }

    ngOnInit() {
        this.initData();
    }

    ngOnDestroy(): void {
        this.getTemplatesSubscription.unsubscribe();
    }

    public loadTemplatesLazy(event: LazyLoadEvent) {
        this.isLoading = true;
        const pageNumber = event.first !== 0 ? (event.first / this.pageSize) : 0;
        const sortOptions = {
            sortField: event.sortField,
            sortOrder: event.sortOrder === 1 ? 'asc' : 'desc'
        };

        this.backendService.getScheduleTemplatesByType(BE_EXCEPTION_SCHEDULE, pageNumber, this.pageSize, sortOptions).subscribe((response) => {
            this.templates = response.content;
            this.isLoading = false;
        });
    }

    public  handleRefreshTemplateList(updatedTemplateId: string) {
        this.updatedTemplateId = updatedTemplateId;
        this.initData();
    }

    public handleOnDelete() {
        this.initData();
    }

    private initData() {
        this.isLoading = true;
        const sortOptions = {
            sortField: this.sortField,
            sortOrder: this.sortOrder === 1 ? 'asc' : 'desc'
        };
        this.getTemplatesSubscription = this.backendService.getScheduleTemplatesByType(BE_EXCEPTION_SCHEDULE, 0, this.pageSize, sortOptions).subscribe((response: any) => {
            this.templates = response.content;
            this.totalRecords = response.totalElements;
            this.isLoading = false;
            this.firstPageIndex = 0;
        });
    }

}
