import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { DataPoint, MappedDataPoint } from '../../../models/data-point';
import { NotificationService } from '../../../services/notification/notification.service';


@Component({
	selector: 'cbms-delete-datapoints-action',
	templateUrl: './delete-datapoints-action.component.html',
	styleUrls: ['./delete-datapoints-action.component.scss']
})
export class DeleteDatapointsActionComponent implements OnInit {
	@Input()
	public selectedDataPoints: MappedDataPoint[];

	@Output()
	public updatedDataPointsEvent = new EventEmitter<string[]>();

	public inProgress: boolean = false;


	constructor(private backendService: BackendService, private notificationService: NotificationService) { }

	ngOnInit(): void {
	}

	public deleteSelectedDatapoints() {
		this.inProgress = true;
		this.backendService.deleteDataPoints(this.selectedDataPoints.map(dataPoint => dataPoint.id)).subscribe( (response: any) => {
			this.notificationService.addSuccessMessage('Delete Data Points', 'Data Points deleted successfully');
			this.updatedDataPointsEvent.emit(response);
			this.inProgress = false;
		});
	}

}
