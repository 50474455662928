import { Component, Input, OnInit } from '@angular/core';
import { BackendService } from '../../../services/backend/backend.service';
import { EventNotificationService, EventType } from '../../../services/notification/event-notification.service';
import { StoreService } from '../../../services/data/store.service';
import { TemplateType } from '../../../models/core/template';


@Component({
    selector: 'cbms-schedule-template-select-action',
    templateUrl: './schedule-template-select-action.component.html',
    styleUrls: ['./schedule-template-select-action.component.scss']
})
export class ScheduleTemplateSelectActionComponent implements OnInit {

    @Input() public templateId: string;
    @Input() public templateName: any;
    @Input() public templateType: TemplateType = TemplateType.WEEKLY_SCHEDULE;

    constructor(public backendService: BackendService,
                public eventNotificationService: EventNotificationService, private storeService: StoreService) {
    }

    ngOnInit() {
    }

    toggleSelectRow() {
        //update Store
        let template = {id: this.templateId, type: this.templateType, name: this.templateName};
        if (this.isWeeklyScheduleTemplate()) {
            this.storeService.selectedWeeklyScheduleTemplate = template;
        } else {
            this.storeService.selectedExceptionScheduleTemplate = template;
        }

        //publish Event
        this.eventNotificationService.emittEvent(this.getEvent());
    }

    private getEvent() {
        return {
            type: this.getPayloadTypeByTemplateType(),
            payload: {
                id: this.templateId,
                name: this.templateName
            }
        };
    }

    private getPayloadTypeByTemplateType() {
        if (this.isWeeklyScheduleTemplate()) {
            return this.isSelectFlow() ? EventType.WEEKLY_SCHEDULE_TEMPLATE_SELECTED : EventType.WEEKLY_SCHEDULE_TEMPLATE_UNSELECTED;
        }
        return this.isSelectFlow() ? EventType.EXCEPTION_SCHEDULE_TEMPLATE_SELECTED : EventType.EXCEPTION_SCHEDULE_TEMPLATE_UNSELECTED;
    }

    private isWeeklyScheduleTemplate() {
        return TemplateType.WEEKLY_SCHEDULE === this.templateType;
    }

    private isSelectFlow() {
        return Boolean(this.templateId);
    }
}
