<div class="content" *ngIf="!isLoading">
    <div class="grid">
        <div class="col-2">Customer</div>
        <div class="col-10">{{ alertData.alarmConfig.site.customer.name }}</div>
    </div>

    <div class="grid">
        <div class="col-2">Site</div>
        <div class="col-10">{{ alertData.alarmConfig.site.name }}</div>
    </div>

    <div class="grid">
        <div class="col-2">Alert Name</div>
        <div class="col-10">{{ alertData.alarmConfig.name }}</div>
    </div>

    <div class="grid">
        <div class="col-2">Alert Type</div>
        <div class="col-10">{{ alertData.alarmConfig.type }}</div>
    </div>

    <div class="grid">
        <div class="col-2">Alert Lifecycle</div>
        <div class="col-10">{{ alertData.alarmBackendStatus }}</div>
    </div>

    <div class="grid">
        <div class="col-2">Alert Description</div>
        <div class="col-10">
            <textarea
                style="width: 100%;"
                readonly
                rows="5"
                cols="60"
                pInputTextarea
                [(ngModel)]="alertData.alarmConfig.description"
            ></textarea>
        </div>
    </div>

    <cbms-query-builder
        [isReadOnly]="true"
        [query]="rules"
    ></cbms-query-builder>

    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-bottom20" (click)="showTelemetryDialog()"
    >
        View Analysis
    </button>

    <div class="grid">
        <div class="col-2">Time Alert Started</div>
        <div class="col-8">
            {{ alertData.startDate | date : 'dd/MM/yyy, HH:mm:ss' }}
        </div>
        <div class="col-2">
            <div class="cbms-redBg">Active Rule or Condition</div>
        </div>
    </div>

    <div class="grid">
        <div class="col-2">Time Alert Ended</div>
        <div class="col-8">
            {{ alertData.endDate === null ? 'N/A' : (alertData.endDate | date : 'dd.MM.yyy, HH:mm:ss') }}
        </div>
        <div class="col-2">
            <div class="cbms-yellowBg">Missing Historical Data</div>
        </div>
    </div>
</div>

<div class="footer">
    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
        (click)="close()"
    >
        Close
    </button>
</div>
