<ng-container *ngIf="isGlobalAction">
    <div [pTooltip]="'No data points or more than '+totalNrOfDPToAnalyze+' selected.'" showDelay="1000" hideDelay="500"
        [tooltipDisabled]="selectedDataPoints.length && selectedDataPoints.length < totalNrOfDPToAnalyze">
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="showTelemetryDialog(selectedDataPoints)"
            [disabled]="!selectedDataPoints.length || selectedDataPoints.length > totalNrOfDPToAnalyze"
            pTooltip="Visualize historical values of the selected datapoints" showDelay="1000" hideDelay="500">
            <mat-icon svgIcon="timeline"></mat-icon>
            Analysis
        </button>
    </div>
</ng-container>


<ng-container *ngIf="!isGlobalAction">
    <button pButton class="p-button-raised p-button-text cbms-row-button" (click)="showTelemetryDialog(selectedDataPoints)"
        pTooltip="Show graph" showDelay="1000" hideDelay="500">
        <mat-icon svgIcon="timeline"></mat-icon>
    </button>
</ng-container>