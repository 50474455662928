<p-table data-cy="weekly-schedules-table" class="cbms-generic-table-style" [columns]="templateCols" [value]="templates" [sortField]="sortField" [sortOrder]="sortOrder"
    [resizableColumns]="true" [scrollable]="true" [scrollHeight]="'600px'" [lazy]="true" [loading]="isLoading"
    [lazyLoadOnInit]="false" (onLazyLoad)="loadTemplatesLazy($event)" [paginator]="true" [totalRecords]="totalRecords"
    [rows]="pageSize" [(first)]="firstPageIndex" columnResizeMode="expand" stateStorage="session" stateKey="{{tableName}}-session">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th>Actions</th>
            <th class="cbms-text-align-left" *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable"
                pResizableColumn>
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order">
                </p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-template>
        <tr [ngClass]="{ 'p-highlight': template.id == updatedTemplateId }">
            <td class="ui-resizable-column cbms-button-wrapper">
                <cbms-show-template-action [templateName]="template.name" [templateId]="template.id"
                                           (onRefreshTemplateList)="handleRefreshTemplateList($event)"></cbms-show-template-action>
                <cbms-schedule-template-delete-action [selectedId]="template.id" [selectedName]="template.name"
                    (onDelete)="handleOnDelete()"></cbms-schedule-template-delete-action>
                <cbms-schedule-template-select-action [templateId]="template.id"
                                                      [templateName]="template.name"></cbms-schedule-template-select-action>
            </td>
            <td class="ui-resizable-column">{{template.customerName}}</td>
            <td class="ui-resizable-column">{{template.name}}</td>
            <td class="ui-resizable-column">{{template.createdBy}}</td>
            <td class="ui-resizable-column">{{template.createdDate | date:'dd.MM.yyy, HH:mm:ss'}}</td>
            <td class="ui-resizable-column">{{template.modifiedBy}}</td>
            <td class="ui-resizable-column">{{template.modifiedDate | date:'dd.MM.yyy, HH:mm:ss'}}</td>

        </tr>
    </ng-template>
</p-table>

<div class="cbms-spinner-wrapper" *ngIf="templates && !templates.length">
    <span class="no-datapoints-text">No templates found</span>
</div>