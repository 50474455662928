import { Component, OnInit, Input } from '@angular/core';
import { Equipment } from 'src/app/models/dashboard';

@Component({
	selector: 'cbms-equipment-accordion',
	templateUrl: './equipment-accordion.component.html',
	styleUrls: ['../equipment-widget/equipment-widget.component.scss']
})
export class EquipmentAccordionComponent implements OnInit {

	@Input()
	equipments: Equipment[];

	constructor() {

	}

	ngOnInit(): void {
		console.log('equipments: ', this.equipments);
	}

}
