<div class="cbms-title-wrapper-baseline">
    <mat-icon class="cbms-title-icon" svgIcon="sites"></mat-icon>
    <span class="cbms-title-text">Sites</span>
    <span
        class="cbms-margin-left-auto"
        *ngIf="siteList !== null && !selectedSites.length"
        >{{ totalRecords }} Sites</span
    >
    <span
        class="cbms-margin-left-auto"
        *ngIf="siteList !== null && selectedSites.length"
        >{{ selectedSites.length }} of {{ siteList.length }} Sites
        Selected</span
    >
</div>
<div
    class="cbms-title-wrapper cbms-align-center cbms-justify-content-space-between"
>
    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-margin-left-auto"
        (click)="showDashboardsManagementDialog(selectedSites)"
        [disabled]="!selectedSites.length || !hasPermission(ROLE_PERMISSIONS.EDIT_SITE_DASHBOARD_FLAG_BUTTON_SITE_TAB_CORE_APP)"
        pTooltip="Enable / Disable site dashboards"
        showDelay="1000"
        hideDelay="500"
    >
        <span class="table-caption-text">Dashboards</span>
    </button>

    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-margin-left"
        (click)="showTagManagementDialog(selectedSites)"
        [disabled]="!selectedSites.length || !hasPermission(ROLE_PERMISSIONS.EDIT_SITE_TAGS_FLAG_BUTTON_SITE_TAB_CORE_APP)"
        pTooltip="Add / Delete tags to selected sites"
        showDelay="1000"
        hideDelay="500"
    >
        <mat-icon svgIcon="tags"></mat-icon>
        <span class="table-caption-text">Edit Tags</span>
    </button>

    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-margin-left"
        [disabled]="!selectedSites.length || !hasPermission(ROLE_PERMISSIONS.DELETE_ALL_SITES_BUTTON_SITE_TAB_CORE_APP)"
        (click)="deleteSelectedItemsMenu.show($event)"
        data-cy="delete-all"
    >
        <mat-icon svgIcon="delete"></mat-icon>
        Delete All
    </button>
    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left"
        (click)="exportSiteExcel()"
        [disabled]="!hasPermission(ROLE_PERMISSIONS.EXPORT_SITE_EXCEL_BUTTON_SITE_TAB_CORE_APP)"
        data-cy="delete-all"
    >
        <i class="pi pi-download cbms-margin-right-05-rem"></i>
        Export Site Excel
    </button>
    <p-fileUpload
        #sitesFileUpload
        class="upload-button cbms-margin-left"
        mode="basic"
        name="myCSVfile"
        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        chooseIcon="pi pi-upload"
        chooseLabel="Import Site Excel"
        maxFileSize="1000000"
        customUpload="true"
        [auto]="true"
        [disabled]="requestInProgress  || !hasPermission(ROLE_PERMISSIONS.IMPORT_SITE_EXCEL_BUTTON_SITE_TAB_CORE_APP)"
        (uploadHandler)="uploadSiteExcel($event, sitesFileUpload)"
    ></p-fileUpload>

    <button
        pButton
        class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left"
        (click)="showAddNewSiteSidebar()"
        [disabled]="!hasPermission(ROLE_PERMISSIONS.CREATE_SITE_BUTTON_SITE_TAB_CORE_APP)"
    >
        New Site
    </button>
</div>

<div class="cbms-spinner-wrapper" *ngIf="isLoading || requestInProgress">
    <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
</div>

<div class="sites-wrapper">
    <cbms-sites-table
        [siteList]="siteList"
        [totalRecords]="totalRecords"
        (getSiteEventEmitter)="loadSitesLazy($event)"
        (selectedSitesEmitter)="selectedSitesEmitterHandler($event)"
    ></cbms-sites-table>
</div>

<p-sidebar
    [(visible)]="showSidebar"
    position="right"
    styleClass="p-sidebar-md"
    (onHide)="resetCloseForm()"
>
    <div class="cbms-sidebar-header">
        <h2 *ngIf="EDIT_MODE == viewMode && selectedSite; else createSite">
            Edit Site: {{ selectedSite.name }}
        </h2>
        <ng-template #createSite>
            <h2>Add Site</h2>
        </ng-template>
    </div>
    <div class="cbms-spinner-wrapper" *ngIf="isLoading">
        <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
    </div>
    <div class="sidebar-body">
        <form
            *ngIf="!isLoading"
            (ngSubmit)="saveSite()"
            [formGroup]="formGroup"
        >
            <div class="p-inputgroup">
                <p-dropdown
                    [options]="customerList"
                    formControlName="customer"
                    [disabled]="true"
                    [styleClass]="'cbms-dropdown__mid-size'"
                    placeholder="Select Customer"
                >
                </p-dropdown>
                *
            </div>
            <div class="p-inputgroup">
                <input
                    pInputText
                    class="cbms-input__mid-size"
                    formControlName="siteName"
                    placeholder="Site Name"
                />*
            </div>

            <div class="p-inputgroup">
                <p-dropdown
                    [options]="timezoneList"
                    name="timezone"
                    formControlName="timezone"
                    [required]="true"
                    optionLabel="label"
                    optionValue="value"
                    [styleClass]="'cbms-dropdown__mid-size'"
                    placeholder="Select Time Zone"
                ></p-dropdown
                >*
            </div>

            <div class="p-inputgroup">
                <p-checkbox
                    formControlName="readonlySite"
                    label="Read-only Site"
                    [binary]="true"
                ></p-checkbox>
            </div>
            <div class="p-inputgroup">
                <p-checkbox
                    formControlName="dashboardEnabled"
                    label="Dashboard Enabled"
                    [binary]="true"
                ></p-checkbox>
            </div>

            <button
                type="submit"
                pButton
                class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                [disabled]="!formGroup.valid || isLoading"
            >
                Save
            </button>
            <button
                type="button"
                pButton
                class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left"
                (click)="resetCloseForm()"
            >
                Close
            </button>
        </form>
    </div>
</p-sidebar>

<p-overlayPanel #deleteSelectedItemsMenu appendTo="body">
    <p>
        Please confirm you want to continue with the removal<br />of
        <strong>{{ selectedSites.length }}</strong> sites.
    </p>
    <hr />
    <div class="delete-action-buttons">
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            label="Confirm"
            (click)="deleteSelectedItems(); deleteSelectedItemsMenu.hide()"
        ></button>
        <button
            pButton
            class="p-button-raised p-button-text cbms-button cbms-margin-left"
            (click)="deleteSelectedItemsMenu.hide()"
            label="Cancel"
        ></button>
    </div>
</p-overlayPanel>
