<p-accordion>
    <p-accordionTab *ngFor="let equipment of equipments"
        header="{{equipment.label}}">
        <div class="grid content-row"
            *ngFor="let point of equipment.points;">
            {{point.label}}
            <!-- <ng-container [ngSwitch]="kpiPoint.signalType">
                <ng-container *ngSwitchCase="'Binary'">
                    <div class="col-10">
                        <p class="font_size20">{{kpiPoint.label}}</p>
                    </div>
                    <div class="col-2">
                        <p class="font_size20">
                            <p-inputSwitch [disabled]="kpiPoint.type !== 'Output'"
                                [(ngModel)]="kpiPoint.presentValue"
                                (onChange)="handleChange($event, kpiPoint)">
                            </p-inputSwitch>
                        </p>
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <div class="col-10">
                        <p class="font_size20">{{kpiPoint.label}}</p>
                    </div>
                    <div class="col-2">
                        <p class="font_size20">{{kpiPoint.presentValue}}</p>
                    </div>
                </ng-container>
            </ng-container> -->
        </div>
        <div *ngIf="equipment.children.length">
            <p-accordion>
                <p-accordionTab *ngFor="let subEquipment of equipment.children;" header="{{subEquipment.label}}">
                    <div class="grid content-row" *ngFor="let point of subEquipment.points;">
                        {{point.label}}
                    </div>
                    <div class="grid content-row">
                        <div class="col-12">
                            {{subEquipment.label}}
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </p-accordionTab>
</p-accordion>