<mat-card class="card">
    <mat-card-header>
        <button mat-mini-fab (click)="scan()" [disabled]="isLoading">
            <mat-icon class="icon-button" svgIcon="scan"></mat-icon>
        </button>
        <mat-card-title>Scan for Devices</mat-card-title>
        <mat-card-subtitle><strong>Warning!</strong> Scan may take some time and generate extensive network traffic.</mat-card-subtitle>
    </mat-card-header>
    <ng-container *ngIf="BACKNET === this.gateway.source">
        <mat-card-header>
            <button mat-mini-fab (click)="add()" *ngIf="!isLoading">
                <mat-icon class="icon-button" svgIcon="add"></mat-icon>
            </button>
            <mat-spinner *ngIf="isLoading" [diameter]="30" class="cbms-spinner"></mat-spinner>
            <mat-card-title>Add Device</mat-card-title>
            <mat-card-subtitle>Manually add a device by Device Address and Device ID.</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="content-wrapper">
            <mat-form-field class="form-field">
                <input matInput type="text" placeholder="Device Address" [(ngModel)]="searchDeviceAddress">
            </mat-form-field>
            <mat-form-field class="form-field">
                <input matInput type="text" placeholder="Device ID" [(ngModel)]="searchDeviceId">
            </mat-form-field>
        </mat-card-content>
    </ng-container>
</mat-card>
