<h2>User Management</h2>
<div class="cbms-spinner-wrapper" *ngIf="spinnerOn">
    <mat-spinner [diameter]="50" class="cbms-spinner"></mat-spinner>
</div>
<div class="table-wrapper">
    <p-table class="cbms-generic-table-style" #dt [value]="userList" [columns]="cols" [rows]="50" [paginator]="true"
        [globalFilterFields]="['firstName', 'lastName', 'username']" responsiveLayout="scroll"
        [(selection)]="selectedUserList" [scrollable]="true" scrollHeight="flex" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center">
                <h5>Users</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                </span>

                <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left-auto" label="New" (click)="openNew()"></button>
                <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left" [disabled]="!selectedUserList || !selectedUserList.length"
                    (click)="deleteSelectedItemsMenu.show($event);">
                    <mat-icon svgIcon="delete"></mat-icon>
                    Delete Selected
                </button>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="flex: 0 0 3rem !important; min-width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th></th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn
                    style="flex-basis: {{col.width}}; min-width: {{col.width}}">
                    <div class="cbms-disply-flex-row-centered">
                        <span>{{col.header}}</span>
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user let-columns="columns">
            <tr>
                <td style="flex: 0 0 3rem !important; min-width: 3rem">
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td>
                <td class="justify-content-end">
                    <button pButton pRipple icon="pi pi-pencil" class="icon-button ui-button-info"
                        (click)="editUser(user)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="icon-button ui-button-info"
                        (click)="deleteItemMenu.show($event);"></button>

                    <p-overlayPanel #deleteItemMenu appendTo="body">
                        <p>
                            Please confirm you want to continue with the removal<br>of
                            <strong>{{user.username}}</strong> user.
                        </p>

                        <hr>
                        <div class="delete-action-buttons">
                            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" label="Confirm"
                                (click)="deleteUser(user); deleteItemMenu.hide();"></button>
                            <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left-auto" label="Cancel" (click)="deleteItemMenu.hide()"></button>
                        </div>
                    </p-overlayPanel>
                </td>
                <td *ngFor="let col of columns" style="flex-basis: {{col.width}}; min-width: {{col.width}}">
                    <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'role.name'">
                            {{user.role.name}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'expiryDate'">
                            {{user.expiryDate ? (user.expiryDate | date:'dd.MM.yyy') : "N/A" }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'lastLoginTimestamp'">
                            {{user.lastLoginTimestamp ? (user.lastLoginTimestamp | date:'dd.MM.yyy, HH:mm:ss') : "N/A" }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>{{user[col.field]}}</ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog *ngIf="userDialog" [(visible)]="userDialog" [style]="{width: '960px', 'min-height': '550px'}" header="User Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
            <div class="formgrid grid">
                <div class="field col">
                    <label for="firstName">First Name</label>
                    <input type="text" pInputText id="firstName" name="firstName" [(ngModel)]="user.firstName"
                        required />
                    <small class="p-error" *ngIf="submitted && !user.firstName">First Name is required.</small>
                </div>
                <div class="field col">
                    <label for="lastName">Last Name</label>
                    <input type="text" pInputText id="lastName" name="fastName" [(ngModel)]="user.lastName" required />
                    <small class="p-error" *ngIf="submitted && !user.lastName">Last Name is required.</small>
                </div>
            </div>

            <div class="formgrid grid align-items-end">
                <div class="field col">
                    <label for="email">Email</label>
                    <input type="text" pInputText id="email" name="email" [(ngModel)]="user.username" required />
                    <small class="p-error" *ngIf="submitted && !user.username">Email is required.</small>
                </div>
                <div class="field col">
                    <div class="flex cbms-margin-bottom05">
                        <div class="col-6">Last Login:</div>
                        <div class="col-6">{{ user.lastLoginTimestamp ? (user.lastLoginTimestamp | date : 'dd/MM/yyy, HH:mm:ss') : 'N/A' }}</div>
                    </div>
                    <div class="flex">
                        <div class="col-6">Invitation Email Last Sent:</div>
                        <div class="col-6">{{ user.lastEmailInvitationTimestamp ? (user.lastEmailInvitationTimestamp | date : 'dd/MM/yyy, HH:mm:ss') : 'N/A' }}</div>
                    </div>
                </div>
            </div>

            <div class="formgrid grid align-items-end">
                <div class="field col-6">
                    <p-dropdown class="min-width300" name="userRole" [required]="true" [options]="userRoleList"
                        [(ngModel)]="selectedUserRole" placeholder="User Role" optionLabel="name"
                        (onChange)="onRoleChosen()" (onFocus)="updateUserRoleList($event)">
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted && !selectedUserRole">Please select a role.</small>
                </div>
                <div class="field col-6">
                    <div class="flex align-items-end">
                        <div class="col-1"><p-checkbox [disabled]="false" binary="true" [(ngModel)]="user.isSendEmailInvite"></p-checkbox></div>
                        <div class="col-8"><label>Send Invitation Email</label></div>
                    </div>
                </div>
            </div>

            <div class="formgrid grid align-items-center">
                <div class="field col-3" *ngIf='elementShouldDisplayFlagMap["customerDropdown"]'>
                    <p-dropdown class="min-width300" name="selectedCustomer" [required]="true" [options]="customerList"
                        [(ngModel)]="selectedCustomer" placeholder="Customer" optionLabel="name"
                        (onChange)="onCustomerChosen()" (onFocus)="fetchCustomerList($event)">
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted  && !selectedCustomer">Please select a customer.</small>
                </div>
                <div class="field col-3" *ngIf='elementShouldDisplayFlagMap["siteDropdown"]'>
                    <p-multiSelect #siteFilter class="min-width500" name="sekectedS" [options]="siteList"
                        [(ngModel)]="selectedSiteList" [filter]="true" filterBy="name" virtualScrollItemSize="30"
                        filterPlaceHolder="Sites" selectedItemsLabel="{0} Sites selected" maxSelectedLabels="1"
                        placeholder="Sites" optionLabel="name" dataKey="id" [virtualScroll]="true">
                        <p-footer>
                            <hr>
                            <div class="filter-footer" *ngIf="siteList && siteList.length">
                                <span *ngIf="siteFilter && !siteFilter._filteredOptions">showing
                                    {{siteList.length}} of {{siteList.length}}
                                    Sites</span>
                                <span *ngIf="siteFilter && siteFilter._filteredOptions">showing
                                    {{siteFilter._filteredOptions.length}} of {{siteList.length}}
                                    Sites</span>
                            </div>
                        </p-footer>
                    </p-multiSelect>
                    <small class="p-error" *ngIf="submitted && !selectedSiteList.length && !this.selectedResources.length">Please select at least one site.</small>
                </div>
                <div class="field col-2" *ngIf='elementShouldDisplayFlagMap["applyButton"]'>
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                        [disabled]="!selectedSiteList.length"
                        (click)="applyRole()">Apply</button>
                </div>
            </div>

            <p-table *ngIf='elementShouldDisplayFlagMap["resourcesTable"]' [style]="{height: '320px'}" [value]="selectedResources" class="cbms-generic-table-style" >
                <ng-template pTemplate="header">
                    <tr>
                        <th>Customer</th>
                        <th>Site</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{item.customer.name}}</td>
                        <td>{{item.site.name}}</td>
                        <td style="text-align: right;"><button pButton pRipple icon="pi pi-trash"
                                class="icon-button ui-button-info" (click)="removeResource(item)"></button></td>
                    </tr>
                </ng-template>
            </p-table>

            <div class="formgrid grid align-items-center" *ngIf="[GLOBAL_ADMIN_ROLE,CUSTOMER_ADMIN_ROLE].includes(user.role)">
                <div class="field col">
                    <h3>All Sites Available to View/Amend</h3>
                </div>
            </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" type="submit" data-cy="send-btn" label="Send" (click)="saveChanges()"></button>
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" label="Close" (click)="hideDialog()"
            data-cy="close-btn"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}" header="Warning" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-overlayPanel #deleteSelectedItemsMenu appendTo="body">
    <p>
        Please confirm you want to continue with the removal<br>of
        <strong>{{selectedUserList.length}}</strong> users.
    </p>
    <hr>
    <div class="delete-action-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" label="Confirm"
            (click)="deleteSelectedUsers(); deleteSelectedItemsMenu.hide();"></button>
        <button pButton class="p-button-raised p-button-text cbms-button cbms-margin-left" (click)="deleteSelectedItemsMenu.hide()">Cancel</button>
    </div>
</p-overlayPanel>
