import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataPoint } from 'src/app/models/data-point';
import { BackendService } from 'src/app/services/backend/backend.service';
import _ from 'lodash';
import { NotificationService } from '../../services/notification/notification.service';
import { Subscription } from 'rxjs';

const ResultStatus = {
    SUCCESS: 1,
    WARNING: 0,
    FAIL: -1
}

interface ScheduleDataPoint extends DataPoint {
    result: {
        code: string;
        message: string;
    }
    success: boolean;
    sortByResult: number
}

@Component({
  selector: 'cbms-write-twin-to-schedule-dialog',
  templateUrl: './write-twin-to-schedule-dialog.component.html',
  styleUrls: ['./write-twin-to-schedule-dialog.component.scss']
})
export class WriteTwinToScheduleDialogComponent implements OnInit {

    public dataPoints: ScheduleDataPoint[] = [];
    public writeTwinToScheduleSubscription: Subscription;
    public isLoading = false;
    public isLoadingDataPoints = false;
    public requestSent = false;
    public hasError = false;

    public cols: any[] = [
        { field: 'siteName', header: 'Site', sortable: true, width: '' },
        { field: 'deviceName', header: 'Device', sortable: true, width: '' },
        { field: 'objectName', header: 'Name', sortable: true, width: '' },
        { field: 'description', header: 'Source Description', sortable: true, width: '' },
        { field: 'customName', header: 'Custom Name', sortable: true, width: '' },
        { field: 'sortByResult', header: 'Result', sortable: true, width: '' }
    ];

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private backendService: BackendService,
        private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.isLoadingDataPoints = true;
        const filterParams = {
            dataPointIdFilter: Object.keys(this.config.data).map((key) => this.config.data[key])
        }
        const pageSize = filterParams.dataPointIdFilter.length;

        this.backendService.getDataPointsDetails(filterParams, 0, pageSize).subscribe(response => {
            this.dataPoints = response.content.map((dataPoint: ScheduleDataPoint) => {
                return Object.assign({}, dataPoint, { siteName: dataPoint.site.name, deviceName: dataPoint.deviceName, result: {} });
            });
            this.isLoadingDataPoints = false;
        });
    }

    public writeTwinToSchedule() {
        this.isLoading = true;
        this.requestSent = true;
        this.writeTwinToScheduleSubscription = this.backendService.
            writeTwinToSchedule(
                this.dataPoints.map(dataPoint => dataPoint.id)).subscribe(response => {
                    response.forEach(item => {
                        this.dataPoints.forEach(dataPoint => {
                            if (dataPoint.id === item.dataPointId) {
                                dataPoint.result = item.result;
                                dataPoint.success = item.success;
                                dataPoint.sortByResult = ResultStatus[item.result.code];
                            }
                        });
                    });
                    this.isLoading = false;

                }, (err) => {
                    this.hasError = true;
                    this.isLoading = false;
                    this.notificationService.addErrorMessage('Write Twin to Schedule', err);
                });
    }


    public close() {
        if (this.writeTwinToScheduleSubscription) {
            this.writeTwinToScheduleSubscription.unsubscribe();
        }
        this.ref.close();
    }

}
