import { Component, Input, OnInit } from '@angular/core';
import { DATAPOINTS_OP } from 'src/app/config/constants';
import { MappedDataPoint } from '../../../models/data-point';
import { DialogConfigService } from '../../../services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-setpoint-change-action',
    templateUrl: './setpoint-change-action.component.html',
    styleUrls: ['./setpoint-change-action.component.scss']
})
export class SetpointChangeActionComponent implements OnInit {

    @Input()
    public selectedDataPoints: MappedDataPoint[];

    @Input()
    isGlobalAction: boolean;


    constructor(private dialogConfigService: DialogConfigService) {

    }

    ngOnInit() {
    }

    public showSetpointDialog(mappedDataPoints: MappedDataPoint[]) {
        this.dialogConfigService.showSetpointDialog({dataPoints: mappedDataPoints, operation: DATAPOINTS_OP.MANAGE_VALUES});
    }

}
