<div class="cbms-content-wrapper" style="max-width: 1024px; margin: 25px auto;">
    <div class="col-12">
        <div class="card" *ngIf="equipmentTree !== undefined">
            <div class="card__header">
                <mat-icon svgIcon="{{equipmentTree.icon}}"></mat-icon>
                <h2>{{equipmentTree.label}}</h2>
            </div>
            <div class="card__content">
                <div class="grid content-row" *ngFor="let kpiPoint of equipmentTree.kpis; trackBy: trackByFn">
                    <ng-container [ngSwitch]="kpiPoint.signalType">
                        <ng-container *ngSwitchCase="'Binary'">
                            <div class="col-10">
                                <p class="font_size20">{{kpiPoint.label}}</p>
                            </div>
                            <div class="col-2">
                                <p class="font_size20">
                                    <p-inputSwitch [disabled]="kpiPoint.type !== 'Output'"
                                        [(ngModel)]="kpiPoint.presentValue" (onChange)="handleChange($event, kpiPoint)">
                                    </p-inputSwitch>
                                </p>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div class="col-10">
                                <p class="font_size20">{{kpiPoint.label}}</p>
                            </div>
                            <div class="col-2">
                                <p class="font_size20">{{kpiPoint.presentValue}} <span *ngIf="kpiPoint.units"
                                        style="font-size:12px">({{kpiPoint.units}})</span></p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="grid content-row">
                    <div class="col-12">
                        <!-- <cbms-equipment-accordion *ngIf="equipmentTree.children.length" [equipments]="equipmentTree.children"></cbms-equipment-accordion> -->
                        <p-accordion>
                            <p-accordionTab *ngFor="let subEquipment of equipmentTree.children"
                                header="{{subEquipment.label}}">
                                <div class="grid content-row"
                                    *ngFor="let point of subEquipment.points; trackBy: trackByFn">
                                    <ng-container [ngSwitch]="point.signalType">
                                        <ng-container *ngSwitchCase="'Binary'">
                                            <div class="col-10">
                                                <p class="font_size20">{{point.label}}</p>
                                            </div>
                                            <div class="col-2">
                                                <p class="font_size20">
                                                    <p-inputSwitch [disabled]="!point.isWritable"
                                                        [(ngModel)]="point.presentValue"
                                                        (onChange)="handleChange($event, point)">
                                                    </p-inputSwitch>
                                                </p>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <div class="col-10">
                                                <p class="font_size20">{{point.label}}</p>
                                            </div>
                                            <div class="col-2">
                                                <p class="font_size20">{{point.presentValue}} <span *ngIf="point.units"
                                                        style="font-size:12px">({{point.units}})</span></p>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div *ngIf="subEquipment.children.length">
                                    <p-accordion>
                                        <p-accordionTab *ngFor="let subSubEquipment of subEquipment.children;"
                                            header="{{subSubEquipment.label}}">
                                            <div class="grid content-row"
                                                *ngFor="let point of subSubEquipment.points; trackBy: trackByFn">
                                                <ng-container [ngSwitch]="point.signalType">
                                                    <ng-container *ngSwitchCase="'Binary'">
                                                        <div class="col-10">
                                                            <p class="font_size20">{{point.label}}</p>
                                                        </div>
                                                        <div class="col-2">
                                                            <p class="font_size20">
                                                                <p-inputSwitch [disabled]="!point.isWritable"
                                                                    [(ngModel)]="point.presentValue"
                                                                    (onChange)="handleChange($event, point)">
                                                                </p-inputSwitch>
                                                            </p>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        <div class="col-10">
                                                            <p class="font_size20">{{point.label}}</p>
                                                        </div>
                                                        <div class="col-2">
                                                            <p class="font_size20">{{point.presentValue}} <span
                                                                    *ngIf="point.units"
                                                                    style="font-size:12px">({{point.units}})</span></p>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>