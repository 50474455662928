
export enum TemplateType {
    WEEKLY_SCHEDULE = 'WEEKLY_SCHEDULE',
    EXCEPTION_SCHEDULE = 'EXCEPTION_SCHEDULE'
}//'WEEKLY_SCHEDULE' | 'EXCEPTION_SCHEDULE'

export interface Template {
    id: string,
    name: string,
    type: TemplateType
}


