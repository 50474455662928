import { Component } from '@angular/core';
import { BackendService } from '../../services/backend/backend.service';
import { DataPoint } from '../../models/data-point';
import { LazyLoadEvent } from 'primeng/api';
import { UtilsService } from '../../services/utils/util.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { FilterParams } from '../../components/filter/interfaces/filter-params.interface';
import { ActionConfig } from '../../models/action-config';
import { FieldList } from '../../config/constants';
import { FilterService } from '../../components/filter/filter.service';

@Component({
    selector: 'cbms-data-points',
    templateUrl: './data-points.component.html',
    styleUrls: ['./data-points.component.scss']
})
export class DataPointsComponent {
    public dataPoints: DataPoint[] = null;
    public nrOfSelectedDataPoints: number = null;
    public totalRecords: number = null;
    public showSelectedDataPointsOn = false;
    public isSelectedDatapointMode = false;
    public shouldHideFilters = false;
    public allDPSelected: boolean;
    public getObjectKeys = Object.keys;
    public selectedDataPointList: { [key: string]: DataPoint | { id: string } } = {};
    public cartUpdated: boolean = false;
    private pageSize = this.utilsService.getDataPointsPageSize();
    private filtersSubscription: Subscription;
    private getDataPointsSubscription: Subscription;
    private customerSubscription: Subscription;
    private filterParams: FilterParams;

    filterConfigObject = {
        siteFilter: true,
        deviceFilter: true,
        tagFilter: true,
        tagLogicalOperator: true,
        quickFilterText: true,
        fieldFilter: true,
        gatewayFilter: true
    };
    tabName: string = 'datapoints';
    public actionConfig: ActionConfig = {
        watchGroup: true,
        editTagsGroup: true,
        setpointChangeGroup: true,
        analysisGroup: true,
        analysisOneRecord: true,
        setpointChangeOneRecord: true,
        viewDatapointOneRecord: true,
        copyGroup: false,
        selectRow: false,
        showScheduleOneRecord: false,
        showExceptionScheduleOneRecord: false,
        createTwinFromSchedule: false,
        compareScheduleToTwin: false,
        writeTwinToSchedule: false,
        createTwinFromDatapoint: true,
        writeTwinToDatapoint: true,
        syncWithOC: true,
        assignOC: true
    };

    public fieldFilterOptions: FieldList[] = [
        FieldList.NAME,
        FieldList.TYPE,
        FieldList.SIGNAL_TYPE,
        FieldList.SOURCE,
        FieldList.DATA_POINT_INTERNAL_ID,
        FieldList.CUSTOM_NAME,
        FieldList.SOURCE_DESCRIPTION,
        FieldList.UNITS,
        FieldList.LAST_RECORDED_VALUE

    ];
    public columnsConfig: any = ['deviceSiteName', 'deviceName', 'objectName', 'type', 'description', 'presentValue'
        , 'presentValueTimestamp', 'twinDatapointValue', 'twinDatapointCreationDate', 'units', 'tags', 'customName',
        'sourceId', 'id', 'signalType', 'source','ocSite.tenant','ocSite.customer','ocSite.name','ocSite.classification', 'ocSite.channel', 'ocSite.granularity',
        'ocSite.isAccumulator','ocSite.dataSource'
    ];

    constructor(
        private backendService: BackendService,
        private utilsService: UtilsService,
        private dataService: DataService,
        private filterService: FilterService) {
    }

    ngOnInit() {
        if (!this.isSelectedDatapointMode) {
            sessionStorage.removeItem('excludeSitesFilter');
            this.dataService.shouldDisableFilter(null);
        }

        this.dataService.currentTabHasChanged('dataPoints');
        this.restoreSelectionFromSession();

        this.filtersSubscription = this.dataService.filtersChanged$.subscribe((filters: FilterParams) => {
            this.dataPoints = null;
            this.filterParams = { ...filters, isPolled: true };

            if (this.getDataPointsSubscription) {
                this.getDataPointsSubscription.unsubscribe();
            }

            if (this.filterParams.quickFilterText) {
                this.loadDataForQuickSearch(0);
            } else {
                this.loadData(0, this.pageSize, this.getSorting());
            }
        });

        this.customerSubscription = this.filterService.customerFilterChanges$.subscribe(() => {
            this.clearDPSelection();
        });
    }

    private clearDPSelection() {
        this.selectedDataPointList = {};
        this.nrOfSelectedDataPoints = null;
        this.allDPSelected = false;
        sessionStorage.removeItem(`${this.tabName}-selectedDataPoints`);
    }

    private restoreSelectionFromSession() {
        this.selectedDataPointList = JSON.parse(sessionStorage.getItem(`${this.tabName}-selectedDataPoints`)) || {};
        this.nrOfSelectedDataPoints = Object.keys(this.selectedDataPointList).length;
    }

    private getSorting() {
        if (sessionStorage.getItem('multiSortOptions')) {
            const multiSortOptions = JSON.parse(sessionStorage.getItem('multiSortOptions'));
            const sortOptions = {
                sortField: multiSortOptions.map(sortOption => sortOption.field).join(),
                sortOrder: multiSortOptions[0].order === 1 ? 'asc' : 'desc'
            };
            return sortOptions;
        }
        return null;
    }

    ngOnDestroy() {
        if (this.filtersSubscription) {
            this.filtersSubscription.unsubscribe();
        }
        if (this.getDataPointsSubscription) {
            this.getDataPointsSubscription.unsubscribe();
        }
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }
        sessionStorage.removeItem('excludeSitesFilter');
        this.dataService.shouldDisableFilter(null);
    }

    public updateSelectedDataPoints(selectedDataPoints: DataPoint[]) {
        if (selectedDataPoints === null) {
            this.clearDPSelection();
            return;
        }

        this.cartUpdated = true;
        selectedDataPoints.forEach(selectedDataPoint => {
            if (selectedDataPoint.selected) {
                this.selectedDataPointList = Object.assign({}, this.selectedDataPointList, { [selectedDataPoint.id]: selectedDataPoint })
            } else {
                delete this.selectedDataPointList[selectedDataPoint.id];
                this.selectedDataPointList = { ...this.selectedDataPointList };
            }
        });

        this.updateAllDPSelectedValue();

        sessionStorage.setItem(`${this.tabName}-selectedDataPoints`, JSON.stringify(this.selectedDataPointList));
        this.nrOfSelectedDataPoints = Object.keys(this.selectedDataPointList).length;
    }

    public toggleAllDataPointsSelection(shouldSelectAll: boolean) {
        if (shouldSelectAll) {
            if (this.isSelectedDatapointMode) {
                this.selectedDataPointList = {};
                this.dataPoints.forEach(dataPoint => {
                    this.selectedDataPointList[dataPoint.id] = { id: dataPoint.id };
                    dataPoint.selected = true;
                });

                this.nrOfSelectedDataPoints = Object.keys(this.selectedDataPointList).length;
                sessionStorage.setItem(`${this.tabName}-selectedDataPoints`, JSON.stringify(this.selectedDataPointList));
                this.updateAllDPSelectedValue();
            } else {
                this.backendService.getAllDataPointsIdList(this.filterParams).subscribe((response) => {
                    this.selectedDataPointList = {};
                    response.forEach(item => {
                        this.selectedDataPointList[item] = { id: item };
                    });
                    this.nrOfSelectedDataPoints = Object.keys(this.selectedDataPointList).length;
                    sessionStorage.setItem(`${this.tabName}-selectedDataPoints`, JSON.stringify(this.selectedDataPointList));
                    this.markDataPointsAsSelected(response);
                    this.updateAllDPSelectedValue();
                });
            }
        } else {
            this.clearDPSelection();
            this.markDataPointsAsUnselected();
        }
        this.cartUpdated = true;
    }

    public lazyLoadEvent(event: LazyLoadEvent) {
        const pageNumber = event.first !== 0 ? (event.first / this.pageSize) : 0;
        let sortOptions = null;

        if (event.multiSortMeta && event.multiSortMeta.length) {
            sortOptions = {
                sortField: event.multiSortMeta.map(sortOption => sortOption.field).join(),
                sortOrder: event.multiSortMeta[0].order === 1 ? 'asc' : 'desc'
            };
        }

        if (this.filterParams.quickFilterText) {
            this.loadDataForQuickSearch(pageNumber);
        } else {
            this.loadData(pageNumber, this.pageSize, sortOptions);
        }
    }

    public showSelectedDP() {
        sessionStorage.setItem('excludeSitesFilter', "true");
        this.dataService.shouldDisableFilter('sites');
        this.isSelectedDatapointMode = true;
        this.shouldHideFilters = true;
        this.loadData(0, this.pageSize, this.getSorting());
    }

    public showAllDP() {
        sessionStorage.removeItem('excludeSitesFilter');
        this.dataService.shouldDisableFilter(null);
        this.isSelectedDatapointMode = false;
        this.shouldHideFilters = false;
        delete this.filterParams.dataPointIdFilter;
        this.loadData(0, this.pageSize, this.getSorting());
    }

    private updateAllDPSelectedValue() {
        this.allDPSelected = Object.keys(this.selectedDataPointList).length === this.totalRecords;
    }

    private markDataPointsAsSelected(selectedDataPointsIdList: string[]) {
        this.dataPoints.forEach((dataPoint => {
            dataPoint.selected = selectedDataPointsIdList.indexOf(dataPoint.id) !== -1;
        }));
    }

    private markDataPointsAsUnselected() {
        this.dataPoints.forEach((dataPoint => {
            dataPoint.selected = false;
        }));
    }

    private loadData(pageNumber: number, pageSize: number, sortOptions) {
        let filterParams;
        if (this.isSelectedDatapointMode) {
            filterParams = {
                dataPointIdFilter: Object.keys(this.selectedDataPointList).map((key) => this.selectedDataPointList[key])
            }
        } else {
            filterParams = this.filterParams;
        }

        this.getDataPointsSubscription = this.backendService.getDataPointsSummary(filterParams, pageNumber, pageSize, true, sortOptions).subscribe(response => {
            this.dataPoints = null;
            this.dataPoints = [...response.content];
            let selectedIdsList = Object.keys(this.selectedDataPointList);
            if (selectedIdsList.length) {
                this.dataPoints = response.content.map(dataPoint => {
                    if (selectedIdsList.indexOf(dataPoint.id) !== -1) {
                        dataPoint.selected = true;
                    }
                    return dataPoint;
                });
            }
            this.totalRecords = response.totalElements;
            this.updateAllDPSelectedValue();
        });
    }

    private loadDataForQuickSearch(pageNumber: number) {
        this.backendService.getDataPointTagsAutoSuggestions(this.filterParams.quickFilterText).subscribe(tagList => {
            this.filterParams.tagIdList = tagList.map(value => value.id);
            this.loadData(pageNumber, this.pageSize, this.getSorting());
        });
    }
}
