export interface AuditTrailResponse {
    id: number;
    userName?: string;
    tenantName?: string;
    customerName?: string;
    siteName?: string;
    gatewayName?: string;
    deviceName?: string;
    operationId?: string;
    dataPointName?: string;
    dataPointDescription?: string;
    dataPointType?: string;
    dataPointId?: string;
    actionCategory?: string;
    action?: string;
    timestamp?: string;
    actionResult?: string;
    valueBefore?: string;
    valueAfter?: string;
}

export const auditTrailMapper = {
    userName: 'userName',
    tenantName: 'tenantName',
    customerName: 'customerName',
    siteName: 'siteName',
    gatewayName: 'gatewayName',
    deviceName: 'deviceName',
    operationId: 'operationId',
    dataPointName: 'dataPointName',
    dataPointDescription: 'dataPointDescription',
    dataPointType: 'dataPointType',
    dataPointId: 'dataPointId',
    actionCategory: 'actionCategory',
    action: 'action',
    actionTimestamp: 'actionTimestamp',
    actionResult: 'actionResult',
    valueBefore: 'valueBefore',
    valueAfter: 'valueAfter',
};


