<div class="content manage-value">
    <div class="cbms-spinner-wrapper" *ngIf="isLoadingPresentValue">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <div *ngIf="!selectionValid && !isLoadingPresentValue">
        <p>Managing the values of Data Points with different units is not allowed, this is to help avoid possible
            selection errors.<br />
            You have selected Data Points with the following units:</p>
        <ul *ngIf="selectedUnitTypes.length">
            <li *ngFor="let unitType of selectedUnitTypes">{{unitType || 'undefined'}}</li>
        </ul>
    </div>

    <p-table class="cbms-generic-table-style" [value]="dataPoints" [columns]="cols" [resizableColumns]="true"
        [scrollable]="true" (onColResize)="onColResize()" [scrollHeight]="'500px'" columnResizeMode="expand"
        stateStorage="session" stateKey="{{tableName}}-session" *ngIf="!isLoadingPresentValue && selectionValid">
        <ng-template pTemplate="caption">
            <div class="setpoint-caption-wrapper">
                <p-radioButton data-cy="change-radio-btn" name="manageValueOption" value="change"
                    [(ngModel)]="manageValueOption"></p-radioButton>
                <span class="cbms-small-margin-left caption-label width_150">Change values to / by</span>
                <input class="cbms-small-margin-left input" pInputText type="text" pKeyFilter="pint"
                    [(ngModel)]="operatorValue" [disabled]="manageValueOption === 'reset' || operation === 'writeTwinToDatapoint'">
                <button pButton class="p-button-raised p-button-text cbms-button button cbms-small-margin-left" (click)="set()"
                    [disabled]="manageValueOption === 'reset' || operation === 'writeTwinToDatapoint'" data-cy="set-btb">
                    <mat-icon class="button-icon" svgIcon="equal"></mat-icon>
                    <span class="cbms-small-margin-left">set</span>
                </button>
                <button pButton class="p-button-raised p-button-text cbms-button button cbms-small-margin-left" (click)="add()" data-cy="add-btn"
                    [disabled]="manageValueOption === 'reset' || operation === 'writeTwinToDatapoint'">
                    <mat-icon class="button-icon" svgIcon="plus"></mat-icon>
                    <span class="cbms-small-margin-left">add</span>
                </button>
                <button class="cbms-small-margin-left button" pButton class="p-button-raised p-button-text cbms-button button cbms-small-margin-left" (click)="sub()" data-cy="sub-btn"
                    [disabled]="manageValueOption === 'reset' || operation === 'writeTwinToDatapoint'">
                    <mat-icon class="button-icon" svgIcon="minus"></mat-icon>
                    <span class="cbms-small-margin-left">sub</span>
                </button>
                <button pButton class="p-button-raised p-button-text cbms-button button cbms-margin-left" (click)="undo()" data-cy="undo-btn"
                    [disabled]="manageValueOption === 'reset' || operation === 'writeTwinToDatapoint'">
                    <mat-icon class="button-icon" svgIcon="reset"></mat-icon>
                    <span class="cbms-small-margin-left">undo</span>
                </button>
                <span class="caption-label cbms-margin-left cbms-text-align-left">At Priority</span>
                <p-dropdown appendTo="body" class="cbms-small-margin-left" [options]="prioritiesList"
                    data-cy="priority-dd" [(ngModel)]="priority"
                    [disabled]="manageValueOption === 'reset' || priorityDisabled || operation === 'writeTwinToDatapoint'"
                    pTooltip="Smart select = System will read the highest commanded value on (relinquish default, then priority 16 through to priority 8) and write the new set value on the highest commanded priority."
                    tooltipPosition="top" showDelay="200" hideDelay="2000">
                </p-dropdown>


                <p-dropdown appendTo="body" class="cbms-small-margin-left" [options]="priorityTypeList"
                    data-cy="priority-dd" [(ngModel)]="priorityType"
                    [disabled]="manageValueOption === 'reset' || operation === 'writeTwinToDatapoint'" tooltipPosition="top" showDelay="200"
                    hideDelay="2000">
                </p-dropdown>

                <ng-container *ngIf="priorityType === 'Timed'">
                    <span class="caption-label cbms-margin-left cbms-text-align-left">For</span>
                    <p-inputMask class="cbms-small-margin-left" [(ngModel)]="rollbackTimer" mask="99:99"
                        placeholder="hh:mm"></p-inputMask>
                </ng-container>

            </div>
            <div class="setpoint-caption-wrapper setpoint-caption-wrapper--margin0">
                <p-radioButton data-cy="reset-radio-btn" name="manageValueOption" value="reset"
                    [(ngModel)]="manageValueOption"></p-radioButton>
                <span class="cbms-small-margin-left caption-label width_150 cbms-text-align-left">Reset options</span>
                <p-dropdown data-cy="reset-options-list" appendTo="body" class="cbms-small-margin-left"
                    [options]="resetOptionsList" [(ngModel)]="selectedResetOption" optionDisabled="inactive" 
                    [disabled]="manageValueOption === 'change' || operation === 'writeTwinToDatapoint'"></p-dropdown>
                <p-dropdown data-cy="priority-to-reset" *ngIf="selectedResetOption === 'specific' && !priorityDisabled" appendTo="body"
                    class="cbms-small-margin-left" [options]="resetPrioritiesList" [(ngModel)]="priorityToReset"
                    [disabled]="manageValueOption === 'change' || operation === 'writeTwinToDatapoint'"></p-dropdown>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="cbms-text-align-left" *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [pSortableColumnDisabled]="!col.sortable" pResizableColumn>
                    {{col.header}}
                    <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataPoint>
            <tr>
                <td class="ui-resizable-column">{{dataPoint.siteName}}</td>
                <td class="ui-resizable-column">{{dataPoint.deviceName}} ({{dataPoint.deviceId}})</td>
                <td class="ui-resizable-column">{{dataPoint.name}}</td>
                <td class="ui-resizable-column">{{dataPoint.units}}</td>
                <td class="ui-resizable-column">{{dataPoint.rollbackTimer ? dataPoint.rollbackValue : ''}}</td>
                <td class="ui-resizable-column"><cbms-count-down *ngIf="dataPoint.rollbackTimer" (shouldRefreshDataEmitter)="refreshData()" [rollbackTimer]="dataPoint.rollbackTimer"> </cbms-count-down></td>
                <td class="ui-resizable-column">{{dataPoint.value === null ? 'Not Reachable' : dataPoint.value}}</td>
                <td class="ui-resizable-column">
                    <ng-container *ngIf="operation === 'manageValues'">
                        <p-inputNumber [(ngModel)]="dataPoint.newValue" [showButtons]="true" mode="decimal"
                        [placeholder]="'Value'" [required]="true" [min]="0" [step]="0.50"
                        [disabled]="dataPoint.value === null || manageValueOption === 'reset'"></p-inputNumber>
                    </ng-container>

                    <ng-container *ngIf="operation === 'writeTwinToDatapoint'">
                        <p-inputNumber *ngIf="dataPoint.twinDatapointCreationDate" [(ngModel)]="dataPoint.twinDatapointValue" [showButtons]="true" mode="decimal"
                        [placeholder]="'Value'" [required]="true" [min]="0" [step]="0.50"
                        [disabled]="true"></p-inputNumber>
                        <p *ngIf="!dataPoint.twinDatapointCreationDate">No Twin Available</p>
                    </ng-container>
                </td>
                <td class="ui-resizable-column">
                    {{dataPoint.updatedValue}}
                </td>
                <td class="ui-resizable-column">
                    <div class="cbms-api-response-feedback" *ngIf="!isLoading">
                        <div class="cbms-api-response-feedback__message">
                            <div class="cbms-api-response-feedback__icon">
                                <mat-icon class="cbms-red-color small" svgIcon="failure"
                                    *ngIf="isError(dataPoint.status)"></mat-icon>
                                <mat-icon class="cbms-yellow-color small" svgIcon="warning"
                                    *ngIf="isWarning(dataPoint.status) "></mat-icon>
                                <mat-icon class="cbms-green-color small" svgIcon="success"
                                    *ngIf="isSucces(dataPoint.status)"></mat-icon>
                                <mat-icon class="bigger" svgIcon="disabled" *ngIf="isDisabled(dataPoint.status)">
                                </mat-icon>
                            </div>
                            <span *ngIf="dataPoint.result">{{dataPoint.result.message}}</span>
                        </div>
                        <div class="cbms-api-response-feedback__code" *ngIf="isError(dataPoint.status)">
                            <span>{{dataPoint.result.code}}</span>
                        </div>
                    </div>
                    <mat-spinner *ngIf="isLoading" [diameter]="20" class="cbms-spinner"></mat-spinner>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" [disabled]="isLoading || !selectionValid"
        pTooltip="Writes selected values to all objects in the list" showDelay="1000" hideDelay="500" (click)="send()"
        data-cy="send-btn">Send</button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()"
        data-cy="close-btn">Close</button>
</div>
