import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { DataPoint } from '../../../models/data-point';
import { NotificationService } from '../../../services/notification/notification.service';

@Component({
    selector: 'cbms-sync-with-oc-action',
    templateUrl: './sync-with-oc-action.component.html',
    styleUrls: ['./sync-with-oc-action.component.scss']
})

//sync datapoints With Optimum Commercial action
export class SyncWithOcActionComponent implements OnInit {

    @Input()
    public selectedDataPoints: DataPoint[];

    @Output()
    public updateOcSiteEvent = new EventEmitter<DataPoint[]>();

    shouldDisableButton = false;

    constructor(private backendService: BackendService, private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.shouldDisableButton = !sessionStorage.getItem('userRolePermissionList').includes("SYNC_WITH_OPTIMUM_COMMERCIAL_BUTTON.CORE_APP");
    }

    public syncDatapointsWithOptimumCommercial() {
        this.backendService.syncDatapointsWithOptimumCommercial(this.selectedDataPoints
            .map(dataPoint => dataPoint.id)).subscribe(data => this.notificationService.addSuccessMessage('Sync with Optimum Commercial',
            'Please check the Audit Log tab for more details', true));

    }

}
