import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BackendService } from '../../../services/backend/backend.service';
import { NotificationService } from '../../../services/notification/notification.service';

@Component({
    selector: 'cbms-schedule-template-delete-action',
    templateUrl: './schedule-template-delete-action.component.html',
    styleUrls: ['./schedule-template-delete-action.component.scss']
})
export class ScheduleTemplateDeleteActionComponent implements OnInit {

    @Input() public selectedId: string;
    @Input() public selectedName: any;
    @Output() public onDelete: EventEmitter<void> = new EventEmitter<null>();

    constructor(public backendService: BackendService,
                public notificationService: NotificationService) {

    }

    ngOnInit() {
    }

    deleteEntity() {
        this.backendService.deleteTemplateById(this.selectedId).subscribe(
            value => {
                this.notificationService.addSuccessMessage('Template', 'Template deleted successfully');
                this.onDelete.emit(null);
            });
    }
}
