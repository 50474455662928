import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MappedDataPoint, DataPoint } from 'src/app/models/data-point';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';

@Component({
	selector: 'cbms-create-twin-from-datapoint-action',
	templateUrl: './create-twin-from-datapoint-action.component.html',
	styleUrls: ['./create-twin-from-datapoint-action.component.scss']
})
export class CreateTwinFromDatapointActionComponent implements OnInit {

	@Input()
	public selectedDataPoints: MappedDataPoint[];

	@Output()
	public updateTwinEvent = new EventEmitter<DataPoint[]>();

	constructor(private dialogConfigService: DialogConfigService) {
	}

	ngOnInit() {
	}

	public showCreateTwinDialog() {
		const ref = this.dialogConfigService.showCreateTwinFromDatapointDialog(this.selectedDataPoints);
		ref.onClose.subscribe((dataPoints: DataPoint[]) => {
			if (dataPoints) {
				this.updateTwinEvent.emit(dataPoints);
			}
		});

	}
}
