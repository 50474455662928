<h2 class="cbms-h2">Auto Optimisation</h2>

<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Data Point Optimisation Enabled</h3>
    </div>
    <div class="col-2">
        <p-checkbox name="twinDatapointEnabled" [(ngModel)]="twinDatapointEnabled" binary="true"
                    (click)="handleTwinDatapointUpdate()">
        </p-checkbox>
    </div>

</div>

<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Schedule Optimisation Enabled</h3>
    </div>
    <div class="col-2">
        <p-checkbox name="twinScheduleEnabled" [(ngModel)]="twinScheduleEnabled"
                    binary="true"
                    (click)="handleTwinScheduleUpdate()">
        </p-checkbox>
    </div>

</div>


<hr>
<h2 class="cbms-h2">Auto Optimisation Daily Reports</h2>
<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Twin Datapoint Optimisation Report</h3>
    </div>
    <div class="col-2">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
            ><b>{{ twinDatapointReportStatus }}</b></span
            >
        </p>
    </div>
    <div class="col-4">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
            ><b>Download date:&nbsp;&nbsp;</b></span
            >
            {{ twinDatapointReportEndDate | date: 'dd.MM.yyy, HH:mm:ss' }}
            <button
                pButton
                class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-left"
                (click)="downloadTwinDatapointReport()"
                [disabled]="twinDatapointReportStatus !== 'Complete'"
            >
                <i class="pi pi-download"></i>
            </button>
            <button
                pButton
                class="p-button-raised p-button-text cbms-row-button cbms-button__turquoise-bg export-button cbms-margin-left"
                (click)="showTooltipTwinDatapointReport.toggle($event)"
            >
                <i class="pi pi-info"></i>
            </button>

            <p-overlayPanel #showTooltipTwinDatapointReport [style]="{ width: '550px' }">
                <ng-template pTemplate="content">
                    <h3>Twin datapoint Optimisation Report</h3>
                    <p class="cbms-margin-bottom05">
                        This report only displays data points that did not match their digital twin.
                        Where the data point has been successfully changed to match the twin, this will be displayed in the Successful Write column.
                    </p>

                </ng-template>
            </p-overlayPanel>

        </p>
    </div>
</div>

<div class="grid align-items-center">
    <div class="col-3">
        <h3 class="cbms-text-size25">Twin Schedule Optimisation Report</h3>
    </div>
    <div class="col-2">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
            ><b>{{ twinScheduleReportStatus }}</b></span
            >
        </p>
    </div>
    <div class="col-4">
        <p class="cbms-vertical-align cbms-min_height_36">
            <span class="cbms-text-size18"
            ><b>Download date:&nbsp;&nbsp;</b></span
            >
            {{ twinScheduleReportEndDate | date: 'dd.MM.yyy, HH:mm:ss' }}
            <button
                pButton
                class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-left"
                (click)="downloadTwinScheduleReport()"
                [disabled]="twinScheduleReportStatus !== 'Complete'"
            >
                <i class="pi pi-download"></i>
            </button>

            <button
                pButton
                class="p-button-raised p-button-text cbms-row-button cbms-button__turquoise-bg export-button cbms-margin-left"
                (click)="showTooltipTwinScheduleReport.toggle($event)"
            >
                <i class="pi pi-info"></i>
            </button>

            <p-overlayPanel #showTooltipTwinScheduleReport [style]="{ width: '550px' }">
                <ng-template pTemplate="content">
                    <h3>Twin schedule Optimisation Report</h3>
                    <p class="cbms-margin-bottom05">
                        This report only displays schedules that did not match their digital twin.
                        Where the schedule has been successfully changed to match the twin, this will be displayed in the Successful Write column.
                    </p>

                </ng-template>
            </p-overlayPanel>

        </p>
    </div>
</div>
