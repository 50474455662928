<mat-card *ngIf="'CC' === device.source" class="card" (click)="onDeviceClick()">
    <mat-card-header>
        <mat-icon mat-card-avatar svgIcon="devices"></mat-icon>
        <mat-card-title class="ellipsis" [pTooltip]="device.objectName"
                        [tooltipDisabled]="device.objectName && device.objectName.length <= 23" tooltipPosition="top"
                        hideDelay="100">
            {{device.objectName}}</mat-card-title>
        <button pButton class="p-button-raised p-button-text cbms-button cbms-card-button cbms-margin-left-auto" 
                (click)="$event.stopPropagation(); toggleVisibility();"
                pTooltip="Toggle device visibility in devices tab"
                showDelay="1000" hideDelay="500">
            <mat-icon [svgIcon]="visible?'visibility_on':'visibility_off'"></mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content>
        <table class="table">
            <tr>
                <td>
                    <span>Description</span>
                </td>
                <td>
                    <span class="label" [pTooltip]="device.description"
                          [tooltipDisabled]="device.description && device.description.length <= 28"
                          tooltipPosition="top"
                          hideDelay="100">{{device.description}}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Device Address</span>
                </td>
                <td>
                    <span class="label">{{device.deviceAddress}}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Device ID</span>
                </td>
                <td>
                    <span class="label">{{device.deviceId}}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Object Name</span>
                </td>
                <td>
                    <span class="label" [pTooltip]="device.objectName"
                          [tooltipDisabled]="device.objectName && device.objectName.length <= 28" tooltipPosition="top"
                          hideDelay="100">{{device.objectName}}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Vendor Name</span>
                </td>
                <td>
                    <span class="label" [pTooltip]="device.vendorName"
                          [tooltipDisabled]="device.vendorName && device.vendorName.length <= 28" tooltipPosition="top"
                          hideDelay="100">{{device.vendorName}}</span>
                </td>
            </tr>
        </table>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="'WiseMeter' === device.source" class="card-height-620" (click)="onDeviceClick()">
    <mat-card-header>
        <mat-icon mat-card-avatar svgIcon="devices"></mat-icon>
        <mat-card-title class="ellipsis" [pTooltip]="device.objectName"
                        [tooltipDisabled]="device.objectName && device.objectName.length <= 23"
                        tooltipPosition="top" hideDelay="100">
            {{device.objectName}}</mat-card-title>
        <button pButton class="p-button-raised p-button-text cbms-button cbms-card-button cbms-margin-left-auto" 
                (click)="$event.stopPropagation(); toggleVisibility();"
                pTooltip="Toggle device visibility in devices tab"
                showDelay="1000" hideDelay="500">
            <mat-icon [svgIcon]="visible?'visibility_on':'visibility_off'"></mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        <table class="table">

            <tr>
                <td>
                    <span>Description</span>
                </td>
                <td>
                    <span class="label" [pTooltip]="device.description"
                          [tooltipDisabled]="device.description && device.description.length <= 28"
                          tooltipPosition="top"
                          hideDelay="100">{{device.description}}</span>
                </td>
            </tr>
            <tr>
                <td><span>Id</span></td>
                <td><span *ngIf="device.extensions.id" class="label" [pTooltip]="device.extensions.id.value"
                          [tooltipDisabled]="device.extensions.id && device.extensions.id.value.length <= 28"
                          tooltipPosition="top"
                          hideDelay="100">{{device.extensions.id.value}}</span></td>
            </tr>
            <tr>
                <td><span>Name</span></td>
                <td><span class="label" [pTooltip]="device.objectName"
                          [tooltipDisabled]="device.objectName && device.objectName.length <= 28" tooltipPosition="top"
                          hideDelay="100">{{device.objectName}}</span></td>
            </tr>
            <tr>
                <td><span>Organization</span></td>
                <td><span *ngIf="device.extensions.organization " class="label"
                          [pTooltip]="device.extensions.organization.value "
                          [tooltipDisabled]=" device.extensions.organization.value && device.extensions.organization.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.organization.value}}</span></td>
            </tr>
            <tr>
                <td><span>Latitude</span></td>
                <td><span *ngIf="device.extensions.latitude " class="label"
                          [pTooltip]="device.extensions.latitude.value "
                          [tooltipDisabled]=" device.extensions.latitude.value && device.extensions.latitude.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.latitude.value}}</span></td>
            </tr>
            <tr>
                <td><span>Longitude</span></td>
                <td><span *ngIf="device.extensions.longitude " class="label"
                          [pTooltip]="device.extensions.longitude.value "
                          [tooltipDisabled]=" device.extensions.longitude.value && device.extensions.longitude.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.longitude.value}}</span></td>
            </tr>
            <tr>
                <td><span>Timezone</span></td>
                <td><span *ngIf="device.extensions.timezone " class="label"
                          [pTooltip]="device.extensions.timezone.value "
                          [tooltipDisabled]=" device.extensions.timezone.value && device.extensions.timezone.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.timezone.value}}</span></td>
            </tr>
            <tr>
                <td><span>Web password</span></td>
                <td><span *ngIf="device.extensions.web_password " class="label"
                          [pTooltip]="device.extensions.web_password.value "
                          [tooltipDisabled]=" device.extensions.web_password.value && device.extensions.web_password.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.web_password.value}}</span></td>
            </tr>
            <tr>
                <td><span>Ethernet</span></td>
                <td><span *ngIf="device.extensions.ethernet " class="label"
                          [pTooltip]="device.extensions.ethernet.value "
                          [tooltipDisabled]=" device.extensions.ethernet.value && device.extensions.ethernet.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.ethernet.value}}</span></td>
            </tr>
            <tr>
                <td><span>Mode</span></td>
                <td><span *ngIf="device.extensions.mode " class="label" [pTooltip]="device.extensions.mode.value "
                          [tooltipDisabled]=" device.extensions.mode.value && device.extensions.mode.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.mode.value}}</span></td>
            </tr>
            <tr>
                <td><span>Available Disk</span></td>
                <td><span *ngIf="device.extensions.disk_space_available " class="label"
                          [pTooltip]="device.extensions.disk_space_available.value "
                          [tooltipDisabled]=" device.extensions.disk_space_available.value && device.extensions.disk_space_available.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.disk_space_available.value}}</span>
                </td>
            </tr>
            <tr>
                <td><span>Used Disk</span></td>
                <td><span *ngIf="device.extensions.disk_space_used " class="label"
                          [pTooltip]="device.extensions.disk_space_used.value "
                          [tooltipDisabled]=" device.extensions.disk_space_used.value && device.extensions.disk_space_used.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.disk_space_used.value}}</span></td>
            </tr>
            <tr>
                <td><span>Free Disk</span></td>
                <td><span *ngIf="device.extensions.disk_space_free " class="label"
                          [pTooltip]="device.extensions.disk_space_free.value "
                          [tooltipDisabled]=" device.extensions.disk_space_free.value && device.extensions.disk_space_free.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.disk_space_free.value}}</span></td>
            </tr>
            <tr>
                <td><span>Actuators</span></td>
                <td><span *ngIf="device.extensions.actuators " class="label"
                          [pTooltip]="device.extensions.actuators.value "
                          [tooltipDisabled]=" device.extensions.actuators.value && device.extensions.actuators.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.actuators.value}}</span></td>
            </tr>
            <tr>
                <td><span>Readings</span></td>
                <td><span *ngIf="device.extensions.readings " class="label"
                          [pTooltip]="device.extensions.readings.value "
                          [tooltipDisabled]=" device.extensions.readings.value && device.extensions.readings.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.readings.value}}</span></td>
            </tr>
            <tr>
                <td><span>Last Update</span></td>
                <td><span *ngIf="device.extensions.last_update " class="label"
                          [pTooltip]="device.extensions.last_update.value "
                          [tooltipDisabled]=" device.extensions.last_update.value && device.extensions.last_update.value.length <= 28"
                          tooltipPosition="top"
                          hideDelay="100"> {{device.extensions.last_update.value ? (device.extensions.last_update.value * 1000 | date:'dd.MM.yyy, HH:mm:ss') : "N/A" }}</span>
                </td>
            </tr>
            <tr>
                <td><span>Last Schedule</span></td>
                <td><span *ngIf="device.extensions.last_schedule_update " class="label"
                          [pTooltip]="device.extensions.last_schedule_update.value "
                          [tooltipDisabled]=" device.extensions.last_schedule_update.value && device.extensions.last_schedule_update.value.length <= 28"
                          tooltipPosition="top" hideDelay="100">{{device.extensions.last_schedule_update.value ? (device.extensions.last_schedule_update.value * 1000 | date:'dd.MM.yyy, HH:mm:ss') : "N/A" }}</span>
                </td>
            </tr>

        </table>
    </mat-card-content>

</mat-card>
