<div pTooltip="No data points selected." showDelay="1000" hideDelay="500"
     [tooltipDisabled]="selectedDataPoints.length">
    <button pButton class="p-button-raised p-button-text cbms-button" (click)="deleteSelectedDatapointsMenu.show($event)"
            [disabled]="!selectedDataPoints.length || inProgress" pTooltip="Delete selected datapoints"
            showDelay="1000" hideDelay="500">
        <mat-icon svgIcon="delete"></mat-icon>
        <span class="table-caption-text">Delete All</span>
    </button>
</div>

<p-overlayPanel #deleteSelectedDatapointsMenu appendTo="body" styleClass="cbms-mid-size">
    <p>
        By removing these datapoints, polling for the selected points will be stopped, and any user added data such as descriptions and tags will be deleted.
        Please confirm you want to continue with the removal of <strong>{{selectedDataPoints.length}}</strong> datapoints.
    </p>
    <hr>
    <div class="cbms-overlay-confirmation-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="deleteSelectedDatapoints(); deleteSelectedDatapointsMenu.hide();">Confirm
        </button>
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="deleteSelectedDatapointsMenu.hide()">Cancel</button>
    </div>
</p-overlayPanel>
