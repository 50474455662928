<div class="content">

    <div class="cbms-spinner-wrapper" *ngIf="gettingSchedule">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>
    <div *ngIf="!gettingSchedule">
        <div class="grid">
            <div *ngIf="[COPY_SCHEDULE,COPY_FROM_TWO_TEMPLATES,COPY_WEEKLY_SCHEDULE_TEMPLATE].includes(operationType)"
                [ngClass]="{'col-12':COPY_WEEKLY_SCHEDULE_TEMPLATE === operationType ,
                             'col-6':COPY_WEEKLY_SCHEDULE_TEMPLATE !== operationType}">
                <h3>Copied schedule name: {{copySource.objectName}}</h3>
                <p>Copied Weekly Schedule</p>
                <cbms-schedule-calendar [calendarHeight]="350"
                    [scheduleData]="weeklyScheduleObject.weeklyScheduleWithInterval">
                </cbms-schedule-calendar>
            </div>
            <div *ngIf="[COPY_SCHEDULE,COPY_FROM_TWO_TEMPLATES,COPY_EXCEPTION_SCHEDULE_TEMPLATE].includes( operationType)"
                [ngClass]="{'col-12': COPY_EXCEPTION_SCHEDULE_TEMPLATE === operationType,
                             'col-6': COPY_EXCEPTION_SCHEDULE_TEMPLATE !== operationType}">
                <h3
                    *ngIf="[COPY_FROM_TWO_TEMPLATES,COPY_EXCEPTION_SCHEDULE_TEMPLATE].includes(operationType); else hideExceptionTitle">
                    Copied schedule name: {{secondCopySource.objectName}}</h3>
                <ng-template #hideExceptionTitle>
                    <h3>&nbsp;</h3>
                </ng-template>
                <p>Copied Exception Schedule</p>
                <div class="margin_top_bottom_10px">
                    <cbms-exception-schedule-table [exceptionScheduleInput]="exceptionSchedule"
                        [scrollHeight]="'319px'"></cbms-exception-schedule-table>
                </div>

            </div>
        </div>
    </div>

    <p-table class="cbms-generic-table-style" [value]="dataPoints" [columns]="cols" [resizableColumns]="true"
        [scrollable]="true" columnResizeMode="expand" [scrollHeight]="'180px'">

        <ng-template pTemplate="caption" *ngIf="[COPY_EXCEPTION_SCHEDULE_TEMPLATE].includes( operationType)">
            <div class="grid" style="text-align:left">
                <div class="col-6">
                    <div class="cbms-inline-flex">
                        <span class="caption-label">Writing Options:</span>
                        <p-radioButton name="replaceEvents" class="cbms-margin-left" value="replace" [disabled]="!exceptionScheduleCheckBox"
                            label="Replace existing events" [(ngModel)]="writingOptions"></p-radioButton>
                        <p-radioButton name="addEvents" class="cbms-margin-left" value="append" [disabled]="!exceptionScheduleCheckBox"
                            label="Add to existing events" [(ngModel)]="writingOptions"></p-radioButton>
                    </div>
                </div>
            </div>
        </ng-template>    

        <ng-template pTemplate="caption" *ngIf="[COPY_SCHEDULE, COPY_FROM_TWO_TEMPLATES].includes( operationType)">
            <div class="grid" style="text-align:left">
                <div class="col-6">
                    <p-checkbox class="right-margin" label="Write Weekly Schedule" [(ngModel)]="weeklyScheduleCheckBox"
                        binary="true"></p-checkbox>
                </div>
                <div class="col-6 cbms-label-small-text">
                    <p-checkbox class="right-margin" label="Write Exceptions (Events) Schedule"
                        [(ngModel)]="exceptionScheduleCheckBox" binary="true"></p-checkbox>

                    <div class="cbms-inline-flex cbms-margin-left">
                        <span class="caption-label">Writing Options:</span>
                        <p-radioButton name="replaceEvents" class="cbms-margin-left" value="replace" [disabled]="!exceptionScheduleCheckBox"
                            label="Replace existing events" [(ngModel)]="writingOptions"></p-radioButton>
                        <p-radioButton name="addEvents" class="cbms-margin-left" value="append" [disabled]="!exceptionScheduleCheckBox"
                            label="Add to existing events" [(ngModel)]="writingOptions"></p-radioButton>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="cbms-text-align-left" *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [pSortableColumnDisabled]="!col.sortable" pResizableColumn>
                    {{col.header}}
                    <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataPoint>
            <tr>
                <td class="ui-resizable-column">{{dataPoint.siteName}}</td>
                <td class="ui-resizable-column">{{dataPoint.deviceName}} ({{dataPoint.device.id}})</td>
                <td class="ui-resizable-column">{{dataPoint.objectName}}</td>
                <td class="ui-resizable-column">{{dataPoint.description}}</td>
                <td class="ui-resizable-column">{{dataPoint.customName}}</td>
                <td class="ui-resizable-column">
                    <div class="cbms-api-response-feedback" *ngIf="requestDone && weeklyScheduleCheckBox">
                        <div class="cbms-api-response-feedback__message">
                            <div class="cbms-api-response-feedback__icon">
                                <mat-icon
                                        [ngClass]="getResultByIdAndType(dataPoint.id,weeklyscheduletype).success?'cbms-green-color':'cbms-red-color'"
                                        [svgIcon]="getResultByIdAndType(dataPoint.id,weeklyscheduletype).success?'success':'failure'">
                                </mat-icon>
                            </div>
                            <span>{{getResultByIdAndType(dataPoint.id, weeklyscheduletype).success ? 'Weekly schedule: success.' : 'Weekly schedule: ' +
                                getResultByIdAndType(dataPoint.id, weeklyscheduletype).result.message}}
                            </span>
                        </div>
                    </div>
                    <div class="cbms-api-response-feedback" *ngIf="requestDone && exceptionScheduleCheckBox">
                        <div class="cbms-api-response-feedback__message">
                            <div class="cbms-api-response-feedback__icon">
                                <mat-icon
                                        [ngClass]="getResultByIdAndType(dataPoint.id,exceptionscheduletype).success?'cbms-green-color':'cbms-red-color'"
                                        [svgIcon]="getResultByIdAndType(dataPoint.id,exceptionscheduletype).success?'success':'failure'">
                                </mat-icon>
                            </div>
                            <span>{{getResultByIdAndType(dataPoint.id, exceptionscheduletype).success ? 'Exception schedule: success.' : 'Exception schedule: ' +
                                getResultByIdAndType(dataPoint.id, exceptionscheduletype).result.message}}
                            </span>
                        </div>
                    </div>
                    <mat-spinner *ngIf="isLoading" [diameter]="20" class="cbms-spinner"></mat-spinner>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
        [disabled]="!weeklyScheduleCheckBox && !exceptionScheduleCheckBox"
        pTooltip="Copy selected schedule to all objects in the list" showDelay="1000" hideDelay="500"
        (click)="computeAndSend()">Copy</button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>
