<div class="content">
    <div class="cbms-spinner-wrapper" *ngIf="isLoading || isLoadingDataPoints">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <p-table class="cbms-generic-table-style" [value]="dataPoints" [columns]="cols" [resizableColumns]="true"
        [scrollable]="true" (onColResize)="onColResize()" [scrollHeight]="'500px'" columnResizeMode="expand"
        stateStorage="session" stateKey="{{tableName}}-session" *ngIf="!isLoading && !isLoadingDataPoints">
        <ng-template pTemplate="caption">
            <div class="caption-wrapper">
                <div class="flex-item">
                    <mat-icon svgIcon="tags" class="cbms-margin-auto"></mat-icon>
                    <span class="cbms-margin-auto cbms-small-margin-left">Add tags: </span>
                    <input class="input cbms-margin-left" pInputText minlength="3" type="text"
                        placeholder="New tag (min 3 chars)" [(ngModel)]="newTag">
                    <p-multiSelect #systemTagsRef class="cbms-small-margin-left min-width500" [options]="systemTags"
                        [(ngModel)]="tagsToAdd" [filter]="true" filterBy="name" filterPlaceHolder="Tags"
                        selectedItemsLabel="{0} Tags selected" maxSelectedLabels="1" placeholder="Tags" dataKey="id"
                        [virtualScroll]="true" virtualScrollItemSize="30" optionLabel="name">
                        <p-footer>
                            <hr>
                            <div class="filter-footer">
                                <span *ngIf="systemTagsRef && systemTagsRef._filteredOptions">showing
                                    {{systemTagsRef._filteredOptions.length}} of {{systemTags.length}} Tags</span>
                                <span *ngIf="systemTagsRef && !systemTagsRef._filteredOptions">showing
                                    {{systemTags.length}} of {{systemTags.length}} Tags</span>
                            </div>
                        </p-footer>
                    </p-multiSelect>

                    <button pButton class="p-button-raised p-button-text cbms-button button cbms-small-margin-left" (click)="addTags()">
                        <mat-icon class="button-icon" svgIcon="plus"></mat-icon>
                        <span class="cbms-small-margin-left">Add</span>
                    </button>
                </div>

                <div class="cbms-margin-left flex-item">
                    <mat-icon svgIcon="tags" class="cbms-margin-auto cbms-margin-left"></mat-icon>
                    <span class="cbms-margin-auto cbms-small-margin-left">Remove Tags: </span>
                    <p-multiSelect #existingTagsRef class="cbms-margin-left min-width500" [options]="existingTags"
                        [(ngModel)]="tagsToRemove" [filter]="true" filterBy="name" filterPlaceHolder="Tags"
                        selectedItemsLabel="{0} Tags selected" maxSelectedLabels="1" [virtualScroll]="true"
                        virtualScrollItemSize="30" placeholder="Tags" optionLabel="name" dataKey="id">
                        <p-footer>
                            <hr>
                            <div class="filter-footer">
                                <span *ngIf="existingTagsRef && existingTagsRef._filteredOptions">showing
                                    {{existingTagsRef._filteredOptions.length}} of {{existingTags.length}} Tags</span>
                                <span *ngIf="existingTagsRef && !existingTagsRef._filteredOptions">showing
                                    {{existingTags.length}} of {{existingTags.length}} Tags</span>
                            </div>
                        </p-footer>
                    </p-multiSelect>
                    <button pButton class="p-button-raised p-button-text cbms-button button cbms-small-margin-left" (click)="removeTags()">
                        <mat-icon class="button-icon" svgIcon="minus"></mat-icon>
                        <span class="cbms-small-margin-left">Delete</span>
                    </button>
                </div>
                <button pButton class="p-button-raised p-button-text cbms-button button cbms-margin-left-auto" (click)="undo()">
                    <mat-icon class="button-icon" svgIcon="reset"></mat-icon>
                    <span class="cbms-small-margin-left">undo</span>
                </button>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="cbms-text-align-left" *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [pSortableColumnDisabled]="!col.sortable" pResizableColumn>
                    {{col.header}}
                    <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order">
                    </p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataPoint>
            <tr>
                <td class="ui-resizable-column">{{dataPoint.siteName}}</td>
                <td class="ui-resizable-column">{{dataPoint.deviceName}}</td>
                <td class="ui-resizable-column">{{dataPoint.objectName}}</td>
                <td class="ui-resizable-column">{{dataPoint.units}}</td>
                <td class="ui-resizable-column">
                    <div class="cbms-white-space-normal">
                        <ng-container *ngFor="let tag of dataPoint.tags">
                            <span class="cbms-chips"
                                [ngClass]="{'tag--highlighted': tag.isNew === true, 'tag--strikedthrough': tag.isDeleted === true }">{{tag.name}}</span>
                        </ng-container>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="footer" *ngIf="!isLoading">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" [disabled]="isLoading || noChangesOnTags()"
        (click)="save()" [tooltipDisabled]="!noChangesOnTags()" pTooltip="Button is disabled as nothing changed."
        showDelay="1000" hideDelay="500">Save</button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>