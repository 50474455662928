import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Setpoint } from './interfaces/setpoint.interface';
import { BackendService } from '../../services/backend/backend.service';
import { isError, isSucces, isWarning, isDisabled, Status } from './interfaces/status';
import { FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';
import { Subscription } from 'rxjs';
import { DataPoint } from 'src/app/models/data-point';
import _ from 'lodash';
import { NotificationService } from '../../services/notification/notification.service';
import { DATAPOINTS_OP } from 'src/app/config/constants';

const CHANGE = 'change';
const RESET = 'reset';
const AUTO = 'auto';
const SPECIFIC = 'specific';
const RUNDOWN = 'rundown';
const TIMED = 'Timed';
const PERMANENTLY = 'Permanently';

@Component({
    selector: 'cbms-setpoint-dialog',
    templateUrl: './setpoint.dialog.html',
    styleUrls: ['./setpoint.dialog.scss']
})
export class SetpointDialogComponent {
    public operatorValue: string; // ngmodel binds a string, impossible to number it
    public priority = 'AUTO';
    public isLoading: boolean = false;
    public isLoadingPresentValue: boolean = false;
    public dataPoints: Setpoint[] = [];
    public tableName: string = 'setpoint';
    public selectionValid: boolean = false;
    public prioritiesList: any[] = [
        { label: 'Smart select', value: 'AUTO' },
        { label: 'Relinquish Default', value: 'RELINQUISH_DEFAULT' },
        { label: '8 (Manual Operator)', value: 'PRIO_8' },
        { label: 9, value: 'PRIO_9' },
        { label: 10, value: 'PRIO_10' },
        { label: 11, value: 'PRIO_11' },
        { label: 12, value: 'PRIO_12' },
        { label: 13, value: 'PRIO_13' },
        { label: 14, value: 'PRIO_14' },
        { label: 15, value: 'PRIO_15' },
        { label: 16, value: 'PRIO_16' }
    ];

    public resetPrioritiesList: any[] = [
        { label: '8 (Manual Operator)', value: 'PRIO_8' },
        { label: 9, value: 'PRIO_9' },
        { label: 10, value: 'PRIO_10' },
        { label: 11, value: 'PRIO_11' },
        { label: 12, value: 'PRIO_12' },
        { label: 13, value: 'PRIO_13' },
        { label: 14, value: 'PRIO_14' },
        { label: 15, value: 'PRIO_15' },
        { label: 16, value: 'PRIO_16' }
    ];
    public priorityToReset = 'PRIO_8';

    public resetOptionsList: any[] = [
        { label: 'Set to Auto', value: AUTO, inactive: false },
        { label: 'Reset a specific priority', value: SPECIFIC, inactive: false },
        { label: 'Rundown Timers', value: RUNDOWN, inactive: false }
    ];

    public priorityTypeList: any[] = [
        { label: 'Permanently', value: PERMANENTLY },
        { label: 'Timed', value: TIMED },
    ];
    public priorityType: string = '';
    public rollbackTimer: string = '';

    public selectedResetOption = AUTO;
    public manageValueOption = CHANGE;
    public priorityDisabled = false;
    public operation = this.config.data.operation;

    private filterParams: FilterParams = {
        includesPresentValue: true,
        dataPointIdFilter: this.config.data.dataPoints
    };

    public cols: any[] = [
        { field: 'siteName', header: 'Site', sortable: true, width: '80px' },
        { field: 'deviceName', header: 'Device', sortable: true, width: '120px' },
        { field: 'name', header: 'Name', sortable: true, width: '100px' },
        { field: 'units', header: 'Unit', sortable: false, width: '70px' },
        { field: 'rollbackValue', header: 'Auto Setback Value', sortable: false, width: '110px' },
        { field: 'rollbackTimer', header: 'Auto Setback Timer', sortable: false, width: '110px' },
        { field: 'value', header: 'Current Value', sortable: true, width: '120px' },
        { field: 'newValue', header: 'New Value', sortable: true, width: '120px' },
        { field: 'updatedValue', header: 'Updated Value', sortable: true, width: '120px' },
        { field: 'result', header: 'Result', sortable: true, width: '100px' }
    ];

    public selectedUnitTypes: string[] = [];

    private getDataPointsSubscription: Subscription;

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private confirmationService: ConfirmationService,
        private backendService: BackendService,
        private notificationService: NotificationService) {

    }

    public ngOnInit() {
        this.isLoadingPresentValue = true;
        const pageSize = this.filterParams.dataPointIdFilter.length;

        const filterParams = Object.assign({}, this.filterParams, { includesPresentValue: false });
        this.backendService.getDataPointsSummary(filterParams, 0, pageSize).subscribe((response) => {
            this.selectionValid = this.isSelectionValid(response.content);

            if (this.selectionValid) {
                this.loadData(pageSize);
            } else {
                this.selectedUnitTypes = [...new Set(response.content.map((dataPoint) => dataPoint.units))];
                this.isLoadingPresentValue = false;
            }
        });
    }

    public close() {
        if (this.getDataPointsSubscription) {
            this.getDataPointsSubscription.unsubscribe();
        }
        this.ref.close();
    }

    public send() {

        if (this.operation === DATAPOINTS_OP.WRITE_TWIN_TO_DATAPOINT) {
            this.writeTwinToDatapoint();
            return;
        }

        if (this.manageValueOption === CHANGE) {
            this.confirmWriteData();
        }

        if (this.manageValueOption === RESET) {
            let priority = '';
            if (this.selectedResetOption === AUTO) {
                priority = 'PRIO_8';
            }
            if (this.selectedResetOption === SPECIFIC) {
                priority = this.priorityToReset;
            }
            this.confirmClearPriorities(priority);
        }
    }

    public writeValue() {
        this.isLoading = true;
        this.dataPoints.forEach(dataPoint => { dataPoint.updatedValue = null; });
        let dataToSend = _.cloneDeep(this.dataPoints);

        if (this.priorityType === TIMED) {
            if (this.rollbackTimer) {
                dataToSend.forEach(dataPoint => dataPoint.rollbackTimer = this.timeToMiliseconds(this.rollbackTimer));
            }
        } else {
            dataToSend.forEach(dataPoint => {
                dataPoint.rollbackTimer = null;
                // dataPoint.rundownTimer = true;
            });
        }

        this.backendService.patchSetpoint(dataToSend, this.priority).subscribe((response) => {
            let rollbackTimer = null;
            if (this.priorityType === TIMED && this.rollbackTimer) {
                rollbackTimer = response[0].rollbackTimer;
            }

            response.forEach(item => {
                this.parseAndSetDataPointFromItem(item);
            });

            this.backendService.getDataPointsDetails(this.filterParams).subscribe(response => {
                response.content.forEach(responseItem => {
                    this.dataPoints.filter(dataPoint => dataPoint.status === Status.SUCCESS && dataPoint.dataPointId === responseItem.id).forEach(dataPoint => {
                        dataPoint.updatedValue = responseItem.presentValue;
                        if (dataPoint.updatedValue !== dataPoint.newValue) {
                            dataPoint.status = Status.WARNING;
                        }
                        dataPoint.result.message = this.computeMessage(dataPoint);
                    });
                });
                this.setDisabledStatusForNullValues();

                this.dataPoints.forEach(dataPoint => {
                    dataPoint.rollbackTimer = rollbackTimer;
                });

                this.isLoading = false;
            });
        }, () => this.setErrorOnDataPointsStopLoader());
    }

    public writeTwinToDatapoint() {
        this.isLoading = true;
        this.backendService.writeTwinToDatapoint(this.dataPoints.map(dataPoint => dataPoint.dataPointId)).subscribe(response => {
            response.forEach(item => {
                this.dataPoints.forEach(dataPoint => {
                    if (dataPoint.dataPointId === item.dataPointId) {
                        dataPoint.status = item.success ? Status.SUCCESS : Status.ERROR;
                        dataPoint.result = item.result;
                        dataPoint.result.code = dataPoint.result.code.split('_').join(' ');
                        dataPoint.result.message = this.computeMessage(dataPoint);
                    }
                });
            });
            this.isLoading = false;

        }, (err) => {
            this.isLoading = false;
            this.notificationService.addErrorMessage('Write Twin to Datapoint', err);
        });
    }

    public set() {
        if (!this.operatorValue) {
            return;
        }
        this.dataPoints.forEach(setpoint => {
            if (setpoint.value === null) {
                return;
            }
            (<number>setpoint.newValue) = this.getOperatorValue();
        });
    }

    public add() {
        if (!this.operatorValue) {
            return;
        }
        this.dataPoints.forEach(setpoint => {
            if (setpoint.value === null) {
                return;
            }
            (<number>setpoint.newValue) += this.getOperatorValue();
        });
    }

    public sub() {
        if (!this.operatorValue) {
            return;
        }
        this.dataPoints.forEach(setpoint => {
            if (setpoint.value === null) {
                return;
            }
            if (<number>setpoint.newValue - this.getOperatorValue() < 0) {
                setpoint.newValue = 0;
            } else {
                (<number>setpoint.newValue) -= this.getOperatorValue();
            }
        });
    }

    public undo() {
        this.dataPoints.forEach(setpoint => {
            if (setpoint.value === null) {
                return;
            }
            setpoint.newValue = setpoint.value;
        });
    }

    public resetPriority(priority: string) {
        const resetPriority = true;
        this.isLoading = true;

        let dataToSend = _.cloneDeep(this.dataPoints);

        if (this.selectedResetOption === RUNDOWN) {
            dataToSend = dataToSend.filter(dataPoint => dataPoint.rollbackTimer).map(item => {
                item.rundownTimer = true;
                return item;
            });

            this.backendService.resetSetpoint(dataToSend).subscribe(response => {
                response.forEach(item => this.parseAndSetDataPointFromItem(item));

                response.forEach(updatedSetpoint => {
                    this.dataPoints.forEach(dataPoint => {
                        if (updatedSetpoint.dataPointId === dataPoint.dataPointId && updatedSetpoint.success) {
                            dataPoint.rollbackTimer = 0;
                            dataPoint.rollbackValue = null;
                            dataPoint.result.message = this.computeMessage(dataPoint);
                        }
                    });
                })

                this.isLoading = false;

            }, () => this.setErrorOnDataPointsStopLoader());

        } else {
            dataToSend = dataToSend.map(item => {
                item.rollbackTimer = null;
                return item;
            });

            this.backendService.patchSetpoint(dataToSend.map(setpoint => { setpoint.newValue = null; return setpoint }), priority, resetPriority).subscribe((response) => {
                response.forEach(item => this.parseAndSetDataPointFromItem(item));

                this.backendService.getDataPointsDetails(this.filterParams).subscribe(response => {
                    response.content.forEach(updatedSetpoint => {
                        this.dataPoints.forEach(dataPoint => {
                            if (dataPoint.dataPointId === updatedSetpoint.id) {
                                dataPoint.updatedValue = updatedSetpoint.presentValue;
                            }
                            dataPoint.result.message = this.computeMessage(dataPoint);
                            dataPoint.rollbackTimer = null;
                        })
                    });
                    this.isLoading = false;
                });

            }, () => this.setErrorOnDataPointsStopLoader());
        }
    }

    public onColResize() {
        let columns = document.getElementsByClassName('ui-sortable-column');
        Array.from(columns).forEach((column: HTMLElement) => {
            sessionStorage.setItem(`${this.tableName}-${column.innerText.replace(/\s/g, "")}`, `${column.clientWidth}px`);
        })
    }

    public isSucces(status: Status) {
        return isSucces(status);
    }

    public isWarning(status: Status) {
        return isWarning(status);
    }

    public isError(status: Status) {
        return isError(status);
    }

    public isDisabled(status: Status) {
        return isDisabled(status);
    }

    public confirmClearPriorities(priority: string) {
        let message = '';
        if (!this.isOnlyCCData(this.dataPoints) && !this.isOnlyWiseMeterData(this.dataPoints)) {
            message = ` Setpoint values on priority "${priority}" will be deleted for BacNet data points.<br />
                        Set to auto for WiseMetering points.<br /> 
                        Please note that if you have running timers for at least one of the data points you have chosen,
                        then all previous timers will be reset to allow for your new action.<br />
                        Do you want to continue?`;
        }
        if (this.isOnlyWiseMeterData(this.dataPoints)) {
            message = ` Setpoint values on priority "${priority}" will be deleted for BacNet data points.<br />
                        Set to auto for WiseMetering points.<br /> 
                        Please note that if you have running timers for at least one of the data points you have chosen,
                        then all previous timers will be reset to allow for your new action.<br />
                        Do you want to continue?`;
        }
        if (this.isOnlyCCData(this.dataPoints)) {
            message = `Setpoint values on priority "${priority}" will be deleted. <br /> 
                        Please note that if you have running timers for at least one of the data points you have chosen, <br />
                        then all previous timers will be reset to allow for your new action.<br />
                        Do you want to continue?`;
        }
        if (this.selectedResetOption === RUNDOWN) {
            message = `There are currently already running timers for at least one of the data points you have chosen, <br />
            please note that all previous timers will be reset to allow for your new action, are you sure you want to continue?`
        }

        this.confirmationService.confirm({
            message: message,
            accept: () => {
                this.resetPriority(priority);
            }
        });
    }

    public confirmWriteData() {
        let message = '';

        if (this.isOnlyCCData(this.dataPoints) || this.isOnlyWiseMeterData(this.dataPoints)) {
            if (this.dataPoints.filter(dataPoint => dataPoint.rollbackTimer).length) {
                message = `There are currently already running timers for at least one of the data points you have chosen, <br />
                            please note that all previous timers will be reset to allow for your new action, are you sure you want to continue?`;
                this.confirmationService.confirm({
                    message: message,
                    accept: () => {
                        this.writeValue();
                    }
                });
            } else {
                this.writeValue();
            }
            return;
        }

        if (!this.isOnlyCCData(this.dataPoints) && !this.isOnlyWiseMeterData(this.dataPoints)) {
            message = `The priority will affect only the BACnet data points. <br /> Do you want to continue?`;

            this.confirmationService.confirm({
                message: message,
                accept: () => {
                    this.writeValue();
                }
            });
        }
    }

    public refreshData() {
        this.isLoadingPresentValue = true;
        this.loadData(this.filterParams.dataPointIdFilter.length);
    }

    private loadData(pageSize: number) {
        if (this.getDataPointsSubscription) {
            this.getDataPointsSubscription.unsubscribe();
        }

        this.getDataPointsSubscription = this.backendService.getDataPointsDetails(this.filterParams, 0, pageSize).subscribe(response => {
            this.dataPoints = response.content.map((dataPoint) => {
                return {
                    dataPointId: dataPoint.id,
                    isCCSource: dataPoint.isCCSource,
                    isWiseMeterSource: dataPoint.isWiseMeterSource,
                    name: dataPoint.objectName,
                    siteName: dataPoint.site.name,
                    deviceName: dataPoint.device.name,
                    deviceId: dataPoint.device.deviceId,
                    units: dataPoint.units,
                    value: this.setSetpointValue(dataPoint),
                    newValue: this.setSetpointValue(dataPoint),
                    updatedValue: null,
                    result: null,
                    status: null,
                    twinDatapointCreationDate: dataPoint.twinDatapointCreationDate ? dataPoint.twinDatapointCreationDate : null,
                    twinDatapointValue: dataPoint.twinDatapointCreationDate ? dataPoint.twinDatapointValue : null,
                }
            });
            this.backendService.getDataPointsRollbackInfo(this.dataPoints.map(dataPoint => dataPoint.dataPointId)).subscribe((response: []) => {
                let shouldDisableRundowOption = true;
                response.forEach((item: any) => {
                    if (item.rollbackTimer) {
                        shouldDisableRundowOption = false;
                    }

                    this.dataPoints.forEach((dataPoint) => {
                        if (dataPoint.dataPointId === item.dataPointId) {
                            dataPoint.rollbackTimer = item.rollbackTimer;
                            dataPoint.rollbackValue = item.value;
                        }
                    });
                });
                
                if (shouldDisableRundowOption) {
                    this.resetOptionsList[this.resetOptionsList.length - 1].inactive = true;
                    this.selectedResetOption = AUTO;
                }

                if (this.isOnlyWiseMeterData(this.dataPoints)) {
                    this.priorityDisabled = true;
                    this.priority = 'PRIO_8';
                    this.selectedResetOption = AUTO;
                    this.priorityToReset = 'PRIO_8';
                    this.resetOptionsList[1].inactive = true;
                }

                this.isLoadingPresentValue = false;
            });
        });
    }

    private timeToMiliseconds(time: any) {
        return time.split(':')[0] * 3600000 + time.split(':')[1] * 60000;
    }

    private setSetpointValue(dataPoint) {
        return dataPoint.presentValue !== undefined ? dataPoint.presentValue : null
    }

    private computeMessage(dataPoint: Setpoint) {
        if (dataPoint.isWiseMeterSource && dataPoint.result.code === 'SUCCESS') {
            return 'Write succeeded';
        }

        const resultCtx = dataPoint.result.context;
        if (!resultCtx) {
            return dataPoint.result.message || '';
        }
        let message = `Write succeeded on property: ${resultCtx.property}`;
        if (resultCtx.priority) {
            message += `, with priority: ${resultCtx.priority}`;
        }

        if (Status.WARNING === dataPoint.status) {
            return `${(message)}. Setpoint writing succeeded, but change is not active. `;
        }
        return message;
    }

    private parseAndSetDataPointFromItem(item) {
        this.dataPoints.filter(datapoint => datapoint.dataPointId === item.dataPointId)
            .forEach(dataPoint => {
                dataPoint.status = item.success ? Status.SUCCESS : Status.ERROR;
                dataPoint.result = item.result;
                dataPoint.result.code = dataPoint.result.code.split('_').join(' ');
                dataPoint.rollbackValue = item.value;
            });
    }

    private setDisabledStatusForNullValues() {
        this.dataPoints.filter(dataPoint => dataPoint.value === null).forEach((dataPoint) => {
            dataPoint.status = Status.DISABLED;
            dataPoint.result = {
                code: '',
                message: 'Device not reachable! Writing command was skipped !'
            };
        });
    }

    private getOperatorValue(): number {
        return parseInt(this.operatorValue);
    }

    private setErrorOnDataPointsStopLoader() {
        this.dataPoints.forEach(dataPoint => dataPoint.status = Status.ERROR);
        this.isLoading = false;
    }

    private isOnlyCCData(dataPoints: Setpoint[]) {
        return dataPoints.every(dataPoint => dataPoint.isCCSource);
    }

    private isOnlyWiseMeterData(dataPoints: Setpoint[]) {
        return dataPoints.every(dataPoint => dataPoint.isWiseMeterSource);
    }

    private isSelectionValid(selectedDataPoints) {
        //allow only combination of one unit type plus 'noUnits' or undefined
        const filteredSelectedDP = selectedDataPoints.filter((dataPoint: DataPoint) => {
            return dataPoint.units && dataPoint.units !== 'noUnits'
        });
        return filteredSelectedDP.every((dataPoint: DataPoint) => dataPoint.units === filteredSelectedDP[0].units);
    }

}
