<ng-container>
    <div [pTooltip]="'No schedules selected.'"
         showDelay="1000"
         hideDelay="500"
         [tooltipDisabled]="selectedDataPoints.length">
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="showWriteTwinToScheduleDialog()"
                [disabled]="!selectedDataPoints.length" data-cy="write-twin-to-schedule">
            <mat-icon svgIcon="arrow_down"></mat-icon>
            Write Twin To Schedule
        </button>
    </div>
</ng-container>
