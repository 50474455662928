import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MappedDataPoint, DataPoint } from 'src/app/models/data-point';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-write-twin-to-schedule-action',
    templateUrl: './write-twin-to-schedule-action.component.html',
    styleUrls: ['./write-twin-to-schedule-action.component.scss']
})
export class WriteTwinToScheduleActionComponent implements OnInit {
    @Input()
    public selectedDataPoints: MappedDataPoint[];

    constructor(private dialogConfigService: DialogConfigService) { }

    ngOnInit(): void {
    }

    public showWriteTwinToScheduleDialog() {
        this.dialogConfigService.showWriteTwinToScheduleDialog(this.selectedDataPoints);
    }

}
