import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';


export enum EventType {

    WEEKLY_SCHEDULE_TEMPLATE_UNSELECTED='WEEKLY_SCHEDULE_TEMPLATE_UNSELECTED',
    WEEKLY_SCHEDULE_TEMPLATE_SELECTED='WEEKLY_SCHEDULE_TEMPLATE_SELECTED',
    EXCEPTION_SCHEDULE_TEMPLATE_UNSELECTED='EXCEPTION_SCHEDULE_TEMPLATE_UNSELECTED',
    EXCEPTION_SCHEDULE_TEMPLATE_SELECTED='EXCEPTION_SCHEDULE_TEMPLATE_SELECTED',
    SITE_CREATED='SITE_CREATED',
    SITE_EDITED='SITE_EDITED',
    NONE='NONE'
}


export interface Payload {
    id: string;
    name?: string;
    jsonData?: string;
}

export interface PayloadEvent {
    type: EventType;
    payload: Payload;
}

/**
 * Generic Event Service used to decouple the components and event based (Reactive style)
 * A user can emit to the Subject or subscribe to the Observable of this service using an generic Event that has a payload.
 */

@Injectable({
    providedIn: 'root'
})
export class EventNotificationService {

    private eventDispatcherSubject = new Subject<PayloadEvent>();
    private eventDispatcher$ = this.eventDispatcherSubject.asObservable();

    constructor() {
    }

    public getWeeklyScheduleTemplateSelected$() {
        return this.eventDispatcher$.pipe(filter(value => EventType.WEEKLY_SCHEDULE_TEMPLATE_SELECTED == value.type));
    }

    public getWeeklyScheduleTemplateUnSelected$() {
        return this.eventDispatcher$.pipe(filter(value => EventType.WEEKLY_SCHEDULE_TEMPLATE_UNSELECTED == value.type));
    }

    public getExceptionScheduleTemplateSelected$() {
        return this.eventDispatcher$.pipe(filter(value => EventType.EXCEPTION_SCHEDULE_TEMPLATE_SELECTED == value.type)); //TODO check if === works
    }

    public getExceptionScheduleTemplateUnSelected$() {
        return this.eventDispatcher$.pipe(filter(value => EventType.EXCEPTION_SCHEDULE_TEMPLATE_UNSELECTED == value.type));
    }

    public getSiteSaved$() {
        return this.eventDispatcher$.pipe(filter(value => EventType.SITE_CREATED == value.type));
    }

    /**
     * Generic Event Observable, all the events can be subscribed here.
     * Using this generic Observable, The user can create a custom logic in his class by combining different payload types, even more than one
     */
    public getEventDispatcher$() {
        return this.eventDispatcher$;
    }


    public emittEvent(event: PayloadEvent) {
        this.eventDispatcherSubject.next(event);
    }

    getSiteEdited$() {
        return this.eventDispatcher$.pipe(filter(value => EventType.SITE_EDITED == value.type));
    }
}
