import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SelectItem } from 'primeng/api';
import { uuid } from 'uuidv4';
import { FieldFilter, FieldFilterValues } from '../interfaces/filter-params.interface';
import { PrimeNGCorrectionService } from '../../../services/utils/primeNgCorrection.service';
import { ExternalSource, FieldList } from '../../../config/constants';

@Component({
    selector: 'cbms-field-filter',
    templateUrl: './field-filter.component.html',
    styleUrls: ['../filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FieldFilterComponent implements OnInit {
    @Input()
    selectedFieldFilter: FieldFilter;
    @Input()
    fieldFilterOptions: string[];

    @Output() valueChange = new EventEmitter();

    addFilterForm: FormGroup;
    submitted: boolean;
    fieldList: SelectItem[] = [
        { label: 'Select a field', value: '' }
    ];
    operatorsList: SelectItem[] = [];

    operatorsListForStringType: SelectItem[] = [
        { label: 'Select an operator', value: '' },
        { label: 'is', value: 'is', disabled: false },
        { label: 'contains', value: 'contains', disabled: false },
        { label: 'in', value: 'in', disabled: true },
        { label: 'within range', value: 'within_range', disabled: true },
        { label: 'out of range', value: 'out_of_range', disabled: true },
        { label: '>', value: '>', disabled: true },
        { label: '<', value: '<', disabled: true }
    ];

    operatorsListForID: SelectItem[] = [
        { label: 'Select an operator', value: '' },
        { label: 'is', value: 'is', disabled: false },
        { label: 'contains', value: 'contains', disabled: true },
        { label: 'in', value: 'in', disabled: true },
        { label: 'within range', value: 'within_range', disabled: true },
        { label: 'out of range', value: 'out_of_range', disabled: true },
        { label: '>', value: '>', disabled: true },
        { label: '<', value: '<', disabled: true }
    ];

    operatorsListForNumericType: SelectItem[] = [
        { label: 'is', value: 'is', disabled: true },
        { label: 'contains', value: 'contains', disabled: true },
        { label: 'in', value: 'in', disabled: true },
        { label: 'within range', value: 'within_range', disabled: false },
        { label: 'out of range', value: 'out_of_range', disabled: false },
        { label: '>', value: '>', disabled: false },
        { label: '<', value: '<', disabled: false }
    ];

    operatorsListForBoolean: SelectItem[] = [
        { label: 'is', value: 'is' },
    ];

    shouldShowInterval: boolean = false;
    currentlyEditingField: number = null;

    // public filterTypes: any[] = [
    //     { name: 'binaryInput', value: 'binaryInput', id: 1 },
    //     { name: 'analogInput', value: 'analogInput', id: 2 },
    //     { name: 'multiStateInput', value: 'multiStateInput', id: 3 },
    //     { name: 'binaryOutput', value: 'binaryOutput', id: 4 },
    //     { name: 'analogOutput', value: 'analogOutput', id: 5 },
    //     { name: 'multiStateOutput', value: 'multiStateOutput', id: 6 },
    //     { name: 'analogValue', value: 'analogValue', id: 7 },
    //     { name: 'binaryValue', value: 'binaryValue', id: 8 },
    //     { name: 'multiStateValue', value: 'multiStateValue', id: 9 },
    //     { name: 'schedule', value: 'schedule', id: 10 }
    // ];

    public filterTypes: any[] = [
        { name: 'Input', value: 'Input', id: 1 },
        { name: 'Output', value: 'Output', id: 2 },
        { name: 'Schedule', value: 'Schedule', id: 3 },
        { name: 'Logical', value: 'Logical', id: 4 }
    ];

    public signalTypeList: any[] = [
        {name: 'Analog', value: 'Analog', id: 1},
        {name: 'Binary', value: 'Binary', id: 2},
        {name: 'MultiState', value: 'MultiState', id: 3}];

    public sourceTypeList: any[] = [
        {name: ExternalSource.BACKNET, value: ExternalSource.BACKNET, id: 1},
        {name: ExternalSource.WISEMETER, value: ExternalSource.WISEMETER, id: 2}];

    filterFormTitle: string = 'Add Filter';

    booleanList: any[] = [
        { label: 'Select a value', value: '' },
        { label: 'True', value: true },
        { label: 'False', value: false },
    ]

    constructor(private formBuilder: FormBuilder, primeNgCorrectionService: PrimeNGCorrectionService) {
    }

    public ngOnInit(): void {
        this.fieldList.push(...this.generateFieldList());

        this.addFilterForm = this.formBuilder.group({
            fieldName: new FormControl('', Validators.required),
            fieldValue: new FormControl('', Validators.required),
            intervalStart: new FormControl({ disabled: true, value: '' }, [Validators.required]),
            intervalEnd: new FormControl({ disabled: true, value: '' }, [Validators.required]),
            selectedOperator: new FormControl('contains', Validators.required),
            selectedFilterTypes: new FormControl({ disabled: true, value: '' }, Validators.required),
            selectedBooleanOperator: new FormControl({ disabled: true, value: ''}, Validators.required)
        });

        this.operatorsList = [...this.operatorsListForStringType];

        this.fieldName.valueChanges.subscribe(value => {
            this.selectedFilterTypes.disable();
            this.selectedBooleanOperator.disable();
            if (value === FieldList.DATA_POINT_INTERNAL_ID || value === FieldList.WISEMETER_ID || value === FieldList.INSTANCE) {
                this.operatorsList = [...this.operatorsListForID];
                this.selectedOperator.setValue('is');
                return;
            }

            if ([FieldList.POLLED, FieldList.SITE_ASSIGNED].includes(value)) {
                this.operatorsList = [...this.operatorsListForBoolean];
                this.selectedOperator.setValue('is');
                this.fieldValue.disable();
                this.selectedBooleanOperator.enable();
                return;
            }

            if ([FieldList.TYPE, FieldList.SIGNAL_TYPE, FieldList.SOURCE].includes(value)) {
                this.selectedOperator.setValue('in');
                this.fieldValue.disable();
                this.selectedFilterTypes.enable();
                return;
            }

            if (value === 'Last recorded value') {
                this.showInterval();
                this.operatorsList = [...this.operatorsListForNumericType];
                this.selectedOperator.setValue('within_range');
            } else {
                this.hideInterval();
                this.operatorsList = [...this.operatorsListForStringType];
                this.selectedOperator.setValue('contains');
                this.shouldShowInterval = false;
            }
        });

        this.selectedOperator.valueChanges.subscribe(value => {
            if (value === '<' || value === '>') {
                this.hideInterval();
            }
            if (value === 'within_range' || value === 'out_of_range') {
                this.showInterval();
            }
        });
    }

    get fieldName() {
        return this.addFilterForm.get('fieldName') as FormControl;
    }
    get fieldValue() {
        return this.addFilterForm.get('fieldValue') as FormControl;
    }
    get selectedOperator() {
        return this.addFilterForm.get('selectedOperator') as FormControl;
    }
    get intervalStart() {
        return this.addFilterForm.get('intervalStart') as FormControl;
    }
    get intervalEnd() {
        return this.addFilterForm.get('intervalEnd') as FormControl;
    }
    get selectedFilterTypes() {
        return this.addFilterForm.get('selectedFilterTypes') as FormControl;
    }
    get selectedBooleanOperator() {
        return this.addFilterForm.get('selectedBooleanOperator') as FormControl;
    }

    public showInterval() {
        this.intervalStart.enable();
        this.intervalEnd.enable();
        this.fieldValue.disable();
        this.shouldShowInterval = true;
    }

    public hideInterval() {
        this.fieldValue.enable();
        this.intervalStart.disable();
        this.intervalEnd.disable();
        this.shouldShowInterval = false;
    }

    public saveFieldFilter(filterMenuOverlay: OverlayPanel) {
        let formValues = this.addFilterForm.value;
        let fieldValue = formValues.fieldValue;

        if (formValues.fieldName === 'Last recorded value') {
            fieldValue = parseFloat(formValues.fieldValue);
        }

        let newFilter: FieldFilterValues = {
            field: formValues.fieldName,
            value: fieldValue,
            operator: formValues.selectedOperator,
            selectedFilterTypes: [],
            intervalStart: formValues.intervalStart,
            intervalEnd: formValues.intervalEnd,
            selectedBooleanOperator: formValues.selectedBooleanOperator
        };

        if ([FieldList.TYPE, FieldList.SIGNAL_TYPE, FieldList.SOURCE].includes(formValues.fieldName)) {
            newFilter.value = formValues.selectedFilterTypes.map(type => type.value);
            newFilter.intervalStart = '';
            newFilter.intervalEnd = '';
            newFilter.selectedFilterTypes = formValues.selectedFilterTypes;
        }

        if ([FieldList.POLLED, FieldList.SITE_ASSIGNED].includes(formValues.fieldName)) {
            newFilter.intervalStart = '';
            newFilter.intervalEnd = '';
            newFilter.value = formValues.selectedBooleanOperator;
        }

        let key = this.currentlyEditingField || uuid();
        this.selectedFieldFilter[key] = newFilter;
        this.valueChange.emit(this.selectedFieldFilter);
        this.currentlyEditingField = null;
        filterMenuOverlay.hide();
    }

    public resetAddFilterForm() {
        this.currentlyEditingField = null;
        this.addFilterForm.reset();
    }

    public editFieldToFilter(event, field: any, filterMenuOverlay: OverlayPanel) {
        this.filterFormTitle = 'Edit Filter';
        filterMenuOverlay.hide();
        filterMenuOverlay.onHide.emit({closed: true});
        this.currentlyEditingField = field.key;

        setTimeout(() => {
            this.addFilterForm.patchValue({
                fieldName: field.value.field,
                fieldValue: field.value.value || '',
                intervalStart: field.value.intervalStart || '',
                intervalEnd: field.value.intervalEnd || '',
                selectedFilterTypes: field.value.selectedFilterTypes,
                selectedOperator: field.value.operator,
                selectedBooleanOperator: field.value.selectedBooleanOperator
            });
            filterMenuOverlay.show(event, event.target);
        });

    };

    public removeFieldFromFilter(field: any) {
        delete this.selectedFieldFilter[field.key];
        this.valueChange.emit(this.selectedFieldFilter);
    }

    public showFilterMenu(event, filterMenuOverlay: OverlayPanel) {
        this.filterFormTitle = 'Add Filter';
        this.resetAddFilterForm();
        filterMenuOverlay.hide();
        filterMenuOverlay.onHide.emit({closed: true});
        setTimeout(() => {
            filterMenuOverlay.show(event, event.target);
        });
    }

    public generateFieldList() {
        return this.fieldFilterOptions.map(fieldName => {
            return { label: fieldName, value: fieldName, disable: false };
        });
    }
}
