import { Component, OnInit, Input } from '@angular/core';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { MappedDataPoint } from 'src/app/models/data-point';

@Component({
	selector: 'cbms-show-schedule-action',
	templateUrl: './show-schedule-action.component.html',
	styleUrls: ['./show-schedule-action.component.scss']
})
export class ShowScheduleActionComponent implements OnInit {

	@Input()
	public selectedDataPoint: MappedDataPoint;
	
	@Input()
	public isTwin: boolean;

	constructor(private dialogConfigService: DialogConfigService) { }

	ngOnInit() {
	}

	public showScheduleDataDialog() {
		this.dialogConfigService.showScheduleDialog({dataPoint: this.selectedDataPoint, isTwin: this.isTwin});
	}
}
