import { Component, OnInit, OnDestroy } from '@angular/core';
import { of, concat } from 'rxjs';
import { concatMap, map, tap, takeUntil, finalize, delay, skip } from 'rxjs/operators';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Setpoint } from '../../dialogs/setpoint/interfaces/setpoint.interface';
import { BackendService } from '../../services/backend/backend.service';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';


@Component({
    selector: 'cbms-watch-data-points',
    templateUrl: './watch-data-points.component.html',
    styleUrls: ['./watch-data-points.component.scss']
})
export class WatchDataPointsComponent implements OnInit, OnDestroy {
    public pollingTimer = 0;
    public isLoadingData = false;
    public dataPoints: Setpoint[] = [];
    public gettingPresentValue = false;
    public tableName: string = 'watch';

    private unsubscribe$ = new Subject<void>();
    private load$ = new BehaviorSubject('');

    public pollingTimerOptions: any[] = [
        { label: 'Manual Update', value: 0 },
        { label: '5 seconds', value: 5000 },
        { label: '10 seconds', value: 10000 },
        { label: '30 seconds', value: 30000 },
    ];
    public cols: any[] = [
        { field: 'siteName', header: 'Site', sortable: true, width: '80px' },
        { field: 'deviceName', header: 'Device', sortable: true, width: '120px' },
        { field: 'name', header: 'Name', sortable: true, width: '100px' },
        { field: 'description', header: 'Description', sortable: true, width: '120px' },
        { field: 'units', header: 'Unit', sortable: true, width: '70px' },
        { field: 'value', header: 'Previous Value', sortable: true, width: '120px' },
        { field: 'updatedValue', header: 'Current Value', sortable: true, width: '120px' }
    ];

    private filterParams: FilterParams = {
        includesPresentValue: true,
        dataPointIdFilter: this.config.data
    };

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private backendService: BackendService) {

    }

    ngOnInit() {
        if (this.pollingTimer === 0) {
            this.isLoadingData = true;
            this.backendService.getDataPointsDetails(this.filterParams).subscribe(response => {
                this.dataPoints = response.content.map((dataPoint) => {
                    return {
                        dataPointId: dataPoint.id,
                        name: dataPoint.objectName,
                        siteName: dataPoint.site.name,
                        deviceName: dataPoint.device.name,
                        deviceId: dataPoint.device.deviceId,
                        description: dataPoint.description,
                        units: dataPoint.units,
                        value: null,
                        updatedValue: dataPoint.presentValue !== undefined ? dataPoint.presentValue : null,
                        highlight: false
                    }
                });
                this.isLoadingData = false;
            });
        } else {
            this.startPolling(this.pollingTimer);
        }
    }

    ngOnDestroy() {
        // Now let's also unsubscribe from the subject itself:
        this.unsubscribe$.unsubscribe();
        this.load$.unsubscribe();
      }

    public changePollingTimer(event) {
        if (event.value === 0) {
            this.unsubscribe$.next();
            return;
        }

        this.unsubscribe$.next();
        this.startPolling(this.pollingTimer);
    }

    public shouldShowUpdateBtn() {
        return this.pollingTimer === 0;
    }

    public getDataPointIds() {
        return this.config.data.map(item => item.id);
    }

    public close() {
        this.unsubscribe$.next();
        this.ref.close();
    }

    public updatePresentValue() {
        this.gettingPresentValue = true;

        this.backendService.getDataPointsDetails(this.filterParams).subscribe(response => {
            this.gettingPresentValue = false
            this.mapPresentValue(response);
        });
    }

    private mapPresentValue(response) {
        response.content.forEach(item => {
            this.dataPoints.forEach(dataPoint => {
                if (dataPoint.dataPointId === item.id) {
                    dataPoint.value = dataPoint.updatedValue;
                    dataPoint.updatedValue = item.presentValue;
                    dataPoint.highlight = dataPoint.value !== dataPoint.updatedValue;
                }
            })
        });
    }

    private startPolling(pollingTimer: number) {
        const dataPoints$ = this.backendService.getDataPointsDetails(this.filterParams);

        const whenToRefresh$ = of('').pipe(
            delay(pollingTimer),
            tap(_ => this.load$.next('')),
            skip(1)
        );

        const poll$ = concat(dataPoints$, whenToRefresh$);

        this.load$.pipe(
            concatMap(_ => {
                this.gettingPresentValue = true;
                return poll$;
            }),
            map((response) => {
                this.gettingPresentValue = false
                this.mapPresentValue(response);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

}
