import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cbms-policy-maker',
  templateUrl: './policy-maker.component.html',
  styleUrls: ['./policy-maker.component.scss']
})
export class PolicyMakerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
