import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataPoint, MappedDataPoint } from '../../../models/data-point';
import { DialogConfigService } from '../../../services/dialog-config/dialog-config.service';

@Component({
    selector: 'cbms-edit-tags-action',
    templateUrl: './edit-tags-action.component.html',
    styleUrls: ['./edit-tags-action.component.scss']
})
export class EditTagsActionComponent implements OnInit {

    @Input()
    public selectedDataPoints: MappedDataPoint[];

    @Input()
    isGlobalAction: boolean;

    @Output()
    public updatedDataPointsEvent = new EventEmitter<DataPoint[]>();

    constructor(private dialogConfigService: DialogConfigService) {

    }

    ngOnInit() {
    }

    public showTagManagementDialog(selectedDataPoints: MappedDataPoint[]) {
        const ref = this.dialogConfigService.showTagManagementDialog(selectedDataPoints);
        ref.onClose.subscribe((dataPoints: DataPoint[]) => {
            if (dataPoints) {
                this.updatedDataPointsEvent.emit(dataPoints);
            }
        });
    }

}
