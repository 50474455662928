<p-table class="cbms-generic-table-style" [columns]="cols" [value]="siteList" [lazy]="true" [scrollable]="true"
    scrollHeight="flex" (onLazyLoad)="loadSitesLazy($event)" [paginator]="true" [rows]="pageSize"
    [totalRecords]="totalRecords">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="flex: 0 0 50px; min-width: 50px;">
                <p-checkbox class="right-margin" [(ngModel)]="allSitesSelected" binary="true"
                    (click)="toggleSelectAllSites($event)"></p-checkbox>
            </th>
            <th style="flex: 0 0 240px; min-width: 240px;">
                Actions
            </th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <div class="cbms-disply-flex-row-centered">
                    <span>{{col.header}}</span>
                    <span>
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </span>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-site let-columns="columns">
        <tr>
            <td style="flex: 0 0 50px; min-width: 50px;">
                <p-checkbox [(ngModel)]="site.selected" binary="true" (click)="updateSelectedSite()">
                </p-checkbox>
            </td>
            <td style="flex: 0 0 240px; min-width: 240px;">
                <div class="cbms-display-flex-row">
                    <button pButton class="p-button-raised p-button-text cbms-row-button"
                            (click)="deleteMenu.show($event)"
                            [disabled]="!hasPermission(ROLE_PERMISSIONS.DELETE_SITE_BUTTON_SITE_TAB_CORE_APP)"
                            pTooltip="Delete Site" showDelay="1000" hideDelay="500">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                    <button pButton type="button" icon="pi pi-pencil" class="ui-button-info cbms-margin-left"
                            (click)="onEditSite(site)"
                            [disabled]="!hasPermission(ROLE_PERMISSIONS.EDIT_SITE_BUTTON_SITE_TAB_CORE_APP)"

                    ></button>

                    <p-fileUpload #datapointsFileUpload class="upload-button white cbms-margin-left" mode="basic"
                        name="{{site.name}}" pTooltip="Import Site's Data Model" chooseIcon="pi pi-upload"
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        chooseLabel="" maxFileSize="20000000" customUpload="true" [auto]="true"
                        [disabled]="importInProgress|| !hasPermission(ROLE_PERMISSIONS.IMPORT_SITE_DATA_MODEL_BUTTON_SITE_TAB_CORE_APP)"
                        (uploadHandler)="uploadDataPointsCSV($event, datapointsFileUpload)"></p-fileUpload>

                    <button pButton class="p-button-raised p-button-text cbms-row-button export-button cbms-margin-left"
                        (click)="exportSiteDatapoints(site)" pTooltip="Export Datapoints" showDelay="500"
                        hideDelay="500">
                        <i class="pi pi-download"></i>
                    </button>

                    <p-overlayPanel #deleteMenu appendTo="body">
                        <p>
                            Please confirm you want to continue with the removal<br>of the Site:
                            <strong>{{site.name}}</strong>.
                        </p>
                        <hr>
                        <div class="delete-action-buttons">
                            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" label="Confirm"
                                (click)="deleteSite(site.id); deleteMenu.hide();"></button>
                            <button pButton class="p-button-raised p-button-text cbms-button" (click)="deleteMenu.hide()">Cancel</button>
                        </div>
                    </p-overlayPanel>
                </div>
            </td>
            <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.uiField">
                    <ng-container *ngSwitchCase="'readOnly'">
                        <mat-icon *ngIf="site[col.uiField]" svgIcon="done"></mat-icon>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dashboardEnabled'">
                        <mat-icon *ngIf="site[col.uiField]" svgIcon="done"></mat-icon>
                    </ng-container>
                    <ng-container *ngSwitchCase="'tags'">
                        <div>
                            <ng-container *ngFor="let tag of site.tags">
                                <span class="cbms-chips">{{tag.name}}</span>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>{{site[col.uiField]}}
                    </ng-container>
                </ng-container>

            </td>
        </tr>
    </ng-template>
</p-table>
