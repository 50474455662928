<ng-container>
    <div [pTooltip]="'No data points selected.'"
         showDelay="1000"
         hideDelay="500"
         [tooltipDisabled]="selectedDataPoints.length">
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="showCreateTwinDialog()"
                [disabled]="!selectedDataPoints.length"
                data-cy="create-twin-from-schedule">
            <mat-icon svgIcon="arrow_up"></mat-icon>
            Create twin from schedule
        </button>
    </div>
</ng-container>
