<cbms-filter *ngIf="!shouldHideFilters" [config]="filterConfigObject" [tabName]="tabName"
    [fieldFilterOptions]="fieldFilterOptions"></cbms-filter>


<div class="cbms-title-wrapper cbms-align-center">
    <ng-container *ngIf="!isSelectedDatapointMode">
        <mat-icon class="cbms-title-icon" svgIcon="schedule"></mat-icon>
        <span class="cbms-title-text">Schedules</span>
        <span *ngIf="!isSelectedDatapointMode && totalRecords" class="cbms-margin-left cbms-margin-top10">({{totalRecords}})</span>
    </ng-container>

    <ng-container *ngIf="isSelectedDatapointMode">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__red-bg" (click)="showAllDP()">
            <i class="pi pi-arrow-left"></i>&nbsp;&nbsp;
            <span class="table-caption-text">Show All</span>
        </button>
    </ng-container>

    <div style="display: flex;flex-flow: column nowrap ">
        <mat-card *ngIf="selectedExceptionScheduleTemplate" class="margin-left-320-px">
            <div class="cbms-display-flex-row cbms-justify-content-flex-start cbms-align-center width-450">
                <cbms-show-exception-schedule-template-action [templateName]="selectedExceptionScheduleTemplate.name"
                    [templateId]="selectedExceptionScheduleTemplate.id" [isReadonly]="true">
                </cbms-show-exception-schedule-template-action>
                <cbms-schedule-template-select-action [templateType]="EXCEPTION_SCHEDULE" class="margin-left-16-px">
                </cbms-schedule-template-select-action>
                <div class="margin-left-16-px"> Selected exception schedule template: <span
                        class="cbms-font-weight-bold">{{selectedExceptionScheduleTemplate.name}}</span></div>
            </div>
        </mat-card>
        <br>
        <mat-card *ngIf="selectedWeeklyScheduleTemplate" class="margin-left-320-px">
            <div class="cbms-display-flex-row cbms-justify-content-flex-start cbms-align-center width-450">
                <cbms-show-template-action [templateName]="selectedWeeklyScheduleTemplate.name"
                    [templateId]="selectedWeeklyScheduleTemplate.id" [isReadOnly]="true">
                </cbms-show-template-action>
                <cbms-schedule-template-select-action [templateType]="WEEKLY_SCHEDULE" class="margin-left-16-px">
                </cbms-schedule-template-select-action>
                <div class="margin-left-16-px"> Selected weekly schedule template: <span
                        class="cbms-font-weight-bold">{{selectedWeeklyScheduleTemplate.name}}</span></div>
            </div>
        </mat-card>
    </div>

    <ng-container *ngIf="dataPoints">
        <button data-cy="show-weekly-templates-btn" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto" 
            (click)="showWeeklyTemplates()">Weekly Templates</button>
        <button data-cy="show-exception-templates-btn" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left" 
            (click)="showExceptionTemplates()">Exception Templates</button>
        <button class="no-bg-btn cbms-margin-left" (click)="showSelectedDP()" data-cy="cart">
            <i class="pi pi-shopping-cart">
                <span [ngClass]="{'cbms-blink': cartUpdated}"
                    (animationend)="cartUpdated=false">{{nrOfSelectedDataPoints || ''}}</span>
            </i>
        </button>
    </ng-container>
</div>

<div class="table-wrapper">
    <cbms-data-points-table *ngIf="dataPoints" [dataPoints]="dataPoints" [totalRecords]="totalRecords"
        [allDPSelected]="allDPSelected" [selectedDataPointList]="selectedDataPointList"
        [isSelectedDatapointMode]="isSelectedDatapointMode" (lazyLoadEvent)="lazyLoadEvent($event)"
        (selectedDataPointEmitter)="updateSelectedDataPoints($event)"
        (toggleAllDataPointsSelectionEmitter)='toggleAllDataPointsSelection($event)' [columnsConfig]="columnsConfig"
        [actionConfig]="actionConfig" [tabName]="'schedules'">
    </cbms-data-points-table>

    <div class="cbms-spinner-wrapper" *ngIf="dataPoints == null">
        <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
    </div>
</div>


<p-sidebar [(visible)]="showSidebar" (onHide)="hide()" position="right" [style]="{width:'80em'}">
    <div class="cbms-sidebar-header">
        <h2 *ngIf="showWeekly">Weekly Schedule Templates</h2>
        <h2 *ngIf="showException">Exception Schedule Templates</h2>
    </div>

    <div class="sidebar-body">
        <cbms-schedule-templates *ngIf="showWeekly"></cbms-schedule-templates>
        <cbms-exception-schedule-templates *ngIf="showException"></cbms-exception-schedule-templates>
    </div>
</p-sidebar>