<cbms-filter [config]="filterConfigObject" [tabName]="tabName"></cbms-filter>

<div class="cbms-title-wrapper-baseline">
    <mat-icon class="cbms-title-icon" svgIcon="gateways"></mat-icon>
    <span class="cbms-title-text">Gateways</span>
    <span *ngIf="gateways && !selectedGatewayIdList.length" class="cbms-margin-left-auto">{{gateways.length}}
        Gateways</span>
    <span *ngIf="gateways && selectedGatewayIdList.length"
        class="cbms-margin-left-auto">{{selectedGatewayIdList.length}} of {{gateways.length}}
        Gateways selected</span>
</div>
<div class="cbms-title-wrapper-flex-end">
    <p-checkbox *ngIf="gateways && gateways.length" class="right-margin" binary="true" [(ngModel)]="allGatewaysSelected"
        label="Select All" (onChange)="toggleSelectAllGateways()"></p-checkbox>
    <div class="cbms-margin-left-auto" pTooltip="No gateways selected, or more than 100 gateways selected"
        showDelay="500" hideDelay="500"
        [tooltipDisabled]="selectedGatewayIdList.length && selectedGatewayIdList.length < MAX_SELECTED_GATEWAYS_COUNT">
        <button pButton class="p-button-raised p-button-text cbms-button icon-button"
            [disabled]="(!selectedGatewayIdList.length || selectedGatewayIdList.length > MAX_SELECTED_GATEWAYS_COUNT) || autoOnboardInProgress "
            (click)="openPollingGroupSelection()">Auto Onboard
        </button>
    </div>
    <button data-cy="delete-all-button" pButton class="p-button-raised p-button-text cbms-button icon-button cbms-margin-left"
        [disabled]="!selectedGatewayIdList.length" (click)="deleteSelectedGatewaysMenu.show($event);">
        <mat-icon svgIcon="delete"></mat-icon>
        Delete All
    </button>

    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left" (click)="exportGatewayExcel()" data-cy="delete-all">
        <i class="pi pi-download cbms-margin-right-05-rem"></i>
        Export GW Excel
    </button>
    <p-fileUpload #gwFileUpload class="upload-button cbms-margin-left" mode="basic" name="myCSVfile"
        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        chooseIcon="pi pi-upload"
        chooseLabel="Import GW Excel" maxFileSize="1000000" customUpload="true" [auto]="true"
        [disabled]="requestInProgress" (uploadHandler)="uploadGatewayExcel($event, gwFileUpload)"></p-fileUpload>

    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left" (click)="openAddGateway()">New Gateway</button>
</div>

<div class="cbms-spinner-wrapper" *ngIf="isLoadingGateways || autoOnboardInProgress || requestInProgress">
    <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
</div>

<div class="cbms-no-devices-wrapper" *ngIf="gateways && gateways.length === 0">
    <span>
        No gateways found
    </span>
</div>

<div class="gateways-wrapper" *ngIf="gateways && gateways.length">
    <mat-accordion class="gateway-tab">
        <mat-expansion-panel *ngFor="let gateway of gateways" (opened)="openGateway(gateway)" (closed)="closeGateway()"
            [expanded]="expandOnLoad(gateway)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p-checkbox class="gateway-checkbox" binary="true" [(ngModel)]="gateway.selected"
                        (onChange)="updateSelectedGatewaysList(gateway)" (click)="stopEventPropagation($event)">
                    </p-checkbox>
                    <mat-icon class="header-icon"
                        [svgIcon]="gateway.virtual ? 'cloud': CC === gateway.source?'gateways':'developer_board'">
                    </mat-icon>
                    <mat-icon *ngIf="gateway.online" class="header-icon cbms-green-color" svgIcon="wifi_on"></mat-icon>
                    <mat-icon *ngIf="!gateway.online" class="header-icon cbms-red-color" svgIcon="wifi_off"></mat-icon>
                    <span class="panel-title">{{gateway.name}}</span>
                </mat-panel-title>
                <mat-panel-description class="description">
                    <span>{{gateway.hardwareId}}</span>
                    <span>Last contact: {{gateway.lastContact | date:'dd.MM.yyy, HH:mm:ss'}}</span>
                    <span *ngIf="gateway.computedIpAddresses">Gateway IP address: {{gateway.computedIpAddresses}}</span>
                    <span *ngIf="gateway.virtualAddress">BBMD address: {{gateway.virtualAddress}}</span>
                    <ng-container
                        *ngIf="gateway.source === BACKNET && gateway.online && !gateway.virtual then hasMaintenanceButton   else hasNoMaintenanceButton">
                    </ng-container>
                    <ng-template #hasMaintenanceButton>
                        <button pButton class="p-button-raised p-button-text cbms-button icon-button auto-margin"
                            (click)="$event.stopPropagation(); showGatewayMaintenanceDialog(gateway)">
                            <mat-icon svgIcon="maintenance"></mat-icon>
                            Maintenance
                        </button>
                        <button pButton class="p-button-raised p-button-text cbms-button icon-button"
                            (click)="$event.stopPropagation(); deleteMenu.show($event);">
                            <mat-icon svgIcon="delete"></mat-icon>
                            Delete
                        </button>
                    </ng-template>

                    <ng-template #hasNoMaintenanceButton>
                        <button pButton class="p-button-raised p-button-text cbms-button icon-button auto-margin"
                            (click)="$event.stopPropagation(); deleteMenu.show($event);">
                            <mat-icon svgIcon="delete"></mat-icon>
                            Delete
                        </button>
                    </ng-template>
                    <button pButton type="button" icon="pi pi-pencil" class="icon-button ui-button-info"
                        (click)="openEditGateway($event, gateway)"></button>
                    <p-overlayPanel #deleteMenu appendTo="body">
                        <ng-container *ngIf="!gateway.online">
                            <p>
                                <strong>NOTE: The gateway is offline!</strong>
                                <br>Although the device and data point information of the site will be
                                <br>removed from the system, the gateway will continue to acquire and
                                <br>to attempt to send data until disconnected from its power supply.
                            </p>
                        </ng-container>
                        <ng-container *ngIf="gateway.online">
                            <p>
                                <strong>All device, data point configurations and<br>TS-data will be removed as
                                    well.</strong>
                            </p>
                        </ng-container>
                        <br>
                        <p>
                            Please confirm you want to continue with the removal<br>of gateway
                            <strong>{{gateway.name}}</strong>.
                        </p>
                        <hr>
                        <div class="delete-action-buttons">
                            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                                (click)="deleteGateway(gateway); deleteMenu.hide();">Confirm</button>
                            <button pButton class="p-button-raised p-button-text cbms-button" (click)="deleteMenu.hide()">Cancel</button>
                        </div>
                    </p-overlayPanel>

                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="cbms-spinner-wrapper" *ngIf="gateway.devices == null">
                <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
            </div>

            <div class="card-wrapper" *ngIf="gateway.devices != null && gateway.devices.length == 0">
                <cbms-option-card [gateway]="gateway"></cbms-option-card>
            </div>

            <div class="card-wrapper" *ngIf="gateway.devices != null && gateway.devices.length > 0">
                <cbms-option-card [gateway]="gateway"></cbms-option-card>
                <cbms-device-card *ngFor="let device of gateway.devices" [device]="device">
                </cbms-device-card>
            </div>

        </mat-expansion-panel>
    </mat-accordion>

</div>

<p-paginator [rows]="paginatorPageSize" [totalRecords]="paginatorTotalRecords" (onPageChange)="onPageSelected($event)"
             [first]="paginatorFirstRecordIndex"></p-paginator>

<p-sidebar [(visible)]="showAddGatewaySidebar" position="right" styleClass="p-sidebar-md"
    (onHide)="cancelGateway(gatewayForm)">
    <div class="cbms-sidebar-header">
        <h2>{{sidebarTitle}}</h2>
    </div>

    <div class="sidebar-body">
        <form (ngSubmit)="submitForm(gatewayForm)" #gatewayForm="ngForm">
            <div *ngIf="!newCreatedGateway">
                <div class="grid cbms-align-center cbms-margin-bottom05">
                    <div class="col-4">
                        <span>Gateway Name</span>
                    </div>
                    <div class="col-8">
                        <input class="cbms-input__mid-size" pInputText minlength="3" name="gatewayName" required
                            [(ngModel)]="gatewayName">*
                    </div>
                </div>
                <div class="grid cbms-align-center cbms-margin-bottom05">
                    <div class="col-4">
                        <span>Hardware Id<br />(12 random chars)</span>
                    </div>
                    <div class="col-8">
                        <input class="cbms-input__mid-size" pInputText minlength="12" maxlength="12"
                            name="gatewayHardwareId" [(ngModel)]="gatewayHardwareId" [disabled]="isEditing">
                    </div>
                </div>
                <div class="grid cbms-align-center cbms-margin-bottom05">
                    <div class="col-4">
                        <span>Gateway Type</span>
                    </div>
                    <div class="col-8">
                        <p-dropdown [options]="gwTypeList" name="gatewayType" [(ngModel)]="selectedGwType"
                            [required]="true" [disabled]="isEditing" [styleClass]="'cbms-dropdown__mid-size'"
                            placeholder="Select Gateway Type"></p-dropdown>*
                    </div>
                </div>
                <div *ngIf=" BACKNET === selectedGwType " class="grid cbms-align-center cbms-margin-bottom05">
                    <div class="col-4">
                        <span>Virtual type</span>
                    </div>
                    <div class="col-8">
                        <p-checkbox name="virtualType" [(ngModel)]="virtualType" binary="true" [disabled]="isEditing">
                        </p-checkbox>
                    </div>
                </div>
                <ng-container *ngIf="virtualType && BACKNET === selectedGwType">
                    <div class="grid cbms-align-center cbms-margin-bottom05">
                        <div class="col-4">
                            <span>VPN</span>
                        </div>
                        <div class="col-8">
                            <p-dropdown [options]="vpnList" name="vpnId" [(ngModel)]="vpnId" [required]="true"
                                optionLabel="description" optionValue="code" [styleClass]="'cbms-dropdown__mid-size'"
                                placeholder="Select VPN"></p-dropdown>*
                        </div>
                    </div>
                    <div class="grid cbms-align-center cbms-margin-bottom05">
                        <div class="col-4">
                            <span>IP Address /<br /> Domain name of <br />the BBMD device</span>
                        </div>
                        <div class="col-8">
                            <input class="cbms-input__mid-size" pInputText minlength="3" name="bbmdIp" required
                                [(ngModel)]="bbmdIp">*
                        </div>
                    </div>
                    <div class="grid cbms-align-center cbms-margin-bottom05"
                        *ngFor="let dnsNameserver of dnsNameservers; let i = index; trackBy: trackByFn">
                        <div class="col-4">
                            <span>DNS Nameserver</span>
                        </div>
                        <div class="col-7 cbms-vertical-align">
                            <input class="cbms-input__mid-size" pInputText minlength="3" name="dnsNameserver_{{i}}"
                                required [(ngModel)]="dnsNameservers[i]">
                            <mat-icon svgIcon="delete" (click)="removeDnsField(i)"></mat-icon>
                        </div>
                    </div>
                    <div class="grid cbms-align-center cbms-margin-bottom05">
                        <div class="col-4"></div>
                        <div class="col-8">
                            <button type="button" *ngIf="dnsNameservers.length < 3" pButton class="p-button-raised p-button-text cbms-button margin-bottom"
                                 (click)="addDnsField()">
                                <mat-icon class="button-icon" svgIcon="plus"></mat-icon>
                                <span class="cbms-small-margin-left">Add new DNS Nameserver</span>
                            </button>
                        </div>
                    </div>
                    <div class="grid cbms-align-center cbms-margin-bottom05">
                        <div class="col-4">
                            <span>Time Zone</span>
                        </div>
                        <div class="col-8">
                            <p-dropdown [options]="timezoneList" name="timezone" [(ngModel)]="timezone" [required]="true"
                                optionLabel="label" optionValue="value" [styleClass]="'cbms-dropdown__mid-size'"
                                placeholder="Select Time Zone"></p-dropdown>*
                        </div>
                    </div>
                </ng-container>
            </div>

            <div *ngIf="newCreatedGateway && !isLoading" class="gateway-info">
                <p>Connection string:</p>
                <pre>{{newCreatedGateway.connectionString}}</pre>
                <p>Gateway Hardware Id: <strong>{{newCreatedGateway.hardwareId}}</strong></p>
                <p-message severity="info"
                    text="Attention: please make sure you copied the connection string before closing the form">
                </p-message>
            </div>

            <div *ngIf="isLoading" class="p-inputgroup">
                <mat-spinner [diameter]="20" class="cbms-spinner"></mat-spinner>
            </div>

            <div class="grid cbms-align-center cbms-margin-bottom05">
                <div class="col-12">
                    <button type="submit" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" 
                        [disabled]="!gatewayForm.form.valid || isLoading || newCreatedGateway">Save</button>
                    <button type="button" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" 
                        (click)="cancelGateway(gatewayForm)">Close</button>
                </div>
            </div>

        </form>
    </div>
</p-sidebar>

<p-sidebar [(visible)]="isPollingGroupSelectionSidebarVisible" position="right" styleClass="p-sidebar-md"
    (onHide)="cancelPollingGroupSelection()">
    <div class="cbms-sidebar-header">
        <h2>Polling Group Selection</h2>
    </div>

    <div class="grid">
        <div class="col-12">
            <h3>Global Presets</h3>
        </div>
    </div>
    <div class="grid" *ngFor="let item of globalPresetsUIList">
        <div class="col-4">
            <p>All {{item.label}} datapoints</p>
        </div>
        <div class="col-6">
            <p-checkbox class="right-margin" binary="true" [(ngModel)]="item.isChecked">
            </p-checkbox>
        </div>
    </div>

    <div class="grid">
        <div class="col-4">
            <p>Site assignment</p>
        </div>
        <div class="col-6">
            <p-checkbox class="right-margin" binary="true" [(ngModel)]="siteAssignment">
            </p-checkbox>
        </div>
    </div>

    <div class="grid">
        <div class="col-4">
            <p>Customer</p>
        </div>
        <div class="col-6">
            <p-dropdown class="min-width250" name="selectedCustomer" [required]="true" [options]="customerList"
                [(ngModel)]="selectedCustomer" placeholder="Select Customer" optionLabel="name" [disabled]="!siteAssignment"
                (onChange)="onCustomerChosen()">
            </p-dropdown>
        </div>
    </div>

    <div class="grid">
        <div class="col-4">
            <p>Site</p>
        </div>
        <div class="col-6">
            <p-dropdown class="min-width250" name="selectedCustomer" [required]="true" [options]="siteList"
                [(ngModel)]="selectedSite" placeholder="Select Site" optionLabel="name" [disabled]="!siteAssignment">
            </p-dropdown>
        </div>
    </div>

    <div class="grid cbms-margin-bottom20">
    </div>

    <button data-cy="apply-button" pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg" [disabled]="siteAssignment && !selectedSite"
        (click)="confirmGroupPollingAndStartAutoOnboarding()">Apply</button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" 
        (click)="cancelPollingGroupSelection()">Close</button>
</p-sidebar>


<p-overlayPanel #deleteSelectedGatewaysMenu appendTo="body">
    <p>
        <strong>All device and data point configurations will be removed.</strong>
    </p>
    <p>
        <strong>NOTE: If the gateway is offline:</strong>
        <br>Although the device and data point information of the site will be
        <br>removed from the system, the gateway will continue to acquire and
        <br>to attempt to send data until disconnected from its power supply.
    </p>
    <br>
    <p>
        Please confirm you want to continue with the removal<br>of
        <strong>{{this.selectedGatewayIdList.length}}</strong> gateways.
    </p>
    <hr>
    <div class="delete-action-buttons">
        <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
            (click)="deleteSelectedGateways(); deleteSelectedGatewaysMenu.hide();">Confirm
        </button>
        <button pButton class="p-button-raised p-button-text cbms-button cbms-small-margin-left" (click)="deleteSelectedGatewaysMenu.hide()">Cancel</button>
    </div>
</p-overlayPanel>
