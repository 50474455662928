import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROLE_PERMISSIONS } from '../../models/user';

@Component({
    selector: 'cbms-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    public showSidebarMenu: boolean = false;
    public userRolePermissionList: string;
    protected readonly ROLE_PERMISSIONS = ROLE_PERMISSIONS;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.userRolePermissionList = sessionStorage.getItem(
            'userRolePermissionList'
        );

    }

    hasPermission(permission: string) {
        return this.userRolePermissionList.includes(permission)
    }

}
