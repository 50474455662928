import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BackendService, DNS_CSV, SortOrderType, VIRTUAL, VIRTUAL_ADDRESS } from '../../services/backend/backend.service';
import { Gateway, GatewayProxy } from '../../models/gateway';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { NotificationService } from '../../services/notification/notification.service';
import { UtilsService } from 'src/app/services/utils/util.service';
import { DialogConfigService } from '../../services/dialog-config/dialog-config.service';
import {
    ExternalSource,
    PAGE_SIZE_1000, PAGE_SIZE_50,
    SUCCESS,
    SUCCESS_CREATED_OPERATION,
    SUCCESS_UPDATED_OPERATION,
    WARNING
} from '../../config/constants';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/components/filter/filter.service';
import { Extensions } from '../../models/core/extensions';
import { DataService } from 'src/app/services/data/data.service';
import { FilterParams } from "../../components/filter/interfaces/filter-params.interface";
import { Customer } from 'src/app/models/customer';
import { Site } from 'src/app/models/site';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';

const GATEWAYS_PAGINATOR_PAGE_SIZE = PAGE_SIZE_50;

@Component({
    selector: 'cbms-gateways',
    templateUrl: './gateways.component.html',
    styleUrls: ['./gateways.component.scss']
})
export class GatewaysComponent implements OnInit {
    public gateways: Gateway[] = null;
    public showAddGatewaySidebar: boolean = false;
    public isPollingGroupSelectionSidebarVisible: boolean = false;
    public sitesList: Array<{ label: string, value: string }> = [];
    public timezoneList: Array<{ label: string, value: string }> = [];
    public timezone: string;
    public vpnList: GatewayProxy[] = [];
    public vpnId: string = '';
    public gatewayName: string = '';
    public gatewayHardwareId: string = '';
    public newCreatedGateway: Gateway = null;
    public isLoading: boolean = false;
    public isLoadingGateways: boolean = false;
    public autoOnboardInProgress: boolean = false;
    public bbmdIp: string = '';
    public virtualType: boolean = false;
    public sidebarTitle: string = 'Add Gateway';
    public isEditing: boolean = false;

    public BACKNET = ExternalSource.BACKNET;
    public gwTypeList: Array<{ label: string, value: string }> = [];
    public selectedGwType: string;
    public allGatewaysSelected: boolean = false;
    public globalPresetsUIList: any[] = [];
    public readonly CC = ExternalSource.BACKNET;
    public selectedGatewayIdList: string[] = [];
    public dnsNameservers: string[] = [];

    public siteList: Site[];
    public customerList: Customer[];
	public selectedCustomer: Customer;

    public readonly MAX_SELECTED_GATEWAYS_COUNT = 100;
    public paginatorPageSize: number;//TODO create paginator object
    public paginatorTotalRecords: number;
    public paginatorFirstRecordIndex: number=0;
    public filterConfigObject: any;
    public tabName = "gateways";
    public siteAssignment = false;
    public selectedSite: Site = null;
    public requestInProgress = false;

    private filtersSubscription: Subscription;
    private selectedGatewayId: string = '';
    private customerSubscription: Subscription;
    private siteSubscription: Subscription;
    private gatewaysSubscription: Subscription;
    private expandByParameter: string = null;
    private readonly GATEWAY_PREFFIX = `gateway-`;

    private customersSubscription: Subscription;
	private sitesSubscription: Subscription;
    private FIRST_PAGE_INDEX: number=0;
    private gatewayFilter: Gateway[]=[];

    constructor(private backendService: BackendService,
        private navigationService: NavigationService,
        private route: ActivatedRoute,
        private utilsService: UtilsService,
        private notificationService: NotificationService,
        private dialogConfigService: DialogConfigService,
        private filterService: FilterService,
        private dataService: DataService) {

    }

    public ngOnInit() {
        this.route.queryParams.subscribe(parameter => {
            this.expandByParameter = parameter['selected'];
        });
        
        this.dataService.currentTabHasChanged('gateways');
        this.generateGlobalPresetsUIList();

        this.getGatewaysList();
        this.getGatewayProxyList();

        this.customerSubscription = this.filterService.customerFilterChanges$.subscribe(() => {
            this.getGatewaysList();
        });

        this.siteSubscription = this.filterService.siteFilterChanges$.subscribe(() => {
            this.getGatewaysList();
        });
        this.filterConfigObject = {
            gatewayFilter: true
        }
        this.filtersSubscription = this.dataService.filtersChanged$.subscribe((filters: FilterParams) => {
            this.gatewayFilter=filters.gatewayFilter
            this.getGatewaysList();
        });

        this.timezoneList = moment.tz.names().map(timezoneName => ({label: timezoneName, value: timezoneName}));
    }

    public ngOnDestroy() {
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }
        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }

        if(this.filtersSubscription){
            this.gatewaysSubscription.unsubscribe()
        }
    }

    /**
     * Retrieves list of gateways
     */
    public getGatewaysList( currentPageNumber=this.FIRST_PAGE_INDEX): void {
        if (this.gatewaysSubscription) {
            this.gatewaysSubscription.unsubscribe();
        }

        this.allGatewaysSelected = false;
        this.isLoadingGateways = true;
        this.gateways = null;
        this.paginatorPageSize = GATEWAYS_PAGINATOR_PAGE_SIZE;
        this.gatewaysSubscription = this.backendService.getGateways(currentPageNumber,this.paginatorPageSize,this.gatewayFilter).subscribe(response => {
            this.paginatorTotalRecords=response.totalElements
            this.gateways = this.utilsService.orderListByFieldName(response.content, 'name');
            this.gateways.forEach(gateway => {
                if (gateway.networkInterfaces) {
                    gateway.computedIpAddresses = Object.keys(gateway.networkInterfaces).map(key => {
                        return gateway.networkInterfaces[key].join(', ');
                    }).join(', ');
                }
            });
            this.isLoadingGateways = false;
        });
    }

    public expandOnLoad(gateway: Gateway): boolean {
        return gateway.id === this.expandByParameter;
    }

    public openGateway(gateway: Gateway) {
        setTimeout(() => {
            this.navigationService.setQueryParameter(this.route, { selected: gateway.id });
        });
        this.backendService.getDevicesByGateway(gateway.id).subscribe(result => {
            gateway.devices = result;
            // gateway.devices = this.utilsService.orderListByFieldName(result, 'deviceId'); //TODO fix me, order fails for some wisemeter with no id
        });
    }

    public closeGateway() {
        this.navigationService.setQueryParameter(this.route, {}, '');
    }

    public deleteGateway(gateway: Gateway): void {
        this.backendService.deleteGatewayById(gateway).subscribe(() => {
            this.navigationService.setQueryParameter(this.route, { selected: '' });
            this.getGatewaysList();
        });
    }

    public submitForm(form: NgForm) {
        this.isLoading = true;
        if (this.selectedGatewayId) {
            this.updateGateway(form);
        } else {
            this.addNewGateway(form);
        }
    }

    public cancelGateway(form: NgForm) {
        form.reset();
        this.newCreatedGateway = null;
        this.isEditing = false;
        this.isLoading = false;
        this.showAddGatewaySidebar = false;
        this.selectedGatewayId = '';
        this.dnsNameservers = [];
    }

    public showGatewayMaintenanceDialog(gateway: Gateway) {
        this.dialogConfigService.showGatewayMaintenanceDialog(gateway);
    }

    public openAddGateway() {
        this.getGwTypeList();

        this.isEditing = false;
        this.sidebarTitle = 'Add Gateway';
        this.showAddGatewaySidebar = true;
        this.virtualType = false;
        this.bbmdIp = '';
        this.timezone = moment.tz.guess();
    }

    public trackByFn(index) {
        return index;
    }

    public addDnsField() {
        this.dnsNameservers.push('');
    }

    public removeDnsField(index: number) {
        this.dnsNameservers.splice(index, 1);
    }

    private getGwTypeList() {
        this.gwTypeList = [
            { label: 'Optimum Connect&Control', value: ExternalSource.BACKNET },
            { label: 'Wisebox', value: ExternalSource.WISEMETER }
        ];
    }

    public openEditGateway(event, gateway: Gateway) {
        event.stopPropagation();
        this.getGwTypeList();

        this.dnsNameservers = gateway.extensions.dns_csv ? gateway.extensions.dns_csv.value.split(',') : [];
        this.isEditing = true;
        this.sidebarTitle = 'Edit Gateway';
        this.showAddGatewaySidebar = true;
        this.timezone = gateway.timezone;

        this.selectedGatewayId = gateway.id;

        // this.siteId = gateway.site.id;
        this.vpnId = gateway.vpn;
        this.gatewayName = gateway.name;
        this.gatewayHardwareId = gateway.hardwareId.replace(this.GATEWAY_PREFFIX, '');
        this.virtualType = gateway.virtual;
        this.bbmdIp = gateway.virtualAddress;
        // need this to fix the GatewayType selection
        setTimeout(() => {
            this.selectedGwType = gateway.source;
        });

    }

    public toggleSelectAllGateways() {
        this.gateways.forEach(gateway => gateway.selected = this.allGatewaysSelected);
        this.selectedGatewayIdList = this.gateways.filter((gateway) => gateway.selected).map((selectedGateway) => selectedGateway.id);
    }

    public updateSelectedGatewaysList(gateway: Gateway) {
        this.allGatewaysSelected = false;
        if (gateway.selected) {
            this.selectedGatewayIdList.push(gateway.id);
        } else {
            this.selectedGatewayIdList = this.selectedGatewayIdList.filter(selectedGatewayId => selectedGatewayId !== gateway.id);
        }
    }

    public stopEventPropagation(event: MouseEvent) {
        event.stopPropagation();
    }

    public openPollingGroupSelection() {
        this.resetGlobalPresets();
        this.getCustomerList();
        
        this.siteAssignment = false;
        this.selectedCustomer = null;
        this.selectedSite = null;

        this.isPollingGroupSelectionSidebarVisible = true;
    }


    public confirmGroupPollingAndStartAutoOnboarding() {
        const siteId = this.siteAssignment ? this.selectedSite.id : null;
        this.autoOnboardInProgress = true;
        this.isPollingGroupSelectionSidebarVisible = false;

        const pollingTypes = this.globalPresetsUIList.filter(type => type.isChecked).map(selectedType => selectedType.globalType);

        this.backendService.gatewayAutoOnboard(this.selectedGatewayIdList, pollingTypes, siteId).subscribe((response: any) => {
            this.autoOnboardInProgress = false;
            this.selectedGatewayIdList = [];
            this.getGatewaysList();
            this.notificationService.addSuccessMessage(SUCCESS, `Auto onboard running in background, it can take a while ...`, true);

        }, error => this.notificationService.addWarnMessage(WARNING, `Auto onboard failed`, true)
        );
    }

    public cancelPollingGroupSelection() {
        this.isPollingGroupSelectionSidebarVisible = false;
    }

    public getGatewayProxyList() {
        this.backendService.getGatewayProxyList().subscribe( (response: GatewayProxy[]) => {
            this.vpnList = response;
        })
    }

    public deleteSelectedGateways() {
        this.backendService.deleteGatewayByIdList(this.selectedGatewayIdList).subscribe(() => {
            this.notificationService.addSuccessMessage('Delete Gateways', 'Gateways deleted successfully');
            this.selectedGatewayIdList = [];
            this.navigationService.setQueryParameter(this.route, { selected: '' });
            this.getGatewaysList();
        });
    }

    public onPageSelected($event) {
        this.paginatorFirstRecordIndex=$event.first
        this.getGatewaysList($event.page)
    }

    public onCustomerChosen() {
        this.getSiteList(this.selectedCustomer.id);
    }

    exportGatewayExcel() {
        let date = new Date();
        let time = `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
        this.requestInProgress = true;
        this.backendService.exportGatewaysExcel().subscribe(response => {
            saveAs(response, `OptimumCC_GW_Blank_Template_${time}.xlsx`);
            this.requestInProgress = false;
        }, (err) => { console.log(err) });
    }

    uploadGatewayExcel(event: any, sitesFileUpload) {
        this.requestInProgress = true;
        this.backendService.uploadGatewaysExcel(event.files[0]).subscribe((response) => {
            sitesFileUpload.clear();
            this.requestInProgress = false;
            this.notificationService.addSuccessMessage(SUCCESS, `Gateways imported successfully.`, true);
        });
    }

    private getSiteList(customerId: string) {
		if (this.sitesSubscription) {
			this.sitesSubscription.unsubscribe()
		}
		this.sitesSubscription = this.backendService.getSitesByCustomer(customerId).subscribe(response => {
			this.siteList = this.utilsService.orderListByFieldName(response.content, 'name');
		});
	}

    private updateGateway(form: NgForm) {
        const gateway: Gateway = this.getGateway(this.getExtensionByGatewayTypeUpdateFlow(this.selectedGwType));
        gateway.id = this.selectedGatewayId;

        this.backendService.updateGateway(gateway).subscribe(response => {
            form.reset();
            this.selectedGatewayId = '';
            this.isLoading = false;
            this.showAddGatewaySidebar = false;
            this.getGatewaysList();
            this.notificationService.addSuccessMessage(SUCCESS, `Gateway ${SUCCESS_UPDATED_OPERATION}`, false);
        }, () => {
            this.isLoading = false;
        });
    }

    private addNewGateway(form: NgForm) {
        const gateway = this.getGateway(this.getExtensionByGatewayTypeCreateFlow(this.selectedGwType));
        gateway.hardwareId = this.gatewayHardwareId ? `${this.GATEWAY_PREFFIX}${this.gatewayHardwareId}` : null;

        this.backendService.addGateway(gateway).subscribe(response => {
            this.newCreatedGateway = response;
            form.reset();
            this.isLoading = false;
            this.getGatewaysList();
            this.notificationService.addSuccessMessage(SUCCESS, `Gateway ${SUCCESS_CREATED_OPERATION}`, false);
        }, () => {
            this.isLoading = false;
        });
    }

    private getExtensionByGatewayTypeCreateFlow(selectedGwType: string) {
        if (ExternalSource.WISEMETER === selectedGwType) {
            return null;
        }
        if (ExternalSource.BACKNET === selectedGwType) {
            if (this.virtualType) {
                let ext = {
                    virtual: { id: null, name: VIRTUAL, value: this.virtualType, extensionType: this.selectedGwType },
                    virtual_address: { id: null, name: VIRTUAL_ADDRESS, value: this.bbmdIp, extensionType: this.selectedGwType }
                }
                if (this.dnsNameservers.length) {
                    ext = Object.assign({}, ext, { dns_csv: { id: null, name: DNS_CSV, value: this.dnsNameservers.join(','), extensionType: this.selectedGwType } });
                }
                return ext;
            }

            return {
                virtual: { id: null, name: VIRTUAL, value: this.virtualType, extensionType: this.selectedGwType },
            };
        }

    }

    private getExtensionByGatewayTypeUpdateFlow(selectedGwType: string): Extensions {
        if (ExternalSource.WISEMETER === selectedGwType) {
            return null;
        }
        if (ExternalSource.BACKNET === selectedGwType) {
            const extensions = this.gateways.filter(value => value.id === this.selectedGatewayId)[0].extensions;
            extensions.virtual.value = this.virtualType;
            if (this.virtualType) {
                extensions.virtual_address.value = this.bbmdIp;

                if (this.dnsNameservers.length) {
                    extensions['dns_csv'] = {
                        id: extensions.dns_csv ? extensions.dns_csv.id : null,
                        name: DNS_CSV,
                        value: this.dnsNameservers.join(','),
                        extensionType: this.selectedGwType
                    }
                } else {
                    delete extensions['dns_csv'];
                }
            }
            return extensions;
        }
    }

    private getGateway(extensions: Extensions): Gateway {
        return {
            name: this.gatewayName,
            source: this.selectedGwType,
            extensions: extensions,
            vpn: this.vpnId,
            timezone: this.timezone
        };
    }

    private generateGlobalPresetsUIList() {
        this.globalPresetsUIList = [
            {
                label: 'Input',
                globalType: 'Input',
                isChecked: false
            },
            {
                label: 'Output',
                globalType: 'Output',
                isChecked: false
            },
            {
                label: 'Logical',
                globalType: 'Logical',
                isChecked: false
            },
            {
                label: 'Schedule',
                globalType: 'Schedule',
                isChecked: false
            }
        ];
    }

    private resetGlobalPresets() {
        this.globalPresetsUIList.forEach((item) => {
            item.isChecked = false;
        });
    }

    private getCustomerList() {
		if (this.customersSubscription) {
			this.customersSubscription.unsubscribe();
		}
		this.customersSubscription = this.backendService.getCustomers().subscribe(customerList => {
			this.customerList = customerList.map(customer => { return { id: customer.id, name: customer.name }; });
		});
	}

}
