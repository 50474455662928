import { Component, Input } from '@angular/core';
import { HighchartsBaseComponent } from '../highcharts-base.component';

import * as Highcharts from 'highcharts';
import { ChartConfig } from './interfaces/timeseries-chart-config.interface';
import { HighchartsDataService } from 'src/app/services/data/highcharts.data.service';
import moment from 'moment';

@Component({
    selector: 'cbms-timeseries-chart',
    templateUrl: '../highcharts-base.component.html',
    styleUrls: ['../highcharts-base.component.scss'],
})
export class TimeseriesChartComponent extends HighchartsBaseComponent {
    constructor(private highchartsDataService: HighchartsDataService) {
        super();
        this.onSelection = this.onSelection.bind(this);
    }

    protected getChartOptions(config: ChartConfig): Highcharts.Options {
        return {
            chart: {
                zooming: {
                    type: 'x'
                },
                events: {
                    selection: this.onSelection,
                },
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: config.yAxis,
            tooltip: {
                shared: true,
            },
            plotOptions: {
                line: {
                    marker: {
                        radius: 3,
                    },
                    lineWidth: 1,
                },
            },
            series: config.data,
        };
    }

    private onSelection(e: any) {
        this.highchartsDataService.addZoomLevel({
            dataMax: null,
            dataMin: null,
            userMax: null,
            userMin: null,
            max: e.xAxis[0].max,
            min: e.xAxis[0].min,
        });

        this.highchartsDataService.updateTimeFilter(
            e.xAxis[0].min,
            e.xAxis[0].max
        );
        return true;
    }
}
