import { Component, OnInit } from '@angular/core';
import { BackendService, BE_WEEKLY_SCHEDULE } from 'src/app/services/backend/backend.service';
import { PAGINATION, SORT_ORDER_DESC } from 'src/app/config/constants';
import { LazyLoadEvent } from 'primeng/api';
import { ScheduleTemplate } from 'src/app/models/data-point';

@Component({
    selector: 'cbms-schedule-templates',
    templateUrl: './schedule-templates.component.html',
    styleUrls: ['./schedule-templates.component.scss']
})
export class ScheduleTemplatesComponent implements OnInit  {
	
	public pageSize = PAGINATION.SIZE;
	public totalRecords: number = null;
    public sortField: string = 'modifiedDate';
    public sortOrder: number = SORT_ORDER_DESC;
	public firstPageIndex: number = 0;
	public isLoading: boolean = false;
    public updatedTemplateId: string = '';
    public tableName = 'scheduleTemplates';

    public templateCols: any[] = [
        {field: 'customerName', header: 'Customer Name', sortable: true, position: 2, width: '170px'},
        {field: 'name', header: 'Name', sortable: true, position: 3, width: ''},
        {field: 'createdBy', header: 'Created By', sortable: true, position: 4, width: ''},
        {field: 'createdDate', header: 'Created Date', sortable: true, position: 5, width: ''},
        {field: 'modifiedBy', header: 'Modified By', sortable: true, position: 6, width: ''},
        {field: 'modifiedDate', header: 'Modified Date', sortable: true, position: 7, width: ''}
    ];

    public templates: ScheduleTemplate[];

    constructor(private backendService: BackendService) {
    }


    ngOnInit() {
        this.initData();
    }

    private initData() {
		this.isLoading = true;
		const sortOptions = {
            sortField: this.sortField,
            sortOrder: this.sortOrder === 1 ? 'asc' : 'desc'
        };
		this.backendService.getScheduleTemplatesByType(BE_WEEKLY_SCHEDULE, 0, this.pageSize, sortOptions).subscribe((response: any) => {
			this.templates = response.content;
			this.totalRecords = response.totalElements;
			this.isLoading = false;
            this.firstPageIndex = 0;
		});
	}

    public loadTemplatesLazy(event: LazyLoadEvent) {
        this.isLoading = true;
        const pageNumber = event.first !== 0 ? (event.first / this.pageSize) : 0;
        const sortOptions = {
            sortField: event.sortField,
            sortOrder: event.sortOrder === 1 ? 'asc' : 'desc'
        };

        this.backendService.getScheduleTemplatesByType(BE_WEEKLY_SCHEDULE, pageNumber, this.pageSize, sortOptions).subscribe((response) => {
            this.templates = response.content;
            this.isLoading = false;
        });
    }

    handleOnDelete() {
        this.initData();
    }

    handleRefreshTemplateList(updatedTemplateId: string) {
        this.updatedTemplateId = updatedTemplateId;
        this.initData();
    }


}
