import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Site } from '../../models/site';
import { ViewDevice } from '../../models/device';
import { DataPointTag } from '../../models/data-point';
import { FilterService } from './filter.service';
import _ from 'lodash';
import { DataService } from 'src/app/services/data/data.service';
import { FilterParams } from './interfaces/filter-params.interface';
import { Subscription } from 'rxjs';
import { merge } from 'rxjs/internal/observable/merge';
import {Gateway} from "../../models/gateway";

@Component({
    selector: 'cbms-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FilterComponent implements OnInit {
    @Input()
    config: any;

    @Input()
    tabName: string;
    @Input()
    fieldFilterOptions: string[];

    customerSubscription: Subscription;
    siteSubscription: Subscription;

    filters: FilterParams = {
        siteFilter: [],
        deviceFilter: [],
        tagFilter: [],
        tagLogicalOperator: 'AND',
        quickFilterText: '',
        fieldFilter: {},
        gatewayFilter:[]
    }

    constructor(
        private dataService: DataService,
        private filterService: FilterService) {
    }

    public ngOnInit(): void {
        Object.keys(this.config).map( (filterName: string) => {
            this.filters[filterName] = this.filterService.getSelectedFilter(filterName, this.tabName) || this.filters[filterName];
        });
        this.dataService.applyFilter(this.filters);

        // clear filters when one of the components emits a value
        merge(this.filterService.customerFilterChanges$, this.filterService.siteFilterChanges$).subscribe( () => {
            this.clearFilters();
        });
    }

    public onFilter() {
        this.filterService.applyFilter(this.tabName, this.filters);
        this.dataService.applyFilter(this.filters);
    }

    public clearFilters() {
        this.filters = {
            siteFilter: [],
            deviceFilter: [],
            tagFilter: [],
            tagLogicalOperator: 'AND',
            quickFilterText: '',
            fieldFilter: {},
            gatewayFilter:[],

        }

        this.filterService.clearFilters(this.tabName, this.config);
        this.dataService.clearFilter(this.filters);
    }

    public ngOnDestroy() {
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }

        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }
    }

    public onSiteChange(siteList: Site[]) {
        this.filters.siteFilter = siteList;
    }

    public onDeviceChange(deviceList: ViewDevice[]) {
        this.filters.deviceFilter = deviceList;
    }

    public onTagChange(event: {tagList: DataPointTag[], tagLogicalOperator: string}) {
        this.filters.tagFilter = event.tagList;
        this.filters.tagLogicalOperator = event.tagLogicalOperator;
    }

    public onQuickFilterChange(quickFilterText: string) {
        this.filters.quickFilterText = quickFilterText;
    }

    public onFieldFilterChange(fieldFilter: any) {
        this.filters.fieldFilter = fieldFilter;
    }

    onGatewayChange($event: Gateway[]) {
      this.filters.gatewayFilter=$event

    }
}
