import { Component, Input, OnInit } from '@angular/core';
import { MappedDataPoint } from 'src/app/models/data-point';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { DATAPOINTS_OP } from '../../../config/constants';

@Component({
	selector: 'cbms-write-twin-to-datapoint',
	templateUrl: './write-twin-to-datapoint.component.html',
	styleUrls: ['./write-twin-to-datapoint.component.scss']
})
export class WriteTwinToDatapointComponent implements OnInit {
	@Input()
	public selectedDataPoints: MappedDataPoint[];

	constructor(private dialogConfigService: DialogConfigService) { }

	ngOnInit(): void {
	}

	public showWriteTwinToDatapointDialog() {
		this.dialogConfigService.showSetpointDialog({ dataPoints: this.selectedDataPoints, operation: DATAPOINTS_OP.WRITE_TWIN_TO_DATAPOINT });
	}
}

