import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BackendService} from "../../../services/backend/backend.service";
import {Gateway} from "../../../models/gateway";
import {PAGE_SIZE_10k, PAGINATION} from "../../../config/constants";
import {Subscription} from "rxjs";
import {DataService} from "../../../services/data/data.service";

@Component({
    selector: 'cbms-gateway-filter',
    templateUrl: './gateway-filter.component.html',
    styleUrls: ['./gateway-filter.component.scss']
})
export class GatewayFilterComponent implements OnInit, OnDestroy {
    @Output()
    filterChanged = new EventEmitter<Gateway[]>();

    gatewayList: Gateway[];

    @Input()
    selectedGatewayList: Gateway[];
    private gatewaySubscription: Subscription;

    constructor(private backendService: BackendService,private dataService:DataService) {
    }

    ngOnDestroy(): void {
        if (this.gatewaySubscription) {
            this.gatewaySubscription.unsubscribe()
        }
    }

    ngOnInit(): void {
        this.gatewayList =  [{
            name:"loading",
            nameAndGwHardwareId:"loading..."}]
        this.gatewaySubscription = this.backendService.getGatewaysSummary(PAGINATION.DEFAULT_PAGE_NR, PAGE_SIZE_10k,[])
            .subscribe(response => {
                this.gatewayList = response.content.map(gateway => {
                    gateway.nameAndGwHardwareId = `${gateway.name} (${gateway.hardwareId})`;
                    return gateway;
                });
                //TODO sort it? or sort it by BE call
            });

    }

    onGatewaySelected() {
        this.filterChanged.emit(this.selectedGatewayList)
        this.dataService.changeGatewaysFilter(this.selectedGatewayList)
    }
}
