import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DataPoint, DataPointTag, TagMngDataPoint } from '../../models/data-point';
import { UtilsService } from '../../services/utils/util.service';
import { NotificationService } from '../../services/notification/notification.service';
import { BackendService } from '../../services/backend/backend.service';
import { DataService } from "../../services/data/data.service";

import _ from 'lodash';
import { FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';
import { Subscription } from 'rxjs';
import { uuid } from 'uuidv4';

@Component({
    selector: 'cbms-tag-management',
    templateUrl: './tag-management.component.html',
    styleUrls: ['./tag-management.component.scss']
})
export class TagManagementComponent implements OnInit {
    public dataPoints: TagMngDataPoint[] = [];
    public historyData = [];
    public systemTags: DataPointTag[] = [];
    public tagsToAdd: DataPointTag[] = [];
    public existingTags: DataPointTag[] = [];
    public tagsToRemove: DataPointTag[] = [];
    public newTag: string = '';
    public isLoading: boolean = false;
    public isLoadingDataPoints: boolean = false;
    public tableName: string = 'tagMng';
    public cols: any[] = [
        { field: 'siteName', header: 'Site', sortable: true, width: '120px' },
        { field: 'deviceName', header: 'Device', sortable: true, width: '' },
        { field: 'objectName', header: 'Name', sortable: true, width: '' },
        { field: 'units', header: 'Unit', sortable: false, width: '80px' },
        { field: 'tags', header: 'Tags', sortable: false, width: '' }
    ];

    private getDataPointTagsAutoSuggestionsSubscription: Subscription;

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private utilsService: UtilsService,
        private backendService: BackendService,
        private notificationService: NotificationService,
        private dataService: DataService) {

    }

    ngOnInit() {
        this.isLoadingDataPoints = true;
        const filterParams = {
            dataPointIdFilter: Object.keys(this.config.data).map((key) => this.config.data[key])
        }
        const pageSize = filterParams.dataPointIdFilter.length;

        this.backendService.getDataPointsSummary(filterParams, 0, pageSize).subscribe(response => {
            this.dataPoints = response.content.map( (dataPoint: DataPoint): TagMngDataPoint => {
                return Object.assign({}, dataPoint, {siteName: dataPoint.site.name, deviceName: dataPoint.deviceName });
            });
            this.getExistingTags();
            this.isLoadingDataPoints = false;
            this.historyData.push(this.getTagsFromDP(this.dataPoints));
        });

        this.getDataPointTagsAutoSuggestionsSubscription =this.backendService.getDataPointTagsAutoSuggestions().subscribe(tags => {
            this.systemTags = this.utilsService.orderListByFieldName(tags, 'name');
        });
    }

    public getExistingTags() {
        this.dataPoints.forEach(dataPoint => {
            dataPoint.tags.forEach(tag => {
                if (tag.isDeleted) {
                    return;
                }
                if (this.existingTags.findIndex(existingTag => existingTag.name === tag.name) === -1) {
                    this.existingTags.push(tag);
                }
            });
        });
        this.existingTags = this.utilsService.orderListByFieldName(this.existingTags, 'name');
    }

    public addTags() {
        if (!this.tagsToAdd.length && !this.newTag) {
            return;
        }

        if (this.newTag && this.newTag.length >= 3) {
            this.tagsToAdd.push({name: this.newTag});
        }
        let tagIndex = null;

        this.dataPoints.forEach(dataPoint => {
            this.tagsToAdd.forEach(tagToAdd => {
                tagIndex = dataPoint.tags.findIndex(existingTag => tagToAdd.name === existingTag.name);
                if (tagIndex === -1) {
                    tagToAdd.isNew = true;
                    tagToAdd.id = tagToAdd.id || uuid();
                    if (!this.systemTags.map(tag => tag.id).includes(tagToAdd.id)) {
                        tagToAdd.shouldRemoveId = true;
                    }
                    dataPoint.tags.push(tagToAdd);
                };
                if (_.has(dataPoint.tags[tagIndex], 'isDeleted')) {
                    delete dataPoint.tags[tagIndex].isDeleted;
                }
            });
        });
        this.historyData.push(this.getTagsFromDP(this.dataPoints));
        this.tagsToAdd = [];
        this.newTag = '';
        this.existingTags = [];
        this.getExistingTags();
    }

    public removeTags() {
        if (!this.tagsToRemove.length) {
            return;
        }
        let tagIndex = null;
        this.dataPoints.forEach(dataPoint => {
            this.tagsToRemove.forEach(tagToRemove => {
                tagIndex = dataPoint.tags.findIndex(existingTag => tagToRemove.name === existingTag.name);
                if (tagIndex !== -1) {
                    if (dataPoint.tags[tagIndex].isNew) {
                        dataPoint.tags.splice(tagIndex, 1);
                    } else {
                        dataPoint.tags[tagIndex].isDeleted = true;
                    }
                };
            });
        });
        this.historyData.push(this.getTagsFromDP(this.dataPoints));
        this.tagsToRemove = [];
        this.existingTags = [];
        this.getExistingTags();
    }

    public undo() {
        let prevTags = [];
        if (this.historyData.length === 1) {
            prevTags = this.historyData[0];
        } else {
            this.historyData.pop();
            prevTags = this.historyData[this.historyData.length-1];
        }

        this.dataPoints.forEach(dataPoint => {
            dataPoint.tags = [...prevTags[dataPoint.id].map(tag => _.cloneDeep(tag))];
        })
        this.tagsToAdd = [];
        this.tagsToRemove = [];
        this.existingTags = [];
        this.getExistingTags();
    }

    public close() {
        this.getDataPointTagsAutoSuggestionsSubscription.unsubscribe();
        this.ref.close();
    }

    public save() {
        this.isLoading = true;

        const filterParams: FilterParams = {
            dataPointIdFilter: this.dataPoints
        };

        this.backendService.updateDataPointsTags(this.dataPoints).subscribe(() => {
            this.notificationService.addSuccessMessage('Edit Tags', 'Tags successfully updated.', false);
            this.backendService.getDataPointsDetails(filterParams).subscribe(dataPoints => {
                this.dataService.changeNewTagsStatus({dataPointsTagsUpdated: true});
                this.ref.close(dataPoints.content);
            });
            this.isLoading = false;
        }, (error) => {
            this.notificationService.addErrorMessage('Edit Tags', error);
            this.isLoading = false;
        });
    }

    public getDataPointIds() {
        return this.dataPoints.map(dataPoint => dataPoint.id);
    }

    public onColResize() {
        let columns = document.getElementsByClassName('ui-sortable-column');
        Array.from(columns).forEach( (column: HTMLElement) => {
            sessionStorage.setItem(`${this.tableName}-${column.innerText.trim()}`, `${column.clientWidth}px`);
        })
    }

    public noChangesOnTags(): boolean {
        return _.isEqual(this.historyData[0], this.historyData[this.historyData.length-1]);
    }

    private getTagsFromDP(dataPoints: DataPoint[]) {
        let tags = {};
        dataPoints.forEach(dataPoint => {
            tags[dataPoint.id] = [...dataPoint.tags.map(tag => _.cloneDeep(tag))];
        });
        return tags;
    }
}
