<div class="flex align-items-baseline cbms-align-center">
    <div class="col-8">
        <cbms-column-filter></cbms-column-filter>
    </div>
    <div class="col-4">
        <cbms-timeframe-filter [styleClass]="'cbms-correct-calendar-position'"></cbms-timeframe-filter>
    </div>
</div>
<div class="cbms-title-wrapper-baseline">
    <mat-icon class="cbms-title-icon" svgIcon="menu_book"></mat-icon>
    <span class="cbms-title-text">Action Log</span>
</div>
<div class="table-wrapper">
    <cbms-audit-table></cbms-audit-table>
</div>
