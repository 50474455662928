import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { ExceptionScheduleTemplate } from '../../../models/data-point';
import { BackendService, BE_EXCEPTION_SCHEDULE } from '../../../services/backend/backend.service';

@Component({
    selector: 'cbms-show-exception-schedule-template-action',
    templateUrl: './show-exception-schedule-template-action.component.html',
    styleUrls: ['./show-exception-schedule-template-action.component.scss']
})
export class ShowExceptionScheduleTemplateActionComponent implements OnInit {

    
    @Input()
    public templateName: any;

    @Input()
    public templateId: string;

    @Output() public onRefreshTemplateList: EventEmitter<string> = new EventEmitter<string>();

    constructor(private dialogConfigService: DialogConfigService,
                private backendService: BackendService) {
    }

    @Input()
    public isReadonly:boolean;

    ngOnInit() {
    }

    public showExceptionScheduleTemplateDialog() {
        this.backendService.getScheduleTemplateByIdAndType(BE_EXCEPTION_SCHEDULE, this.templateId).subscribe((response) => {
            const scheduleTemplate = response.content[0];
            this.templateName = scheduleTemplate.name;

            let ref = this.dialogConfigService.showExceptionScheduleTemplateDialog({
                name: this.templateName,
                id: this.templateId,
                exceptionScheduleObject: (<ExceptionScheduleTemplate>(scheduleTemplate)).exceptionScheduleObject,
                isReadonly: this.isReadonly
            });
            ref.onClose.subscribe((templateId) => {
                if (templateId) {
                    this.onRefreshTemplateList.emit(templateId);
                }
            });
        });
    }

}
