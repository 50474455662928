import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/components/filter/filter.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { saveAs } from 'file-saver';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DataService } from 'src/app/services/data/data.service';

const REPORT_RUNNING_STATUS_CODE = 'RUNNING';

@Component({
    selector: 'cbms-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
    private readonly zeroPercentageText = '0';

    shouldDisableRunReportButton = false;

    //datapoint override report
    public downloadDate = '';
    public status = '';
    public overrideReportRequestInProgress = false;
    public overrideReportProgressPercentage: string = this.zeroPercentageText;

    //datapoint site report
    public siteConnectivityDownloadDate = '';
    public siteConnectivityStatus = '';
    public siteConnectivityRequestInProgress = false;

    //datapoint device report
    public deviceConnectivityDownloadDate = '';
    public deviceConnectivityStatus = '';
    public deviceConnectivityRequestInProgress = false;

    private customerId = '';
    private customerName = '';

    //datapoint integrity report
    public datapointIntegrityReportStatus = '';
    public datapointIntegrityRequestInProgress = false;
    public datapointIntegrityReportDownloadDate = '';
    public datapointIntegrityReportProgressPercentage = this.zeroPercentageText;

    private reportrunningnotificationmessage =
        'Report now running. Status will change to Complete when ready to download new report, please refresh the page to update status.';

    public deviceConnectivityReportProgressPercentage = this.zeroPercentageText;

    private readonly IN_PROGRESS_LABEL = 'In Progress';

    //datapoint faulty report
    public datapointFaultyReportStatus = '';
    public datapointFaultyRequestInProgress = false;
    public datapointFaultyReportDownloadDate = '';
    public datapointFaultyReportProgressPercentage = this.zeroPercentageText;
    public showFaultySensorReportProperties = false;

    outOfRangeLowCelsius = -20;
    outOfRangeHighCelsius = 120;
    outOfRangeLowRelativeHumidity = 0;
    outOfRangeHighRelativeHumidity = 100;
    selectedDayRange: { name: string; value: number } = { name: '3', value: 3 };

    dayRangeOptions: any[] = [
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
        { name: '5', value: 5 },
        { name: '6', value: 6 },
        { name: '7', value: 7 },
        { name: '8', value: 8 },
        { name: '9', value: 9 },
        { name: '10', value: 10 },
        { name: '11', value: 11 },
        { name: '12', value: 12 },
        { name: '13', value: 13 },
        { name: '14', value: 14 },
    ];

    showDatapointFaultyReportError = false;

    constructor(
        private backendService: BackendService,
        private filterService: FilterService,
        private notificationService: NotificationService,
        private dataService: DataService
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('reports');

        this.filterService.customerFilterChanges$.subscribe((data) => {
            this.customerId = data.selectedCustomer?.id;
            this.customerName = data.selectedCustomer?.name;
            this.getOverridesReportStatus();
            this.getSiteConnectivityReportStatus();
            this.getDeviceConnectivityReportStatus();
            this.getDatapointIntegrityReportStatus();
            this.getDatapointFaultyReportStatus();
        });

        this.shouldDisableRunReportButton = !sessionStorage.getItem('userRolePermissionList').includes("RUN_REPORT_BUTTON.CORE_APP");

        this.getOverridesReportStatus();
        this.getSiteConnectivityReportStatus();
        this.getDeviceConnectivityReportStatus();
        this.getDatapointIntegrityReportStatus();
        this.getDatapointFaultyReportStatus();
    }

    public runOverrideReport() {
        this.backendService
            .generateOverridesReport(this.customerId)
            .subscribe((response) => {
                this.status = 'In Progress';
                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );

                this.overrideReportRequestInProgress = true;
                this.overrideReportProgressPercentage = this.zeroPercentageText;
            });
    }

    public exportOverridesReport() {
        this.overrideReportRequestInProgress = true;
        this.backendService.exportOverridesReport(this.customerId).subscribe(
            (response) => {
                saveAs(
                    response,
                    `${
                        this.customerName
                    }_Overrides_Report_${this.getDateNowAsText()}.csv`
                );
                this.overrideReportRequestInProgress = false;
            },
            (err) => {
                console.log(err);
            }
        );
    }

    private getOverridesReportStatus() {
        this.backendService
            .getOverridesReportStatus(this.customerId)
            .subscribe((response) => {
                this.downloadDate = response.status.lastSuccessfulReportDate;
                this.status = response.status.statusDescription;
                this.overrideReportRequestInProgress =
                    this.isReportRunning(response);
                this.overrideReportProgressPercentage =
                    response.status.progressPercentage;
            });
    }

    private isReportRunning(response) {
        return response.status.status === REPORT_RUNNING_STATUS_CODE;
    }

    public runSiteConnectivityReport() {
        this.backendService
            .generateSiteConnectivityReport(this.customerId)
            .subscribe((response) => {
                this.siteConnectivityStatus = 'In Progress';
                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
            });
    }

    public exportSiteConnectivityReport() {
        this.siteConnectivityRequestInProgress = true;
        this.backendService
            .exportSiteConnectivityReport(this.customerId)
            .subscribe(
                (response) => {
                    saveAs(
                        response,
                        `${
                            this.customerName
                        }_Connectivity_Report_${this.getDateNowAsText()}.csv`
                    );
                    this.siteConnectivityRequestInProgress = false;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    private getSiteConnectivityReportStatus() {
        this.backendService
            .getSiteConnectivityReportStatus(this.customerId)
            .subscribe((response) => {
                this.siteConnectivityDownloadDate =
                    response.status.lastSuccessfulReportDate;
                this.siteConnectivityStatus = response.status.statusDescription;
            });
    }

    public runDeviceConnectivityReport() {
        this.backendService
            .generateDeviceConnectivityReport(this.customerId)
            .subscribe((response) => {
                this.deviceConnectivityStatus = 'In Progress';
                this.deviceConnectivityRequestInProgress = true;
                this.deviceConnectivityReportProgressPercentage =
                    this.zeroPercentageText;
                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
            });
    }

    public exportDeviceConnectivityReport() {
        this.deviceConnectivityRequestInProgress = true;
        this.backendService
            .exportDeviceConnectivityReport(this.customerId)
            .subscribe(
                (response) => {
                    saveAs(
                        response,
                        `${
                            this.customerName
                        }_Device_Connectivity_Report_${this.getDateNowAsText()}.csv`
                    );
                    this.deviceConnectivityRequestInProgress = false;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    private getDeviceConnectivityReportStatus() {
        this.backendService
            .getDeviceConnectivityReportStatus(this.customerId)
            .subscribe((response) => {
                this.deviceConnectivityDownloadDate =
                    response.status.lastSuccessfulReportDate;
                this.deviceConnectivityStatus =
                    response.status.statusDescription;
                this.deviceConnectivityRequestInProgress =
                    this.isReportRunning(response);

                this.deviceConnectivityReportProgressPercentage =
                    response.status.progressPercentage;
            });
    }

    runDatapointIntegrityReport() {
        this.backendService
            .runDatapointIntegrityReport(this.customerId)
            .subscribe((response) => {
                this.datapointIntegrityReportStatus = this.IN_PROGRESS_LABEL;
                this.datapointIntegrityRequestInProgress = true;
                this.datapointIntegrityReportProgressPercentage =
                    this.zeroPercentageText;

                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
            });
    }

    runDatapointFaultyReport() {
        this.showDatapointFaultyReportError = false;
        if (
            this.outOfRangeLowCelsius > this.outOfRangeHighCelsius ||
            this.outOfRangeLowRelativeHumidity >
                this.outOfRangeHighRelativeHumidity
        ) {
            this.showDatapointFaultyReportError = true;
            return;
        }

        this.backendService
            .runDatapointFaultyReport(this.customerId, {
                outOfRangeLowCelsius: this.outOfRangeLowCelsius,
                outOfRangeHighCelsius: this.outOfRangeHighCelsius,
                outOfRangeLowRelativeHumidity:
                    this.outOfRangeLowRelativeHumidity,
                outOfRangeHighRelativeHumidity:
                    this.outOfRangeHighRelativeHumidity,
                dayRange: this.selectedDayRange.value,
            })
            .subscribe((response) => {
                this.datapointFaultyReportStatus = this.IN_PROGRESS_LABEL;
                this.datapointFaultyRequestInProgress = true;
                this.datapointFaultyReportProgressPercentage =
                    this.zeroPercentageText;

                this.notificationService.addSuccessMessage(
                    'Reports',
                    this.reportrunningnotificationmessage
                );
                this.hideFaultySensorReportDialog();
            });
    }

    exportDatapointIntegrityReport() {
        this.datapointIntegrityRequestInProgress = true;
        this.backendService
            .exportDatapointIntegrityReport(this.customerId)
            .subscribe(
                (response) => {
                    saveAs(
                        response,
                        `${
                            this.customerName
                        }_Datapoint_Integrity_Report_${this.getDateNowAsText()}.csv`
                    );
                    this.datapointIntegrityRequestInProgress = false;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    exportDatapointFaultyReport() {
        this.datapointFaultyRequestInProgress = true;
        this.backendService
            .exportDatapointFaultyReport(this.customerId)
            .subscribe({
                next: (response) => {
                    saveAs(
                        response,
                        `${
                            this.customerName
                        }_Datapoint_Faulty_Report_${this.getDateNowAsText()}.csv`
                    );
                    this.datapointFaultyRequestInProgress = false;
                },
                error: (err) => {
                    console.log(err);
                },
            });
    }

    setDefaultProperties() {
        this.outOfRangeLowCelsius = -20;
        this.outOfRangeHighCelsius = 120;
        this.outOfRangeLowRelativeHumidity = 0;
        this.outOfRangeHighRelativeHumidity = 100;
        this.selectedDayRange = { name: '3', value: 3 };
    }

    runWithDefaultProperties() {
        this.setDefaultProperties();
        this.runDatapointFaultyReport();
    }

    hideFaultySensorReportDialog() {
        this.showFaultySensorReportProperties = false;
        this.showDatapointFaultyReportError = false;
        this.setDefaultProperties();
    }

    showFaultySensorReportDialog() {
        this.showFaultySensorReportProperties = true;
    }

    private getDateNowAsText() {
        let date = new Date();
        let time = `${date.getFullYear()}${
            date.getMonth() + 1
        }${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
        return time;
    }

    private getDatapointIntegrityReportStatus() {
        this.backendService
            .getDatapointIntegrityReportStatus(this.customerId)
            .subscribe((response) => {
                this.datapointIntegrityReportDownloadDate =
                    response.status.lastSuccessfulReportDate;
                this.datapointIntegrityReportStatus =
                    response.status.statusDescription;
                this.datapointIntegrityRequestInProgress =
                    this.isReportRunning(response);

                this.datapointIntegrityReportProgressPercentage =
                    response.status.progressPercentage;
            });
    }

    private getDatapointFaultyReportStatus() {
        this.backendService
            .getDatapointFaultyReportStatus(this.customerId)
            .subscribe((response) => {
                this.datapointFaultyReportDownloadDate =
                    response.status.lastSuccessfulReportDate;

                this.datapointFaultyReportStatus =
                    response.status.statusDescription;

                this.datapointFaultyRequestInProgress =
                    this.isReportRunning(response);

                this.datapointFaultyReportProgressPercentage =
                    response.status.progressPercentage;
            });
    }
}
