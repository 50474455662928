<div class="content">
    <div class="cbms-spinner-wrapper" *ngIf="isLoadingDataPoints">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <p-table *ngIf="!isLoadingDataPoints" class="cbms-generic-table-style" [value]="dataPoints" [columns]="cols" [resizableColumns]="true" [scrollable]="true"
    columnResizeMode="expand" [scrollHeight]="'450px'">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="cbms-text-align-left" *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable"
                    pResizableColumn>
                    {{col.header}}
                    <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dataPoint>
            <tr>
                <td class="ui-resizable-column">{{dataPoint.siteName}}</td>
                <td class="ui-resizable-column">{{dataPoint.deviceName}} ({{dataPoint.device.deviceId}})</td>
                <td class="ui-resizable-column">{{dataPoint.objectName}}</td>
                <td class="ui-resizable-column">{{dataPoint.description}}</td>
                <td class="ui-resizable-column">{{dataPoint.customName}}</td>
                <td class="ui-resizable-column">
                    <div class="cbms-api-response-feedback" *ngIf="requestSent && !isLoading && !hasError">
                        <div *ngIf="dataPoint.twinCreationDate" class="cbms-api-response-feedback__message">
                            <div class="cbms-api-response-feedback__icon">
                                <mat-icon class="cbms-red-color small" svgIcon="failure" *ngIf="dataPoint.result.code === 'FAIL'"></mat-icon>
                                <mat-icon class="cbms-yellow-color small" svgIcon="warning" *ngIf="dataPoint.result.code === 'WARNING'"></mat-icon>
                                <mat-icon class="cbms-green-color small" svgIcon="success" *ngIf="dataPoint.success"></mat-icon>

                            </div>
                            <span *ngIf="dataPoint.result.code === 'FAIL'">Write weekly schedule failed!</span>
                            <span *ngIf="dataPoint.success">Write weekly schedule successful!</span>
                            
                        </div>
                        <div *ngIf="!dataPoint.twinCreationDate" class="cbms-api-response-feedback__message">
                            <div class="cbms-api-response-feedback__icon">
                                <mat-icon class="cbms-yellow-color small" svgIcon="warning"></mat-icon>
                            </div>
                            <span>This schedule does not have a twin! Write schedule skipped!</span>
                        </div>
                    </div>
                    <mat-spinner *ngIf="isLoading" [diameter]="20" class="cbms-spinner"></mat-spinner>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
        pTooltip="Write Twin to schedule" showDelay="1000" hideDelay="500"
        (click)="writeTwinToSchedule()" [disabled]="requestSent || isLoadingDataPoints" data-cy="write-twin-btn">Write</button>
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>
