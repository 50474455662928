import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { FilterParams } from 'src/app/components/filter/interfaces/filter-params.interface';
import { BackendService } from 'src/app/services/backend/backend.service';
import { PocServiceService } from './poc-service.service';
import { timer, Subscription, Subject, forkJoin } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Location, Equipment, Point } from '../../models/dashboard';
import { KPI } from '../../config/constants';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
	selector: 'cbms-dashboard-poc',
	templateUrl: './dashboard-poc.component.html',
	styleUrls: ['./dashboard-poc.component.scss']
})
export class DashboardPocComponent implements OnInit {
	polledDatapointsValues: Subscription;
	polledKPIPointsValues: Subscription;
	locations: Location[] = [];
	locationCards: Location[] = [];
	equipments: Equipment[] = [];
	equipmentCards: Equipment[] = [];
	locationsFilters: any;
	equipmentsFilters: any;
	selectedFilter: string = 'all';

	private filterParams: FilterParams = {
		includesPresentValue: true,
		dataPointIdFilter: null
	};

	private stopPolling = new Subject();


	constructor(private pocService: PocServiceService, 
		private router: Router, 
		private backendService: BackendService, 
		private notificationService: NotificationService) { }

	ngOnInit(): void {
		this.getAllLocations();
		this.getAllEquipments();
	}

	ngOnDestroy() {
		if (this.polledDatapointsValues) {
			this.polledDatapointsValues.unsubscribe();
		}

		if (this.polledKPIPointsValues) {
			this.polledKPIPointsValues.unsubscribe();
		}
	}

	filterLocationCards(filter: string) {
		this.selectedFilter = filter;
		if (filter === 'all') {
			this.locationCards = [...this.locations];
			return;
		}
		this.locationCards = [...this.locations.filter(location => location.class === filter)];
	}

	filterEquipmentCards(filter: string) {
		this.selectedFilter = filter;
		if (filter === 'all') {
			this.equipmentCards = [...this.equipments];
			return;
		}
		this.equipmentCards = [...this.equipments.filter(equipment => equipment.class === filter)];
	}

	getAllLocations() {
		this.pocService.getLocations().subscribe((data) => {
			data.bindings.map(item => {
				this.locations.push({
					class: item.location_class.value.split("#")[1],
					uri: item.location.value,
					label: item.location_label.value,
					point: {
						label: item.point_label.value,
						uuid: item.point_uuid.value,
						presentValue: null
					}
				});
			});

			this.locationsFilters = _.groupBy(this.locations, "class");
			this.filterParams.dataPointIdFilter = this.locations.map((location) => { return { "id": location.point.uuid } });

			const datapointsValues$ = this.backendService.getDataPointsDetails(this.filterParams);

			this.polledDatapointsValues = timer(0, 10000).pipe(
				concatMap(_ => datapointsValues$),
				map((datapointsValuesResponse) => {
					this.mapLocationsPointPresentValue(datapointsValuesResponse.content);
					this.locationCards = [...this.locations];
					this.filterLocationCards(this.selectedFilter);
				})
			).subscribe();
		});
	}

	getAllEquipments() {
		forkJoin(
			[
				this.pocService.getEquipments(),
				this.pocService.getPointsOfEquipments()
			]
		).subscribe(([equipments, points]) => {
			equipments.bindings.forEach(item => {
				this.equipments.push({
					class: item.equipment_class.value.split("#")[1],
					uri: item.equipment.value,
					icon: this.generateIconClass(item.equipment_class.value),
					label: item.equipment_label.value,
					kpi: {
						label: '',
						uuid: '',
						presentValue: ''
					},
					points: [],
					parent: item.parent_equipment ? {
						uri: item.parent_equipment.value,
						label: item.parent_equipment_label.value
					} : null
				});
			});

			this.equipments.forEach(equipment => {
				points.bindings.forEach(point => {
					if (equipment.uri === point.equipment.value) {
						equipment.points.push({
							label: point.point_label.value,
							uuid: point.point_uuid.value,
							class: point.point_class.value.split('#')[1],
							presentValue: null
						})
					}
				})
			});

			this.equipments = this.unflattenData(this.equipments);
			
			this.equipments.forEach(equipment => {
				this.generateKPI(equipment);
			});

			this.equipmentsFilters = _.groupBy(this.equipments, "class");
			this.filterParams.dataPointIdFilter = this.equipments.map((equipment) => { return { "id": equipment.kpi.uuid } });

			const equipmentKPIPoints$ = this.backendService.getDataPointsDetails(this.filterParams);

			this.polledKPIPointsValues = timer(0, 10000).pipe(
				concatMap(_ => equipmentKPIPoints$),
				map((kpiPointsResponse) => {
					this.mapEquipmentKPI(kpiPointsResponse.content);
					this.equipmentCards = [...this.equipments];
					this.filterEquipmentCards(this.selectedFilter);
				})
			).subscribe();
		})
	}

	unflattenData(items) {
		let tree = [];
		let mappedArr = {};
		let mappedElem = null;

		// Build a hash table and map items to objects
		items.forEach(function (item) {
			var uri = item.uri;
			if (!mappedArr.hasOwnProperty(uri)) { // in case of duplicates
				mappedArr[uri] = item; // the extracted id as key, and the item as value
				mappedArr[uri].children = [];  // under each item, add a key "children" with an empty array as value
			}
		})

		// Loop over hash table
		for (var uri in mappedArr) {
			if (mappedArr.hasOwnProperty(uri)) {
				mappedElem = mappedArr[uri];

				// If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
				if (mappedElem.parent) {
					var parentUri = mappedElem.parent.uri;
					mappedArr[parentUri].children.push(mappedElem);
				}

				// If the element is at the root level, directly push to the tree
				else {
					tree.push(mappedElem);
				}
			}
		}

		return tree;
	}

	mapLocationsPointPresentValue(points: any[]) {
		const mappedPointsPerId = {};

		this.locations.forEach(location => {
			const pointObjectReference = location.point
			if (pointObjectReference) {
				mappedPointsPerId[pointObjectReference.uuid] = pointObjectReference;
			}
		})

		points.forEach(point => {
			const existingPointObjectReference = mappedPointsPerId[point.id];
			if (existingPointObjectReference) {
				existingPointObjectReference.presentValue = point.presentValue;
			}
		})
	}

	mapEquipmentKPI(points: any[]) {
		const mappedPointsPerId = {};

		this.equipments.forEach(equipment => {
			const pointObjectReference = equipment.kpi;
			if (pointObjectReference) {
				mappedPointsPerId[pointObjectReference.uuid] = pointObjectReference;
			}
		})

		points.forEach(point => {
			const existingPointObjectReference = mappedPointsPerId[point.id];
			if (existingPointObjectReference) {
				existingPointObjectReference.presentValue = point.presentValue;
				existingPointObjectReference.signalType = point.signalType;
				existingPointObjectReference.type = point.type;
				existingPointObjectReference.units = point.units;
				existingPointObjectReference.isWritable = this.isPointWritable(existingPointObjectReference);
			}
		})
	}

	isPointWritable(point: Point) {
		const pointMainClass = point.class.split('_').pop();
		if (['Command', 'Parameter', 'Setpoint'].includes(pointMainClass)) return true;
		if (['Alarm', 'Sensor', 'Status'].includes(pointMainClass)) return false;
	}

	handleTabChange(event) {
		this.selectedFilter = 'all';
		if (event.index === 0) {
			this.filterLocationCards(this.selectedFilter);
		}
		if (event.index === 1) {
			this.filterEquipmentCards(this.selectedFilter);
		}
	}

	generateIconClass(equipmentClass: string) {
		if (equipmentClass.toLowerCase().includes('air_handling_unit') || equipmentClass.toLowerCase().includes('exhaust_fan')) return 'air';
		if (equipmentClass.toLowerCase().includes('luminaire') || equipmentClass.toLowerCase().includes('lighting')) return 'lighting';
		if (equipmentClass.toLowerCase().includes('chiller')) return 'chiller';
		if (equipmentClass.toLowerCase().includes('boiler')) return 'hot_water';
		if (equipmentClass.toLowerCase().includes('fan')) return 'fan';
	}

	generateKPI(equipment: Equipment) {
		KPI[equipment.class].some((kpiClass: string) => {
			let index = equipment.points.findIndex((point) => { return point.class === kpiClass; })
			if (index !== -1) {
				equipment.kpi.label = equipment.points[index].label;
				equipment.kpi.uuid = equipment.points[index].uuid;
				equipment.kpi.class = equipment.points[index].class;
				return true;
			}
		});
	}

	handleChange(e, equipment: Equipment) {
		this.writeCommandPoint(equipment);
	}

	writeCommandPoint(equipment: Equipment) {
		const priority = 'AUTO';

		let dataToSend = {
			dataPointId: equipment.kpi.uuid,
			value: equipment.kpi.presentValue ? 1 : 0,
			rollbackTimer: null,
			priority: ''
		}

		this.backendService.writeCommand(dataToSend, priority).subscribe((response) => {
			this.notificationService.addSuccessMessage('Success', 'Point successfully written');
		})
	}

	goToEquipment(equipment: Equipment) {
		this.router.navigate(['dashboard-poc/equipment'],
			{
				queryParams: {
					uri: equipment.uri
				}
			});
	}
}
