import { Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BINARY } from 'src/app/config/constants';
import { Rule } from 'src/app/models/alert-rule.model';
import { DataPoint } from 'src/app/models/data-point';
import { Site } from 'src/app/models/site';

@Component({
    selector: 'cbms-query-builder',
    templateUrl: './query-builder.component.html',
    styleUrls: ['./query-builder.component.scss'],
})
export class QueryBuilderComponent implements OnInit {
    @Input() isReadOnly: boolean = false;
    @Input() selectedSite: Site;
    @Input() dataPointList: DataPoint[];
    @Input() operatorList: SelectItem[];
    @Input() parentValue: any;
    @Input() query: { condition: string, rules: Rule[] } = { condition: 'and', rules: [] };

    alertName = '';
    selectedDataPoint: DataPoint;
    selectedOperator: SelectItem;

	binaryPointList: SelectItem[] = [
		{ label: 'True', value: "true"},
		{ label: 'False', value: "false"},
	];

	isFreeText = true;

    emptyRule: Rule = {
        condition: null,
        rules: null,
        field: 'datapoint.lastRecordedValue',
        operator: '=',
        value: '',
        entityName: '',
		isFreeText: true
    };

    constructor() {}

    ngOnInit(): void {
    }

    onDataPointSelected(dropdown: any, rule: Rule) {
        if (dropdown.selectedOption.signalType === BINARY) {
            rule.isFreeText = false;
        } else {
            rule.isFreeText = true;
            rule.value = '';
        }
        rule.entityId = dropdown.selectedOption.id;
    }

    addRule() {
        this.query.rules.push({...this.emptyRule});
    }

    addRuleset() {
        this.query.rules.push({ condition: 'or', rules: [], field: null, operator: null, value: null});
    }

    removeRule(rule: any): void {
        this.query.rules = this.query.rules.filter((r) => r !== rule);
    }

    removeRuleSet(ruleset?: any, parent?: any): void {
        ruleset = ruleset || this.query;
        parent = parent || this.parentValue;

        parent.rules = parent.rules.filter((r) => r !== ruleset);
    }

    changeCondition(value: string): void {
        this.query.condition = value;
    }
}
