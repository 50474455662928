import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import { SelectItem } from 'primeng/api';
import { FilterService } from '../filter.service';
import { DataService } from 'src/app/services/data/data.service';
import { TimeFramesValues } from '../../../config/constants';

@Component({
	selector: 'cbms-timeframe-filter',
	templateUrl: './timeframe-filter.component.html',
	styleUrls: ['./timeframe-filter.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TimeframeFilterComponent implements OnInit {
	@Input() styleClass: string;

	public start: Date = moment.utc().subtract(7, 'days').toDate();
	public end: Date = moment.utc().toDate();
	public maxDateValue: Date = moment.utc().toDate();
	public rangeDates: Date[];
	public timeOptionList: SelectItem[] = [
		{ label: 'Select time', value: '' },
		{ label: 'Today', value: TimeFramesValues.TODAY },
		{ label: 'Yesterday', value: TimeFramesValues.YESTERDAY },
		{ label: 'Last 7 days', value: TimeFramesValues.LAST_7_DAYS },
		{ label: 'Last 30 days', value: TimeFramesValues.LAST_30_DAYS }
	];
	public selectedTime: SelectItem = { label: 'Last 7 days', value: 7 };
	private tabName = 'actionLog';

	constructor(private filterService: FilterService, private dataService: DataService) { }

	ngOnInit(): void {
		const timeframeFilter = this.filterService.getSelectedFilter('timeframeFilter', this.tabName) || {};
		if (timeframeFilter.selectedTime) {
			this.selectedTime = timeframeFilter.selectedTime;
			this.rangeDates = [...timeframeFilter.range.map((date: Date) => {
				if (date) { return moment(date).toDate(); }
			})];
		} else {
			this.setDefaultTimeframe();
		}
		
		this.dataService.applyTimeframeFilter(this.rangeDates);
	}

	public onIntervalSelected(event) {
		this.resetSelectedTime();
	}

	public onFilter() {
		this.filterService.applyFilter(this.tabName, { timeframeFilter: { range: this.rangeDates, selectedTime: this.selectedTime } });
		this.dataService.applyTimeframeFilter(this.rangeDates);
	}

	public onIntervalOptionSelected() {
		this.setCalendarInterval();
	}

	private setCalendarInterval() {
		let start = moment('00:00:00', 'HH:mm:ss').utc().subtract(this.selectedTime.value, 'days').toDate();
		let end = moment('23:59:00', 'HH:mm:ss').utc().toDate();

		if (this.selectedTime.value === TimeFramesValues.YESTERDAY) {
			end = moment(start).endOf("day").seconds(0).milliseconds(0).toDate();
		}
		
		this.rangeDates = [start, end];
	}

	private resetSelectedTime() {
		this.selectedTime = { label: 'Select time', value: '' };
	}

	private setDefaultTimeframe() {
		this.selectedTime = { label: 'Last 7 days', value: TimeFramesValues.LAST_7_DAYS };
		this.setCalendarInterval();
	}

}
