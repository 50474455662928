<div class="content">
    <div class="cbms-spinner-wrapper" *ngIf="isLoading">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>


    <div *ngIf="!isLoading" class="content-wrapper">
        <p>It is possible to remotely access the shell of the GW via SSH.</p>
        
        <div *ngIf="!isConnected" class="flex-item">
            <div class="status status--notConnected"></div>
            <div class="cbms-margin-left">
                <p>Not connected</p>
            </div>
            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto"
                (click)="openGatewayConnection()">Connect</button>
        </div>
        <div *ngIf="isConnected">
            <div class="flex-item">
                <div class="status status--connected"></div>
                <div class="cbms-margin-left">
                    <p>SSH connected <br />
                        Port: {{gatewayConnection.port}} <br />
                        Uptime: {{gatewayConnection.uptime}}
                    </p>
                </div>
                <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto"
                    (click)="closeGatewayConnection()">Disconnect</button>
            </div>
            <p>The session is only open for a limited time and will be automatically closed.</p>
        </div>
    </div>
</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>