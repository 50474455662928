<div class="content">

    <div class="cbms-spinner-wrapper" *ngIf="!isRequestDone">
        <mat-spinner [diameter]="30" class="cbms-spinner"></mat-spinner>
    </div>

    <br *ngIf=" ! isSuccessResponse">
    <div class="cbms-display-flex-row cbms-justify-content-center" *ngIf=" ! isSuccessResponse">
        <h3>  {{responseMessage}}</h3>
    </div>

    <div class="grid" *ngIf="isRequestDone && isSuccessResponse">
        <div class="col-9">
            <h3>Schedule Name: {{selectedDataPoint.objectName}}</h3>
            <cbms-schedule-calendar [scheduleData]="weeklyScheduleObject.weeklyScheduleWithInterval"></cbms-schedule-calendar>
        </div>
        <div class="col-3" *ngIf="!isTwin">
            <h3>Save as Template</h3>
            <form [formGroup]="saveTemplateForm" (ngSubmit)="saveTemplate()">
                <input pInputText type="text" placeholder="Template Name" required minlength="3" formControlName="templateName">
                <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" type="submit"
                    [disabled]="!saveTemplateForm.valid || isLoading">Save</button>
            </form>
            <div class="cbms-spinner-wrapper" *ngIf="isLoading">
                <mat-spinner [diameter]="20" class="cbms-spinner"></mat-spinner>
            </div>
        </div>
    </div>
</div>

<div class="footer">
    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-small-margin-left" (click)="close()">Close</button>
</div>