import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BackendService } from '../../../services/backend/backend.service';
import { UtilsService } from '../../../services/utils/util.service';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cbms-exception-schedule-table',
    templateUrl: './exception-schedule-table.component.html',
    styleUrls: ['./exception-schedule-table.component.scss']
})
/**
 * ExceptionScheduleTableComponent renders an exception schedule object.
 * The loading of the exception schedule object can be Lazy or not.
 *
 * If isLazyLoading==true, set the selectedDataPointIdInput
 * If isLazyLoading==false, set the exceptionScheduleInput object
 */
export class ExceptionScheduleTableComponent implements OnInit, OnChanges {
    @Input()
    public exceptionScheduleInput: any;
    @Input()
    public selectedDataPointIdInput: any;
    @Input()
    public scrollHeight = '400px';

    public selectedDataPointId: string;
    public isLoading: boolean = false;
    public isRequestDone: boolean = false;
    public exceptionSchedule: any;

    private getExceptionScheduleSubscription: Subscription;
    public responseMessage: string;
    public isSuccessResponse: boolean;

    public mainTableCols = [
        {field: 'name', header: 'Name', sortable: false},
        {field: 'type', header: 'Type', sortable: false},
        {field: 'summary', header: 'Summary', sortable: false},
        {field: 'priority', header: 'Priority', sortable: true}
    ];

    public subTableCols = [
        {field: 'start', header: 'Start', sortable: true},
        {field: 'end', header: 'Stop', sortable: true},
        {field: 'value', header: 'Value', sortable: true},
        {field: 'type', header: 'Type', sortable: true}
    ];



    constructor(public ref: DynamicDialogRef,
                public config: DynamicDialogConfig,
                private backendService: BackendService,
                private formBuilder: FormBuilder,
                private utilsService: UtilsService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.exceptionScheduleInput && changes.exceptionScheduleInput.currentValue) {
            this.exceptionSchedule = this.utilsService.mapExceptionSchedule(changes.exceptionScheduleInput.currentValue);
            this.isRequestDone = true;
            this.isSuccessResponse = true;
        }
    }

    ngOnInit() {}


    public close() {
        if (this.getExceptionScheduleSubscription) {
            this.getExceptionScheduleSubscription.unsubscribe();
        }
        this.ref.close();
    }

}
