<div class="filter-item">
    <mat-icon svgIcon="devices"></mat-icon>
    <p-multiSelect data-cy="device-filter" #deviceFilter class="filter-item min-width500" [options]="deviceList"
        [(ngModel)]="selectedDeviceList" [filter]="true" filterBy="label,objectName,description,nameAndDescription" virtualScrollItemSize="30"
        filterPlaceHolder="Devices" selectedItemsLabel="{0} Devices selected" maxSelectedLabels="1"
        placeholder="Devices" optionLabel="nameAndDescription" dataKey="id" (onChange)="onDeviceChosen()" [showToggleAll]="false" 
        (onFocus)="onFocus()" [virtualScroll]="true" dataKey="id">
        <p-footer>
            <hr>
            <div class="filter-footer" *ngIf="deviceList && deviceList.length">
                <span *ngIf="deviceFilter && !deviceFilter._filteredOptions">showing
                    {{deviceList.length}} of {{deviceList.length}}
                    Devices</span>
                <span *ngIf="deviceFilter && deviceFilter._filteredOptions">showing
                    {{deviceFilter._filteredOptions.length}} of {{deviceList.length}}
                    Devices</span>
            </div>
        </p-footer>
    </p-multiSelect>
</div>