import { Component, Input } from '@angular/core';
import { BackendService } from '../../../services/backend/backend.service';
import { Gateway } from '../../../models/gateway';
import { ExternalSource } from '../../../config/constants';

@Component({
    selector: 'cbms-option-card',
    templateUrl: './option-card.component.html',
    styleUrls: ['./option-card.component.scss']
})
export class OptionCardComponent {
    public searchDeviceId: string;
    public searchDeviceAddress: string;
    public isLoading: boolean = false;

    @Input()
    public gateway: Gateway;
    public BACKNET = ExternalSource.BACKNET;

    constructor(private backendService: BackendService) {

    }

    public scan() {
        this.gateway.devices = null;
        this.backendService.scanForDevices(this.gateway.id).subscribe(result => this.gateway.devices = result);
    }

    public add() {
        this.isLoading = true;
        const newDevice: any = {
            gatewayId: this.gateway.id,
            deviceId: this.searchDeviceId,
            deviceAddress: this.searchDeviceAddress
        };

        this.backendService.addDevice(newDevice).subscribe(() => {
            this.backendService.getDevicesByGateway(this.gateway.id).subscribe(devices => {
                this.searchDeviceId = '';
                this.searchDeviceAddress = '';
                this.gateway.devices = devices;
            });
            this.isLoading = false;
        }, () => {
            this.backendService.getDevicesByGateway(this.gateway.id).subscribe(devices => {
                this.gateway.devices = devices;
                this.isLoading = false;
            });
        });
    }
}
