import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { FilterService } from 'src/app/components/filter/filter.service';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend/backend.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { SUCCESS } from '../../../config/constants';
import { DataService } from 'src/app/services/data/data.service';

@Component({
	selector: 'cbms-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	public uploadInProgress: boolean = false;
    public templateName: string = '';
    public templateDateOfImport: string = '';
    private customerSubscription: Subscription;

	constructor(private backendService: BackendService, private notificationService: NotificationService, private filterService: FilterService, private dataService: DataService) { }

	ngOnInit(): void {
        this.dataService.currentTabHasChanged('dashboard');
        this.getCurrentTemplateInfo();

        this.customerSubscription = this.filterService.customerFilterChanges$.subscribe((data) => {
            if (data.isInitLoad) { return; }
            this.getCurrentTemplateInfo();
        });
	}

	public uploadTemplateCSV(event: any, dashboardTemplateUpload) {
        this.uploadInProgress = true;
		
        this.backendService.uploadDashboardTemplateExcel(event.files[0]).subscribe((response) => {
            dashboardTemplateUpload.clear();
            this.uploadInProgress = false;
            this.notificationService.addSuccessMessage(SUCCESS, `Data model successfully imported.`, true);
            this.getCurrentTemplateInfo();
        });
    }

    public exportDashboardSettingsTemplate() {
        this.backendService.exportDashboardTemplate().subscribe(response => {
            saveAs(response, `${this.templateName}`);
        }, (err) => { console.log(err) });
    }

	public deleteTemplate() {
        this.backendService.deleteDashboardTemplate().subscribe(response => {
            this.notificationService.addSuccessMessage(SUCCESS, 'Data model deleted successfully', true);
            this.getCurrentTemplateInfo();
        })
	}

    private getCurrentTemplateInfo() {
        this.backendService.getDashbardTemplateInfo().subscribe(response => {
            this.templateName = response.name;
            this.templateDateOfImport = response.createdDate;
        })
    }

}
