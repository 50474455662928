<ng-container>
    <div [pTooltip]="'No data points selected.'"
         showDelay="1000"
         hideDelay="500"
         [tooltipDisabled]="selectedDataPoints.length">
        <button pButton class="p-button-raised p-button-text cbms-button" (click)="showCopyDialog()"
                [disabled]="!selectedDataPoints.length || !isCopySourceSelected()">
            <mat-icon svgIcon="copyTo"></mat-icon>
            Copy to
        </button>
    </div>
</ng-container>
