<p-table class="cbms-generic-table-style" [columns]="mainTableCols" [value]="exceptionSchedule" dataKey="id" sortField="priority"
         sortOrder="1" [scrollable]="true" [scrollHeight]="scrollHeight">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th class="button-column"></th>
            <th class="cbms-text-align-left" class="cbms-text-align-left" *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
        <tr>
            <td class="button-column">
                <a href="#" [pRowToggler]="rowData">
                    <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                </a>
            </td>
            <td *ngFor="let col of columns">
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
        <tr>
            <td [attr.colspan]="columns.length + 1" style="padding:20px 39px; background-color: white;">
                <p><strong>Period:</strong></p>
                <p-table [columns]="subTableCols" [value]="rowData.timeValues">
                    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="text-align:left" *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                {{data[col.field]}}
            </td>
        </tr>
    </ng-template>
</p-table>
<div class="cbms-spinner-wrapper">
    <p *ngIf="!exceptionSchedule.length">No events defined</p>
</div>

