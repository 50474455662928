import { SelectItem } from 'primeng/api';

export const PAGINATION = {
    SIZE: 100,
    DEFAULT_PAGE_NR: 0
};
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_1000 = 1000;
export const PAGE_SIZE_10k = 10000;
export const PAGE_SIZE_100k = 100000;

export class DatapointsScan {
    static readonly DATAPOINTS_SCAN_STATUS_MILISECOND_INTERVAL = 5000;
}

export const TOTAL_NR_OF_DATAPOINTS_TO_ANALYZE: number = 20;
export const NO_OF_TAGS_TO_FETCH = 100000;

export const SORT_ORDER_DESC = -1;
export const SORT_ORDER_ASC = 1;

export enum FieldList {
    POLLED = 'Polled',
    SITE_ASSIGNED = 'Site Assigned',
    NAME = 'Name',
    TYPE = 'IO-Type',
    SIGNAL_TYPE = 'Signal Type',
    SOURCE = 'Source',
    SOURCE_DESCRIPTION = 'Source Description',
    CUSTOM_NAME = 'Custom Name',
    UNITS = 'Units',
    LAST_RECORDED_VALUE = 'Last recorded value',
    DATA_POINT_INTERNAL_ID = 'Datapoint ID',
    WISEMETER_ID = 'ID',
    INSTANCE = 'Instance',
    CLASSIFICATION = 'Classification'
}

export enum TimeFramesValues {
    TODAY = 0,
    YESTERDAY = 1,
    LAST_7_DAYS = 7,
    LAST_30_DAYS = 30
}

export const UNDERSCORE_SEPARATOR = '_';

export const COLOR_LIST = [
    '#EA1C0A', //eon-red
    '#008000', //eon-green
    '#1EA2B1', //eon-turquoise
    '#B00402', //eon-bordeaux
    '#E3E000', //eon-limeyellow
    '#D51607', //eon-red-active
    '#2B91A3', //eon-turqoise-active
    '#A00311', //eon-bordeaux-active
    '#DAD600', //eon-limeyellow-active
    '#f05548', //eon-red-75
    '#76020C', //eon-bordeaux-dark
    '#0F738A', //eon-turquoise-dark
    '#eae840', //eon-limeyellow-75
    '#d78180', //eon-bordeaux-50,
    '#f48D84', //eon-red-50
    '#56b9c5', //eon-turquoise-75
    '#f1ef7f', //eon-limeyellow-50
];

export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    RED: '#EA1C0A',
    TURQOISE: '#1EA2B1',
    LIME_YELLOW: '#E3E000'
};

export const ANY = 'Any';

export enum Months {
    Any = 0,
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12
}

export enum DayOfWeek {
    Any = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}

export const MONTH_LIST: SelectItem[] = [
    { label: 'Month', value: '' },
    { label: Months[0], value: Months.Any },
    { label: Months[1], value: Months.January },
    { label: Months[2], value: Months.February },
    { label: Months[3], value: Months.March },
    { label: Months[4], value: Months.April },
    { label: Months[5], value: Months.May },
    { label: Months[6], value: Months.June },
    { label: Months[7], value: Months.July },
    { label: Months[8], value: Months.August },
    { label: Months[9], value: Months.September },
    { label: Months[10], value: Months.October },
    { label: Months[11], value: Months.November },
    { label: Months[12], value: Months.December },
];

export const WEEK_OF_MONTH_TEXT = {
    0: 'Any',
    1: 'Week1',
    2: 'Week2',
    3: 'Week3',
    4: 'Week4',
    5: 'Week5',
    6: 'Last week of month',
    7: '2nd last week of the month',
    8: '3rd last week of the month',
    9: '4th last week of the month'
};

export const SUCCESS_CREATED_OPERATION = ' has been successfully created.';
export const SUCCESS_UPDATED_OPERATION = ' has been successfully updated.';
export const SUCCESS = 'Success';
export const WARNING = 'Warning';

export enum ViewMode {
    EDIT_MODE = 'EDIT_MODE',
    READ_ONLY_MODE = 'READ_ONLY_MODE'
}

export const ExternalSource = {
    BACKNET: 'CC',
    WISEMETER: 'WiseMeter'

};

export const TRUE = 'true';
export const ANALOG = 'Analog';
export const BINARY = 'Binary';
export const MULTISTATE = 'MultiState';
export const MANUAL_DATAPOINTS_TABLE_SIZE = 'cbms.datapoints.page.size';

export const KPI = {
    'Boiler': ['Hot_Water_Supply_Temperature_Sensor', 'Hot_Water_Return_Temperature_Sensor', 'On_Off_Command', 'On_Off_Status', 'Maintenance_Required_Alarm', 'Supply_Hot_Water_Temperature_Setpoint'],
    'Chiller': ['Chilled_Water_Supply_Temperature_Sensor', 'Chilled_Water_Return_Temperature_Sensor', 'On_Off_Command', 'On_Off_Status', 'Maintenance_Required_Alarm', 'Supply_Chilled_Water_Temperature_Setpoint'],
    'Luminaire': ['On_Off_Command'],
    'Lighting': ['On_Off_Command'],
    'Air_Handling_Unit': ['Supply_Air_Temperature_Sensor', 'Return_Air_Temperature_Sensor', 'Supply_Air_Humidity_Sensor', 'Return_Air_Humidity_Sensor', 'Zone_Air_Temperature_Setpoint', 'CO2_Setpoint', 'Setpoint'],
    'Exhaust_Fan': ['On_Off_Command', 'Fan_On_Off_Status', 'Overload_Alarm', 'Setpoint'],
    'Supply_Fan': ['On_Off_Command', 'Fan_On_Off_Status', 'Overload_Alarm'],
    'Outside_Damper': ['Damper_Command', 'Damper_Position_Sensor'],
    'Exhaust_Damper': ['Damper_Command', 'Damper_Position_Sensor'],
    'Mixed_Damper': ['Damper_Command', 'Damper_Position_Sensor'],
    'Pre_Filter': ['Pre_Filter_Status'],
    'Hot_Water_Valve': ['Valve_Command', 'Valve_Position_Sensor'],
    'Chilled_Water_Valve': ['Valve_Command', 'Valve_Position_Sensor']
};

export enum ENV_NAME {
    PROD = 'PROD',
    DEV = 'DEV',
    LOCAL = 'LOCAL'
}

export const AAD_B2C = {
    'DEV': {
        clientId: '093651c6-fb00-4c22-999e-ab38f8244419',
        aadAuthority: 'https://eonemsb2c.b2clogin.com/login.dev-qa.eon-optimum.com/B2C_1_connect',
        resourceScope: 'https://login.dev-qa.eon-optimum.com/candc/connect.control',
        knownAuthorities: 'eonemsb2c.b2clogin.com',
        postLogoutRedirectUri: 'https://dev.cbms.eon-optimum.com'
    },
    'PROD': {
        clientId: '0bb0d8f7-d6b2-4f87-b079-4ce15f030538',
        aadAuthority: 'https://optimumprodb2c.b2clogin.com/optimumprodb2c.onmicrosoft.com/B2C_1_Connect',
        resourceScope: 'https://optimumprodb2c.onmicrosoft.com/candc/connect.control',
        knownAuthorities: 'optimumprodb2c.b2clogin.com',
        postLogoutRedirectUri: 'https://cbms.eon-optimum.com'
    }
};

export const AUTH0_CONFIG = {
    'DEV': {
        issuer: 'https://login.dev.eon-optimum.com',
        redirectUri: window.location.origin,
        clientId: 'LqyW08BevUCrSvNHs8hwCGwK6hwD9qhU',
        scope: 'openid name email',
        responseType: 'code',
        requestAccessToken: false,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
    },
    'PROD': {
        issuer: 'https://login.eon-optimum.com',
        redirectUri: window.location.origin,
        clientId: 'lOmEO7dGVfVON7ZuFSzHedcENqmsgIMw',
        scope: 'openid name email',
        responseType: 'code',
        requestAccessToken: false,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
    }
}

export const OPERATION_TYPE_CREATE = 'CREATE';
export const OPERATION_TYPE_UPDATE = 'UPDATE';

export const enum DATAPOINTS_OP {
    MANAGE_VALUES = 'manageValues',
    WRITE_TWIN_TO_DATAPOINT = 'writeTwinToDatapoint'
}

export const VPN_LIST = [
    {label: 'Test VPN_1', value: '1'},
    {label: 'Test VPN_2', value: '2'},
    {label: 'Test VPN_3', value: '3'},
    {label: 'Test VPN_4', value: '4'}
];

export const enum ASSIGN_BY {
    DEVICES = 'devices',
    DATA_POINTS = 'dataPoints'
}

export const NO_GLOBAL_FILTERS_TABS = ['gateways', 'devices', 'actionLog'];
export const NO_GLOBAL_SITE_FILTERS_TABS = ['userManagement', 'gateways', 'devices', 'reports', 'actionLog', 'alertConsole', 'smartAlert', 'dashboard'];

export const ROLE_ADMIN = 'ROLE_ADMIN';
