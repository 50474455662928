import { Component, OnInit } from '@angular/core';
import { Gateway } from 'src/app/models/gateway';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BackendService } from '../../services/backend/backend.service';
import { Subscription } from 'rxjs';

type GatewayConnection = {
    gatewayId: string;
    gatewayName: string;
    spaceName: string;
    status: string;
    port?: number;
    uptime?: string;
}

@Component({
    selector: 'cbms-gateway-maintenance',
    templateUrl: './gateway-maintenance.component.html',
    styleUrls: ['./gateway-maintenance.component.scss']
})
export class GatewayMaintenanceComponent implements OnInit {
    public gateway: Gateway;
    public gatewayConnection: GatewayConnection;
    public isLoading = false;
    public isConnected = false;

    private getGatewayConnectionStatusSubscription: Subscription;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private backendService: BackendService) { }

    ngOnInit() {
        this.gateway = this.config.data;
        this.isLoading = true;
        this.getGatewayConnectionStatusSubscription = this.backendService.getGatewayConnectionStatus(this.gateway).subscribe((response: GatewayConnection) => {
            this.mapGatewayConnection(response);
            this.isLoading = false;
        } );
    }

    public openGatewayConnection() {
        this.isLoading = true;
        this.backendService.openGatewayConnection(this.gateway).subscribe((response: GatewayConnection) => {
            this.mapGatewayConnection(response);
            this.isLoading = false;
        } );
    }

    public closeGatewayConnection() {
        this.isLoading = true;
        this.backendService.closeGatewayConnection(this.gateway).subscribe((response: GatewayConnection) => {
            this.mapGatewayConnection(response);
            this.isLoading = false;
        } );
    }

    public mapGatewayConnection(response: GatewayConnection) {
        this.gatewayConnection = response;
        this.isConnected = response.status === "active";
    }

    public close() {
        this.getGatewayConnectionStatusSubscription.unsubscribe();
        this.ref.close();
    }

}
