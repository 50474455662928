import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpBaseService } from '../../services/http/http-base.service';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class PocServiceService {

    constructor(private httpBaseService: HttpBaseService, private http: HttpClient) {
    }

    getLocations() {

        let httpHeaders = {
            'Accept': 'application/sparql-results+json',
            'Content-Type': 'application/sparql-query',
        };

        const body = `
			PREFIX brick: <https://brickschema.org/schema/Brick#>
			PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
			PREFIX occ: <https://cbms.optimum.com/cc-ontology#>
				
			SELECT  ?location_class ?location_label ?point_label ?point_description ?location ?point_uuid
			WHERE {
				?location a brick:Location;
							rdfs:label ?location_label;
							brick:hasPoint ?point .
				
				?point a brick:Zone_Air_Temperature_Sensor;
							rdfs:label ?point_label;
							occ:pointUUID ?point_uuid .
				
				?location a ?location_class .
				FILTER NOT EXISTS {
					?subtype ^a ?location ;
							rdfs:subClassOf ?location_class .
					FILTER (?subtype != ?location_class)
				}
			}
		`;

        return this.httpBaseService.sendDataToFusekiServer(this.getSparqlQueryEndpointUrl(), httpHeaders, null, body)//TODO modify me
            .pipe(map((response: any) => response.results));
    }

    getEquipments() {
        let httpHeaders = {
            'Accept': 'application/sparql-results+json',
            'Content-Type': 'application/sparql-query',
        };

        const body = `
			PREFIX brick: <https://brickschema.org/schema/Brick#>
			PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
			PREFIX occ: <https://cbms.optimum.com/cc-ontology#>
			
			SELECT *
			WHERE {
				?equipment a brick:Equipment;
							rdfs:label ?equipment_label .
				OPTIONAL {
					?parent_equipment a brick:Equipment;
							brick:hasPart ?equipment .
					?parent_equipment rdfs:label ?parent_equipment_label .
					?parent_equipment a ?parent_equipment_class .
				}
				
				GRAPH <site://raw_data> {
					?equipment a ?equipment_class .
					FILTER (?equipment_class != brick:Equipment)
				}
			}
		`;

        return this.httpBaseService.sendDataToFusekiServer(this.getSparqlQueryEndpointUrl(), httpHeaders, null, body)//TODO modify me
            .pipe(map((response: any) => response.results));
    }

    getPointsOfEquipments() {
        let httpHeaders = {
            'Accept': 'application/sparql-results+json',
            'Content-Type': 'application/sparql-query',
        };

        const body = `
			PREFIX brick: <https://brickschema.org/schema/Brick#>
			PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
			PREFIX occ: <https://cbms.optimum.com/cc-ontology#>
			
			SELECT ?equipment ?point_class ?point_label ?point_uuid
			WHERE {
				?equipment a brick:Equipment;
							brick:hasPoint ?point .
				?point a brick:Point; 
						rdfs:label ?point_label;
						occ:pointUUID ?point_uuid .
				
				GRAPH <site://raw_data> {
					?point a ?point_class .
					FILTER (?point_class != brick:Point)
				}
			}
		`;

        return this.httpBaseService.sendDataToFusekiServer(this.getSparqlQueryEndpointUrl(), httpHeaders, null, body)//TODO modify me
            .pipe(map((response: any) => response.results));
    }

    getEquipment(uri: string) {
        let httpHeaders = {
            'Accept': 'application/sparql-results+json',
            'Content-Type': 'application/sparql-query',
        };

        const body = `
			PREFIX brick: <https://brickschema.org/schema/Brick#>
			PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
			PREFIX occ: <https://cbms.optimum.com/cc-ontology#>

			SELECT ?parent_equipment ?parent_equipment_label 
				?equipment ?equipment_class ?equipment_label
				?equipment_point ?point_class ?point_label ?point_uuid
			WHERE {
			{
				# Get root information
				VALUES ?equipment {
					<${uri}>
				}
				?equipment a brick:Equipment;
					rdfs:label ?equipment_label;
					brick:hasPoint ?equipment_point .

				?equipment_point a brick:Point;
								rdfs:label ?point_label;
								occ:pointUUID ?point_uuid .
				
				GRAPH <site://raw_data> {
					?equipment_point a ?point_class .
					FILTER (?point_class != brick:Point)
				}
			} UNION {
				# Get sub-equipment information
				VALUES ?root {
					<${uri}>
				}
				?root brick:hasPart* ?equipment .

				?equipment a brick:Equipment;
					rdfs:label ?equipment_label;
					brick:isPartOf ?parent_equipment .
					
				?parent_equipment a brick:Equipment;
								rdfs:label ?parent_equipment_label .  
				OPTIONAL {
				?equipment brick:hasPoint ?equipment_point .
				?equipment_point a brick:Point;
								rdfs:label ?point_label;
								occ:pointUUID ?point_uuid .
				
					GRAPH <site://raw_data> {
						?equipment_point a ?point_class .
						FILTER (?point_class != brick:Point)
					}
				}
			}
			
				GRAPH <site://raw_data> {
					?equipment a ?equipment_class .
					FILTER (?equipment_class != brick:Equipment)
				}
			}`;

        return this.httpBaseService.sendDataToFusekiServer(this.getSparqlQueryEndpointUrl(), httpHeaders, null, body)
            .pipe(map((response: any) => response.results));

    }

    private getSparqlQueryEndpointUrl() {
        const siteId = this.getSelectedSiteList() && this.getSelectedSiteList().length > 0 ? this.getSelectedSiteList()[0].id : '';
        return `fuseki/${siteId}`;
    }

    getSubLocations() {
        return this.httpBaseService.get(`locations/subtypes?type=space`);
    }

    getLocationsInstances(subType: string) {
        return this.httpBaseService.get(`locations?type=space&subType=${subType}`);
    }

    private getSelectedSiteList() {
        return JSON.parse(window.localStorage.getItem('selectedSiteList'));
    }
}
