import { DeviceAPI } from './device';
import { Site } from './site';
import { Extensions } from './extensions';
import {Gateway} from "../gateway";
import {GatewayPaginator} from "../core/gatewayPaginator";

export interface GatewayAPI {
    id?: string;
    name: string;
    hardwareId?: string;
    online?: string;
    site?: Site;
    siteId?: string;
    connectionString?: string;
    devices?: DeviceAPI[];
    networkInterfaces?: {
        wlan0?: string[],
        lo?: string[],
        eth1?: string[]
    },
    computedIpAddresses?: string;
    source?:string;
    extensions?:Extensions
}

export function toCoreModel(gatewayResponses: GatewayAPI[]): Gateway[] {
    const gateways = gatewayResponses.map(value => <Gateway><unknown>value);

    gateways.forEach(gateway => {
        if (gateway.extensions && gateway.extensions.virtual) {
            gateway.virtual = gateway.extensions.virtual.value;
        }
        if (gateway.extensions && gateway.extensions.virtual_address) {
            gateway.virtualAddress = gateway.extensions.virtual_address.value;
        }
    });
    return gateways;
}
export function toCoreModelWithPaginator(response: { content: GatewayAPI[]; totalElements: number }):GatewayPaginator{
    let coreGatewayPaginator:GatewayPaginator={
        content:            toCoreModel(response.content),
        totalElements:response.totalElements
    };

    return coreGatewayPaginator
}
