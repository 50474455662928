import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';

@Component({
	selector: 'cbms-audit',
	templateUrl: './audit.component.html',
	styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {

	constructor(private dataService: DataService) { }

	ngOnInit(): void {
		this.dataService.currentTabHasChanged('actionLog');
	}

}
