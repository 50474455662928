import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { WeeklyScheduleObject } from '../../../models/data-point';
import { BackendService, BE_WEEKLY_SCHEDULE } from '../../../services/backend/backend.service';

@Component({
    selector: 'cbms-show-template-action',
    templateUrl: './show-template-action.component.html',
    styleUrls: ['./show-template-action.component.scss']
})
export class ShowTemplateActionComponent implements OnInit {

    @Input()
    public templateName: any;

    @Input()
    public templateId: string;

    @Input()
    public isReadOnly: boolean;

    @Output() public onRefreshTemplateList: EventEmitter<string> = new EventEmitter<string>();


    constructor(private dialogConfigService: DialogConfigService,
                private backendService: BackendService) {
    }

    ngOnInit() {

    }

    public showTemplateDialog() {
        this.backendService.getScheduleTemplateByIdAndType(BE_WEEKLY_SCHEDULE, this.templateId).subscribe((response) => {
            const scheduleTemplate = response.content[0];
            this.templateName = scheduleTemplate.name;

            const ref = this.dialogConfigService.showTemplateDialog({
                name: this.templateName,
                id: this.templateId,
                weeklyScheduleObject: scheduleTemplate.weeklyScheduleObject,
                isReadOnly: this.isReadOnly
            });
            ref.onClose.subscribe((templateId) => {
                if (templateId) {
                    this.onRefreshTemplateList.emit(templateId);
                }
            });
        });
    }

}
