import { Component, OnInit, OnDestroy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { Subscription, interval } from 'rxjs';

@Component({
    selector: 'cbms-count-down',
    templateUrl: './count-down.component.html',
    styleUrls: ['./count-down.component.scss']
})
export class CountDownComponent implements OnInit, OnDestroy {
    @Input()
    public rollbackTimer: number;

    @Output()
    shouldRefreshDataEmitter = new EventEmitter<boolean>();
    
    private subscription: Subscription;

    public dDay;
    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute = 60;

    public timeDifference;
    public secondsToDday;
    public minutesToDday;
    public hoursToDday;
    public daysToDday;

    ngOnInit() {
        if (this.rollbackTimer) {
            this.initCountdownSubscription();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.rollbackTimer && changes.rollbackTimer.currentValue) {
            this.rollbackTimer = changes.rollbackTimer.currentValue;
            this.cancelSubscription();
            this.initCountdownSubscription();
        }
    }

    ngOnDestroy() {
        this.cancelSubscription();
    }

    private getTimeDifference() {
        this.timeDifference = this.dDay.getTime() - new Date().getTime();
        if (this.timeDifference <= 0) {
            this.subscription.unsubscribe();
            this.shouldRefreshDataEmitter.emit(true);
            return;
        }
        this.allocateTimeUnits(this.timeDifference);
    }

    private allocateTimeUnits(timeDifference) {
        this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    }

    private initCountdownSubscription() {
        this.dDay = moment(new Date()).add('milliseconds', this.rollbackTimer).toDate();
            
        this.subscription = interval(1000)
            .subscribe(x => { this.getTimeDifference(); });
    }

    private cancelSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
