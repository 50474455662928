import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ViewDevice } from '../../../models/device';
import { BackendService } from 'src/app/services/backend/backend.service';
import { Subscription } from 'rxjs';
import { FilterService } from '../filter.service';
import { PAGE_SIZE_10k } from '../../../config/constants';
import { DataService } from "../../../services/data/data.service";
import { Gateway } from "../../../models/gateway";

@Component({
    selector: 'cbms-device-filter',
    templateUrl: './device-filter.component.html',
    styleUrls: ['../filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceFilterComponent {
    @Output()
    valueChange = new EventEmitter<ViewDevice[]>();

    @Input()
    selectedDeviceList: ViewDevice[];

    deviceList: ViewDevice[];
    siteSubscription: Subscription;
    customerSubscription: Subscription;
    isLoading: boolean = true;
    private gatewaysFilterSubscription: Subscription;

    @Input()
    private gatewaysFilterList: Gateway[]=[]

    constructor(
        private backendService: BackendService,
        private filterService: FilterService,
        private dataService:DataService) {
    }

    public ngOnInit() {
        this.deviceList = [];
        this.loadDevices();

        this.gatewaysFilterSubscription = this.dataService.gatewaysFilterChanged$.subscribe((gatewaysFilterList: Gateway[]) => {
            this.selectedDeviceList = []
            this.gatewaysFilterList=gatewaysFilterList
            this.valueChange.emit(this.selectedDeviceList);
            this.loadDevices()

        })
    }

    private loadDevices() {
        this.backendService.getDevicesSummary(0, PAGE_SIZE_10k, [], this.gatewaysFilterList).subscribe((response: any) => {
            let deviceList = response;

            deviceList.forEach((device: ViewDevice) => {
                this.computeNameAndDescription(device);
            });
            this.deviceList = [...deviceList];
            this.isLoading = false;
        });
    }

    public ngOnDestroy() {
        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }
        if(this.gatewaysFilterSubscription){
            this.gatewaysFilterSubscription.unsubscribe()
        }
    }

    public ngOnChanges(changes: SimpleChanges) {//TODO check if still needed?
        if (changes.selectedDeviceList) {
            this.selectedDeviceList = Object.assign([], changes.selectedDeviceList.currentValue);
        }
    }

    public onFocus() {
        //Not needed
    }

    public onDeviceChosen() {
        this.valueChange.emit(this.selectedDeviceList);
    }


    private computeNameAndDescription(device: ViewDevice) {
        device.nameAndDescription = device.description ? `${device.name} (${device.description})` : device.name || 'undefined';
    }
}
