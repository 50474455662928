import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, QueryParamsHandling } from '@angular/router';


@Injectable()
export class NavigationService {
    constructor(private router: Router) {

    }

    public setQueryParameter(activatedRoute: ActivatedRoute, parameter: any, handlingType: QueryParamsHandling  = 'merge') {
        this.router.navigate([], {
            relativeTo: activatedRoute,
            queryParams: parameter,
            queryParamsHandling: handlingType
        });
    }
}
