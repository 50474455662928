import { Component, Input, OnInit } from '@angular/core';
import { MappedDataPoint } from 'src/app/models/data-point';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { StoreService } from '../../../services/data/store.service';

@Component({
    selector: 'cbms-copy-action',
    templateUrl: './copy-action.component.html',
    styleUrls: ['./copy-action.component.scss']
})
export class CopyActionComponent implements OnInit {

    @Input()
    public selectedDataPoints: MappedDataPoint[];
    @Input()
    public copySource: MappedDataPoint;

    constructor(private dialogConfigService: DialogConfigService, private storeService: StoreService) {
    }

    ngOnInit() {
    }

    public showCopyDialog() {
        this.dialogConfigService.showCopyDialog(this.selectedDataPoints, this.copySource);
    }


    isCopySourceSelected() {
        return Boolean(this.copySource) || Boolean(this.storeService.selectedWeeklyScheduleTemplate)
            || Boolean(this.storeService.selectedExceptionScheduleTemplate);
    }
}
