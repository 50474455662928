<div class="container-full-width">
    <div class="sub-menu">
        <p-button type="text" (click)="showSidebarMenu = true" icon="pi pi-bars"></p-button>
    </div>
    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>

<p-sidebar [(visible)]="showSidebarMenu" styleClass="cbms-sidebar-menu">
    <h2>Settings</h2>
    <ul class="cbms-menu-container">
        <li *ngIf="hasPermission(ROLE_PERMISSIONS.VIEW_SETTINGS_DASHBOARD_TAB)" class="cbms-menu-item" [routerLink]="['dashboard']" [routerLinkActive]="'active-menu-item'">
            <a href="" (click)="showSidebarMenu = false">Dashboard</a>
        </li>
        <li *ngIf="hasPermission(ROLE_PERMISSIONS.VIEW_SETTINGS_USER_MANAGEMENT_TAB)" class="cbms-menu-item" [routerLink]="['user-management']" [routerLinkActive]="'active-menu-item'">
            <a href="" (click)="showSidebarMenu = false">User Management</a>
        </li>

        <li *ngIf="hasPermission(ROLE_PERMISSIONS.VIEW_REPORTS_TAB)" class="cbms-menu-item" [routerLink]="['reports']" [routerLinkActive]="'active-menu-item'">
            <a href="" (click)="showSidebarMenu = false">Reports</a>
        </li>
        <li *ngIf="hasPermission(ROLE_PERMISSIONS.VIEW_ALERTS_RULES_TAB)" class="cbms-menu-item" [routerLink]="['smart-alert']" [routerLinkActive]="'active-menu-item'">
            <a href="" (click)="showSidebarMenu = false">Smart Alert Rules</a>
        </li>
        <li *ngIf="hasPermission(ROLE_PERMISSIONS.VIEW_ALERT_CONSOLE_TAB)" class="cbms-menu-item" [routerLink]="['alert-console']" [routerLinkActive]="'active-menu-item'">
            <a href="" (click)="showSidebarMenu = false">SMART Alert Console</a>
        </li>

        <li *ngIf="hasPermission(ROLE_PERMISSIONS.VIEW_SETTINGS_USER_MANAGEMENT_TAB)" class="cbms-menu-item" [routerLink]="['auto-optimisation']" [routerLinkActive]="'active-menu-item'">
            <a href="" (click)="showSidebarMenu = false">Auto Optimisation</a>
        </li>
    </ul>
</p-sidebar>
