import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import _ from 'lodash';

@Component({
    selector: 'cbms-quick-filter',
    templateUrl: './quick-filter.component.html',
    styleUrls: ['../filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickFilterComponent { 
    @Output()
    valueChange = new EventEmitter<string>();
    
    @Input()
    styleClass: string = '';

    private _quickFilterText: string = '';

    @Input()
    set quickFilterText(value: string) {
        this._quickFilterText = value;
        if (value && value.length < 3) {
            return;
        }
        this.valueChange.emit(value);
    }
    get quickFilterText(): string {
        return this._quickFilterText;
    }

    constructor() {}
}
