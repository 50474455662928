import { Component, OnInit, Input } from '@angular/core';
import { DialogConfigService } from 'src/app/services/dialog-config/dialog-config.service';
import { MappedDataPoint } from 'src/app/models/data-point';


@Component({
  selector: 'cbms-show-exception-schedule-action',
  templateUrl: './show-exception-schedule-action.component.html',
  styleUrls: ['./show-exception-schedule-action.component.scss']
})
export class ShowExceptionScheduleActionComponent implements OnInit {

  @Input()
  public selectedDataPoint: MappedDataPoint;

  constructor(private dialogConfigService: DialogConfigService) { }

  ngOnInit() {
  }

  public showExceptionScheduleDialog() {
    this.dialogConfigService.showExceptionScheduleDialog(this.selectedDataPoint);
  }

}
